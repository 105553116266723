import React, {  useState } from "react";
import { classNames } from "primereact/utils";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import "../../Assets/Styling/auth.css";
import { loginStore, storeTransactionData,transactionTableData,getPartnerStores } from "../../../store/Reducers/userAuth";
import { ThunkDispatch } from "redux-thunk";
import { useNavigate, NavigateFunction } from "react-router-dom";
import { FormLoginValues, CreateStoreProps } from "../models";
import { LoginInterface, RootState } from "../../../store/models";
import { Password } from "primereact/password";
import { Checkbox } from "primereact/checkbox";
import { ProgressSpinner } from "primereact/progressspinner";
import { useTranslation } from "react-i18next";
import { DashboardDataForm,TransactionTable } from "../../main/models";

const LoginComponent: React.FC<CreateStoreProps> = ({ appToastRef }) => {
	const dispatch: ThunkDispatch<RootState, void, any> = useDispatch();
	const navigate: NavigateFunction = useNavigate(); // Explicitly set the type here
	const [checked, setChecked] = useState(false);
	const [showSpinner, setShowSpinner] = useState(false);
	const { t } = useTranslation();

	const handleSubmit = async (values: FormLoginValues) => {
		try {
			setShowSpinner(true);
			const staticFields = {
				mode: "email",
			};
			const response = await dispatch(loginStore({ loginData: { ...values, ...staticFields },successMsg:t("user_login_success"),errorMsg: t("user_login_error"), toastRef: appToastRef, navigate }));
			const username = (response.payload as LoginInterface).username;
			const formData: DashboardDataForm = {
				username: username, 
				window: "day",
				createdAtStart: "",
				createdAtEnd: "",
				limit: 25,
				start: 0,
			};
			const oneWeekFromSystemDate = new Date();
			oneWeekFromSystemDate.setDate(oneWeekFromSystemDate.getDate()-7);
			formData.createdAtStart = oneWeekFromSystemDate.toLocaleString();
			await dispatch(storeTransactionData({ storeData: { ...formData },successMsg:t(""),errorMsg: t("Error Loading data from server"), toastRef: appToastRef, navigate }));
			
			const accountType = (response.payload as LoginInterface).accountType;
			const formData1: TransactionTable = {
				username: username,
				createdAtStart: "",
				createdAtEnd: "",
				limit: 25,
				start: 0,
				actionName: accountType !== "retailer" ? "create" : "redeem",
			};

			formData1.createdAtStart = oneWeekFromSystemDate.toLocaleString();
			await dispatch(transactionTableData({ tableData: { ...formData1 },successMsg:t(""),errorMsg: t("Error Loading data from server"), toastRef: appToastRef, navigate }));

			//Get partner store details
			if(accountType !== "retailer") {
				await dispatch(getPartnerStores({ username: username,successMsg:t(""),errorMsg: t("Error Loading data from server"), toastRef: appToastRef, navigate }));
			}


			setShowSpinner(false);
		} catch (error: any) {
			setShowSpinner(false);
			console.error("Login error:", error);
		}
	};
	const formik = useFormik({
		initialValues: {
			email: "",
			password: "",
		},
		validate: (data) => {
			const errors: Partial<{ email: string; password: string }> = {};
			if (!data.email) {
				errors.email = t("invalid_email_error_required");
			} else if (!/^\S+@\S+\.\S+$/.test(data.email)) {
				errors.email = t("invalid_email_error");
			}
			if (!data.password) {
				errors.password =t("invalid_password_error");
			}
			return errors;
		},
		onSubmit: (data) => {
			// Handle form submission here
			handleSubmit(data);
		},
	});
	const isFormFieldInvalid = (name: keyof typeof formik.values) =>
		!!(formik.touched[name] && formik.errors[name]);
	const getFormErrorMessage = (name: keyof typeof formik.values) =>
		isFormFieldInvalid(name) ? (
			<small className="p-error margin-top">{formik.errors[name]}</small>
		) : (
			<small className="p-error">&nbsp;</small>
		);
	return (
	
		<div className="login-parent-div">
			<div className="flex-basis-60">
				<div className="login-partOne-parent">
					<div className="logo-login-parent">
						<img src="/image 2.png" alt="" className="width-complete-100" />
					</div>
					<h2 className="text-center login-heading">{t("login_heading")}</h2>
					{/* <p className="text-center login-para">{t("login_para")}</p> */}
					{showSpinner ? (
						<ProgressSpinner />
					) : (
						<form className="login-form-div" onSubmit={formik.handleSubmit}>
							<div className="p-mb-2 margin-top login-input-parent-div">
								<label htmlFor="email" style={{paddingBottom: '8px', paddingLeft: '1px'}}>{t("email")}</label>
								<InputText
									id="email"
									placeholder={t("email_placeholder")}
									name="email"
									value={formik.values.email}
									onChange={formik.handleChange}
									className={`input-width input-border-radius ${classNames({ "p-invalid": isFormFieldInvalid("email") })}`}
								/>
								{getFormErrorMessage("email")}
							</div>
							<div className="p-mb-2 margin-top login-input-parent-div">
								<label htmlFor="password" style={{paddingBottom: '8px', paddingLeft: '1px'}}>{t("password")}</label>
								<Password
									value={formik.values.password}
									onChange={formik.handleChange}
									id="password"
									name="password"
									feedback={false}
									tabIndex={1}
									className={`input-width input-border-radius ${classNames({ "p-invalid": isFormFieldInvalid("password") })}`}
									placeholder={t("password_placeholder")}
								/>
								{getFormErrorMessage("password")}
							</div>
							<div className="login-remember-div">
								<div className="login-radio-parent">
									<Checkbox onChange={(e: any) => setChecked(e.checked)} checked={checked}></Checkbox>
									<span style={{ paddingLeft: "10px" }}>{t("remember_me")}</span>
								</div>
								<Button label={t("forgot_password")} onClick={() => navigate("/auth/resetPassword")} text />
							</div>
							<div className="width-fifty">
								<Button type="submit" label={t("submit")} className="login-submit-button" />
							</div>
							<div className="p-field p-grid navigate-parent">
								<div className="p-col">
									<div className="navigate-auth-option-btn">
										<p>{t("dont_have_account")}</p>
										<Button label={t("signup")} text onClick={() => navigate("/auth/signup")} />
									</div>
								</div>
							</div>
						</form>
					)}
				</div>
			</div>
			<div className="flex-basis-40">
				<div>
					<img src="/Sign in 1.png" className="width-complete-100" alt="" />
				</div>
			</div>
		</div>
	);
};
export default LoginComponent;
