const baseUrl = "https://api.redimi.net/api/";
const baseWebUrl = "https://api.redimi.net";
interface GlobalServiceFunctions {
	getStorage: (key: string) => Promise<any>;
	setStorage: (key: string, value: any) => Promise<void>;
	removeStorage: (key: string) => Promise<void>;
	clearStorage: () => Promise<void>;
}

const getStorage = async (key: string): Promise<any> => {
	try {
		const response = localStorage.getItem(key);
		return response ? JSON.parse(response) : null;
	} catch (error) {
		throw new Error("Error retrieving data from Local Storage");
	}
};

const setStorage = async (key: string, value: any): Promise<void> => {
	try {
		await localStorage.setItem(key, JSON.stringify(value));
	} catch (error) {
		throw new Error("Error storing data in localStorage");
	}
};

const removeStorage = async (key: string): Promise<void> => {
	try {
		await localStorage.removeItem(key);
	} catch (error) {
		throw new Error("Error removing data from localStorage");
	}
};

const clearStorage = async (): Promise<void> => {
	try {
		await localStorage.clear();
	} catch (error) {
		throw new Error("Error clearing localStorage");
	}
};

const GlobalService: GlobalServiceFunctions = {
	getStorage,
	setStorage,
	removeStorage,
	clearStorage
};
const GlobalValues = {
	baseUrl,
	baseWebUrl
} as any;

export { GlobalService, GlobalValues };