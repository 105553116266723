import { Button } from "primereact/button";
import React from "react";
import { useTranslation } from "react-i18next";
import { NavigateFunction, useNavigate } from "react-router-dom";

export default function SuccessfullComponent({ appToastRef }: any) {
	const navigate: NavigateFunction = useNavigate();
	const { t } = useTranslation();
	return (
		<div className="reset-form-align-center">
			<div className="login-partOne-parent" style={{ height: "100vh" }}>
				<div className="logo-forgotpassword-parent ">
					<span className="circleStyle">
						<img src="/check-circle.png" alt="reset-logo-img" className="reset-logo-img-width" />
					</span>
				</div>
				<h2 className="text-center forgotpassword-heading" >{t("successfully")}</h2>
				<p className="text-center forgotpassword-para">{t("order_msg")}</p>
				<div className="forgotpassword-submit-parent-div">
					<Button type="submit" label={t("continue")} className="login-submit-button" onClick={() => { navigate("/giftCards"); }} /></div>
			</div>
		</div>
	);
}
