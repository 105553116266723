import React, {useEffect, useState } from "react";
import { classNames } from "primereact/utils";
import { useFormik } from "formik";
import { Timeline } from "primereact/timeline";
import { InputText } from "primereact/inputtext";import { Button } from "primereact/button";
import { useDispatch } from "react-redux";
import  {useNavigate,NavigateFunction} from "react-router-dom";
import "../../Assets/Styling/auth.css";
import { ThunkDispatch } from "redux-thunk";
import { forgotPasswordEmail, forgotPasswordRecreation } from "../../../store/Reducers/userAuth";
import bcrypt from "bcryptjs";
import { SignupProps,forgotPassword,securePassword } from "../models";
import { RootState } from "../../../store/models";
import { Password } from "primereact/password";
import { ProgressSpinner } from "primereact/progressspinner";
import { useTranslation } from "react-i18next";
const ResetPasswordComponent: React.FC<SignupProps> = ({ appToastRef }) => {
	const dispatch: ThunkDispatch<RootState, void, any> = useDispatch();
	const navigate: NavigateFunction = useNavigate(); 
	const [selectCategory, setSelectCategory] = useState("details"); // Set the selected number
	const [emailData,setEmailData]=useState("");
	const [showSpinner, setShowSpinner] = useState(false);
	const [showOtherSpinner, setShowOtherSpinner] = useState(false);
	const { t } = useTranslation();
	//Timeline layout handled
	const [layout, setLayout] = useState<"vertical" | "horizontal" | undefined>("vertical");
	const [showSubtitle, setShowSubtitle] = useState(true);

	useEffect(() => {
		const handleResize = () => {
			const screenWidth = window.innerWidth;
			if (screenWidth <= 750) {
				setLayout("horizontal");
				setShowSubtitle(false);
			} else {
				setLayout("vertical");
				setShowSubtitle(true);
			}
		};

		// Initial check on component mount
		handleResize();

		// Add event listener for window resize
		window.addEventListener("resize", handleResize);

		// Cleanup the event listener
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	//forgot password
	const handleforgotPasswordFormikSubmit = async (values: forgotPassword) => {
		try {
			setShowSpinner(true);
			setEmailData(values.email);
			await dispatch(forgotPasswordEmail({ verifyEmail:values,successMsg:t("verification_code_sent_success"),errorMsg: t("verification_code_sent_error"), verifyEmailtoastRef: appToastRef,setSelectCategory }));   
			setShowSpinner(false);
		} catch (error:any) {
			setShowSpinner(false);
			console.error("Login error:", error);
		}
	};
	const forgotPasswordFormik = useFormik({
		initialValues: {
			email: "",
		},
		validate: (data) => {
			const errors: Partial<{ email: string}> = {};
			if (!data.email) {
				errors.email = t("invalid_email_error_required");
			} else if (!/^\S+@\S+\.\S+$/.test(data.email)) {
				errors.email = t("invalid_email_error");
			}
			return errors;
		},
		onSubmit: (data) => {
			// Handle form submission here
			handleforgotPasswordFormikSubmit(data);
		},
	});
	const isFormforgotPasswordFieldInvalid = (name: keyof typeof forgotPasswordFormik.values) =>
		!!(forgotPasswordFormik.touched[name] && forgotPasswordFormik.errors[name]);
	const getFormforgotPasswordErrorMessage = (name: keyof typeof forgotPasswordFormik.values) =>
		isFormforgotPasswordFieldInvalid(name) ? (
			<small className="p-error reset-password-marginBottom">{forgotPasswordFormik.errors[name]}</small>
		) : (
			<small className="p-error reset-password-marginBottom">&nbsp;</small>
		);
	//secure password
	const handleSecurePasswordFormikSubmit = async (values: securePassword) => {
		try {
			setShowOtherSpinner(true);
			const saltRounds = 10;
			let client_salt = bcrypt.genSaltSync(saltRounds);
			client_salt = "$2a" + client_salt.substring(3, client_salt.length);
			const client_hash = bcrypt.hashSync(values.confirm_password, client_salt);
			// Create the final object with all the values
			const formData = {
				email: emailData,
				verificationCode: values.verificationCode,
				encryptionData: {
					client_salt,
					client_hash,
				},
			};
			await dispatch(forgotPasswordRecreation({ verifyEmail:formData,successMsg:t("password_recreated_success"),errorMsg:t("password_recreated_error"), verifyEmailtoastRef: appToastRef,setSelectCategory }));  
			setShowOtherSpinner(false);
		} catch (error:any) {
			setShowOtherSpinner(false);
			console.error("Login error:", error);
		}
	};
	const securePasswordFormik = useFormik({
		initialValues: {
			verificationCode: "",
			password:"",
			confirm_password:""
		},
		validate: (data) => {
			const errors: Partial<{ password: string,confirm_password:string,verificationCode:string}> = {};
			if (!data.password) {
				errors.password = t("invalid_password_error");
			}
			if (!data.confirm_password) {
				errors.confirm_password = t("confirm_password_required_error");
			}
			if (!data.verificationCode) {
				errors.verificationCode = t("verificationcode_required_error");
			}
			if (data.password !== data.confirm_password) {
				errors.confirm_password =  t("passwords_match_error");
			}
			return errors;
		},
		onSubmit: (data) => {
			// Handle form submission here
			handleSecurePasswordFormikSubmit(data);
		},
	});
	const isFormSecurePasswordFieldInvalid = (name: keyof typeof securePasswordFormik.values) =>
		!!(securePasswordFormik.touched[name] && securePasswordFormik.errors[name]);  
	const getFormSecurePasswordErrorMessage = (name: keyof typeof securePasswordFormik.values) =>
		isFormSecurePasswordFieldInvalid(name) ? (
			<small className="p-error margin-top">{securePasswordFormik.errors[name]}</small>
		) : (
			<small className="p-error ">&nbsp;</small>
		);
	//timeline work
	const events = [
		{
			status: showSubtitle ?t("your_details"):t("details"),
			subTitle: t("reset_name_email"),
			date: "15/10/2020 10:30",
			icon: "pi pi pi-cog",
			color: selectCategory === "details" && "black" ,
		},
		{
			status:  showSubtitle ?t("check_email"):t("email"),
			subTitle: t("get_reset_link"),
			date: "15/10/2020 14:00",
			icon: "pi pi-envelope",
			color: selectCategory === "verifyEmail" && "black" ,
		},
		{
			status: showSubtitle ?t("choose_password"):t("password"),
			subTitle: t("choose_secure_password"),
			date: "15/10/2020 16:15",
			icon: "pi pi-check",
			color: selectCategory === "securePassword" && "black",
		},
		{
			status:showSubtitle ? t("successfully"):t("success"),
			subTitle: t("go_back_to_login"),
			date: "16/10/2020 10:00",
			icon: "pi pi-check",
			color: selectCategory === "loginIn" && "black",
		},
	];
	const customizedMarker = (item:any) => {
		const circleStyle = {
			width: "2rem",
			height: "2rem",
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
			borderRadius: "50%",
			border: `1px solid ${item.color?item.color:"gray"}`,
		};
		return (
			<span className="flex w-2rem h-2rem align-items-center justify-content-center text-white border-circle z-1 shadow-1">
				<span style={circleStyle}>
					<i className={item.icon} style={{ color:item.color?item.color:"gray"}}></i>
				</span>
			</span>
		);
	};
	return (
		<div className="reset-password-parent">
			<div className="reset-password-partOne">
				<div className="logo-login-parent align-self-logo-resetPassword">
					<img src="/image 2.png" alt="" className="width-complete-100" />
				</div>
				<div className="timeline-parent">
					<Timeline
						value={events}
						marker={customizedMarker}
						layout={layout}
						content={(item) => {
							return (
								<div className="timeline-content-parent">
									<span
										className="timeline-status" style={{color:item.color?item.color:"gray"}}>
										{item.status}
									</span>
									{showSubtitle && (
										<span className="timeline-subtitle" style={{color:item.color?item.color:"gray"}}>
											{item.subTitle}
										</span>
									)}
								</div>
							);
						}}
						className="w-full cursor-pointer"
					/>
				</div>
			</div>
			<div className="reset-password-partTwo">
				<div style={{display: "flex",
					alignItems: "center"}}>
					{selectCategory==="details"?
						<div className="reset-form-align-center">
							<div className="login-partOne-parent signup-padding-top">
								<div className="logo-forgotpassword-parent ">
									<span className="circleStyle">
										<img src="/Icon.png"alt="reset-logo-img" className="reset-logo-img-width"/>
									</span>
								</div>
								<h2 className="text-center forgotpassword-heading" >{t("forgot_password")}</h2>
								<p className="text-center forgotpassword-para">{t("reset_worry_text")} </p>
								{showSpinner ? (
									<ProgressSpinner />
								) : (
									<form className="login-form-div"
										onSubmit={forgotPasswordFormik.handleSubmit}
									>
										<div className=" p-mb-2 margin-top forgotpassword-input-parent-div">
											<label htmlFor="email" style={{paddingBottom: '8px', paddingLeft: '1px'}}>{t("email")}</label>
											<InputText
												id="email"
												name="email"
												placeholder={t("email_placeholder")}
												value={forgotPasswordFormik.values.email}
												onChange={forgotPasswordFormik.handleChange}
												className={`input-width input-border-radius ${classNames({ "p-invalid": isFormforgotPasswordFieldInvalid("email") })}`}
											/>
											{getFormforgotPasswordErrorMessage("email")}
										</div>        
										<div className="forgotpassword-submit-parent-div">
											<Button type="submit" label={t("confirm_email")}className="login-submit-button" /></div>
										<div className="navigate-back-reset-parent">
											<i className="pi pi-arrow-left"></i>
											<button onClick={()=>{navigate("/auth/login");}} className="navigate-back-reset-text-padding">{t("back_to_login")}</button>
										</div>
									</form>
								)}
							</div>
						</div>
						:selectCategory==="verifyEmail"?
							<div className="reset-form-align-center">
								<div className="login-partOne-parent signup-padding-top">
									<div className="logo-forgotpassword-parent ">
										<span className="circleStyle">
											<i className="pi pi-envelope"></i>
										</span>
									</div>
									<h2 className="text-center forgotpassword-heading" >{t("check_email")}</h2>
									<p className="text-center forgotpassword-para">{t("get_reset_link")} <p className="resetpassword-verify-email-p">{emailData}</p></p>
									<div className="forgotpassword-submit-parent-div">
										<Button type="submit" label={t("continue")} className="login-submit-button" onClick={()=>{setSelectCategory("securePassword");}} /></div>
									{/* <div className="p-field  p-grid">
  <div className="p-col">
    <div className="navigate-auth-option-btn">
      <p>Did not receive an email?</p>
      <Button onClick={async()=>{ await dispatch(forgotPasswordEmail({ verifyEmail:{email:emailData}, verifyEmailtoastRef: appToastRef,setSelectCategory }));}} label="click to resend" text  />
    </div>
  </div>
</div> */}
									<div className="navigate-back-reset-parent">
										<i className="pi pi-arrow-left"></i>
										<button onClick={()=>{navigate("/auth/login");}} className="navigate-back-reset-text-padding">{t("back_to_login")}</button>
									</div>
								</div>
							</div>:selectCategory==="securePassword"? 
								<div className="reset-form-align-center">
									<div className="login-partOne-parent signup-padding-top">
										<div className="logo-forgotpassword-parent ">
											<span className="circleStyle">
												<img src="/Icon (1).png"alt="reset-logo-img" className="reset-logo-img-width"/>;
											</span>
										</div>
										<h2 className="text-center forgotpassword-heading" >{t("set_new_password")}</h2>
										<p className="text-center forgotpassword-para">{t("password_different")}</p>
										{showOtherSpinner ? (
											<ProgressSpinner />
										) : (
											<form className="login-form-div"
												onSubmit={securePasswordFormik.handleSubmit}
											>
												<div className=" p-mb-2 margin-top forgotpassword-input-parent-div">
													<label htmlFor="verificationCode" style={{paddingBottom: '8px', paddingLeft: '1px'}}>{t("verification_code")}</label>
													<InputText
														id="verificationCode"
														name="verificationCode"
														placeholder={t("verification_code_placeholder")}
														value={securePasswordFormik.values.verificationCode}
														onChange={securePasswordFormik.handleChange}
														className={`input-width input-border-radius ${classNames({ "p-invalid": isFormSecurePasswordFieldInvalid("verificationCode") })}`}
													/>
													{getFormSecurePasswordErrorMessage("verificationCode")}
												</div>
												<div className=" p-mb-2 margin-top forgotpassword-input-parent-div">
													<label htmlFor="password" style={{paddingBottom: '8px', paddingLeft: '1px'}}>{t("password")}</label>
													<Password value={securePasswordFormik.values.password} onChange={securePasswordFormik.handleChange}id="password" name="password" feedback={false} tabIndex={1}    className={`input-width input-border-radius ${classNames({ "p-invalid": isFormSecurePasswordFieldInvalid("password") })}`}placeholder={t("password_placeholder")} />
													{getFormSecurePasswordErrorMessage("password")}
												</div>
												<div className=" p-mb-2 margin-top forgotpassword-input-parent-div">
													<label htmlFor="confirm_password" style={{paddingBottom: '8px', paddingLeft: '1px'}}>{t("confirm_password")}</label>
													<Password value={securePasswordFormik.values.confirm_password} onChange={securePasswordFormik.handleChange} id="confirm_password" name="confirm_password" feedback={false} tabIndex={1}     className={`input-width input-border-radius ${classNames({ "p-invalid": isFormSecurePasswordFieldInvalid("confirm_password") })}`}placeholder={t("password_placeholder")} />
													{getFormSecurePasswordErrorMessage("confirm_password")}
												</div>
												<div className="confirmPassword-submit-parent-div">
													<Button type="submit" label={t("reset_password")}  className="login-submit-button" /></div>
												<div className="navigate-back-reset-parent">
													<i className="pi pi-arrow-left"></i>
													<button onClick={()=>{navigate("/auth/login");}} className="navigate-back-reset-text-padding">{t("back_to_login")}</button>
												</div>
											</form>)}
									</div>
								</div>:selectCategory==="loginIn"?
									<div className="reset-form-align-center">
										<div className="login-partOne-parent signup-padding-top">
											<div className="logo-forgotpassword-parent ">
												<span className="circleStyle">
													<img src="/check-circle.png" alt="reset-logo-img" className="reset-logo-img-width"/>
												</span>
											</div>
											<h2 className="text-center forgotpassword-heading" >{t("successfully")}</h2>
											<p className="text-center forgotpassword-para">{t("password_magical_statement")}</p>
											<div className="forgotpassword-submit-parent-div">
												<Button type="submit" label={t("continue")}className="login-submit-button" onClick={()=>{navigate("/auth/login");}} /></div>
											<div className="navigate-back-reset-parent">
												<i className="pi pi-arrow-left"></i>
												<button onClick={()=>{navigate("/auth/login");}} className="navigate-back-reset-text-padding">{t("back_to_login")}</button>
											</div>
										</div>
									</div>:<div></div>}
				</div>
			</div>
		</div>
	);
};
export default ResetPasswordComponent;