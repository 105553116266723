import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./Reducers/userAuth";
import mainReducer from "./Reducers/userMain";

const store = configureStore({
	reducer: {
		authData: userReducer,
		mainData:mainReducer
	},
});

export default store;
