import { createSlice, createAsyncThunk, PayloadAction, createAction } from "@reduxjs/toolkit";
import { GlobalService } from "../../modules/shared/services/global.service";
import { HttpService } from "../../modules/shared/services/http.service";
import { UploadProfileImageData, AllGiftsData, Wallpaper, CategorizedWallpapers, cartItem, AllCountriesList, AllRetailerDropdownData } from "../models";
import bcrypt from "bcryptjs";
import _ from "lodash"; // Import lodash
import { type } from "@testing-library/user-event/dist/type";

export const updateStore = createAsyncThunk<any, { updateData: any; successMsg: string, errorMsg: string, toastRef: React.MutableRefObject<any>; }>(
	"users/updateStore",
	async ({ updateData, toastRef, successMsg, errorMsg }, { rejectWithValue }) => {
		try {
			const response = await HttpService.postRequest("stores/updateCustomerInfo", updateData);
			console.log(response,"response");

			toastRef.current.show({
				severity: "success",
				summary: "Success",
				detail: successMsg,
				life: 3000,
			});

			return response;

		} catch (error: any) {
			const { status }: any = error.response;
			const { details }: any = error?.response?.data?.error|| {};;
			if (status === 400) {
				toastRef.current.show({
					severity: "error",
					summary: "Error",
					detail:details,
					life: 3000,
				});
				return rejectWithValue("User Profile does not updated" || "An error occurred while creating a store");
			} else {
				toastRef.current.show({
					severity: "error",
					summary: "Error",
					detail: error.message,
					life: 3000,
				});
				return rejectWithValue(error.message || "An error occurred while creating a store");
			}
		}
	}
);
export const redeemCartDropdown = createAsyncThunk<AllRetailerDropdownData>(
	"users/redeemCartDropdown",
	async (_, { rejectWithValue }) => {
		try {
			const response = await HttpService.getRequest("stores?filters[accountType][$eq]=retailer");

			const { data } = response.data;
			console.log(data, "redeemDropdown");

			GlobalService.setStorage("redeemDropdown", data);
			return data;
		} catch (error: any) {

			console.log(error.response, "redeemCart");

			return rejectWithValue(error.message || "An error occurred while creating a store");

		}
	}
);
export const redeemCartData = createAsyncThunk<any, { data: any; successMsg: string, errorMsg: string, toastRef: React.MutableRefObject<any>; }>(
	"users/redeemCartData",
	async ({ data, toastRef, successMsg, errorMsg }, { rejectWithValue }) => {
		try {
			const response = await HttpService.postRequest("vouchers/redeem20", data);

			toastRef.current.show({
				severity: "success",
				summary: "Success",
				detail: successMsg,
				life: 3000,
			});

			return response;

		} catch (error: any) {
			const { status }: any = error.response;
			const { details }: any = error?.response?.data?.error|| {};;
			if (status === 400) {
				toastRef.current.show({
					severity: "error",
					summary: "Error",
					detail: details,
					life: 3000,
				});
				return rejectWithValue("Cart not successfully redeem" || "An error occurred while creating a store");
			} else {
				toastRef.current.show({
					severity: "error",
					summary: "Error",
					detail: error.message,
					life: 3000,
				});
				return rejectWithValue(error.message || "An error occurred while creating a store");
			}
		}
	}
);
export const placeOrderApi = createAsyncThunk<any, { formattedOrders: any; successMsg: string,mallUserName:string; errorMsg: string, toastRef: React.MutableRefObject<any>; }>(
	"users/formattedOrders",
	async ({ formattedOrders, toastRef, successMsg, mallUserName,errorMsg }, { rejectWithValue }) => {
		try {
			console.log(formattedOrders,"formatted");
			
			const response = await HttpService.postRequest("vouchers/createVoucher20", formattedOrders);
			toastRef.current.show({
				severity: "success",
				summary: "Success",
				detail: successMsg,
				life: 3000,
			});
			const data={...formattedOrders,mallUserName};
			return data;

		} catch (error: any) {
			const { status }: any = error.response;
			const { details }: any = error?.response?.data?.error|| {};;
			let errorMessage: string;

			if (typeof details === 'object' && details !== null) {
  				errorMessage = details.error;
			} else if (typeof details === 'string') {
  				errorMessage = details;
			} else {
  				errorMessage = "An unexpected error occurred";
			
			}
			if (status === 400) {
				toastRef.current.show({
					severity: "error",
					summary: "Error",
					detail: errorMessage.toString(),
					life: 3000,
				});
				return rejectWithValue("Order does not placed" || "An error occurred while creating a order placed");
			} else {
				toastRef.current.show({
					severity: "error",
					summary: "Error",
					detail: error.message.toString(),
					life: 3000,
				});
				return rejectWithValue(error.message || "An error occurred while creating a place order");
			}
		}
	}
);

export const getWallpapers = createAsyncThunk<CategorizedWallpapers, { category: string, toastRef: React.MutableRefObject<any> }>(
	"users/getWallpapers",
	async ({ category, toastRef }, { rejectWithValue }) => {
	  try {
			const response = await HttpService.getRequest(`upload/files?filters[name][%24contains]=${category}`);
			const { data }: any = response;
  
			const formattedData: CategorizedWallpapers = {};
  
			data.forEach((wallpaper: Wallpaper) => {
		  const nameSegments = wallpaper.name.split("_");
		  const key = nameSegments[1];
  
		  if (!formattedData[key]) {
					formattedData[key] = [];
		  }
  
		  formattedData[key].push({
					id: wallpaper?.id,
					thumbnail_url: wallpaper?.formats?.thumbnail?.url || "", 
					medium_url: wallpaper?.formats?.medium?.url || "",
		  });
			});
  
			GlobalService.setStorage("StoreWallPapersData", formattedData);
  
			return formattedData;
	  } catch (error) {
			console.error(error, "error in get Wallpapers");
			return rejectWithValue("An error occurred while fetching wallpapers");
	  }
	}
);


export const changeUserPassword = createAsyncThunk<any, { changePasswordData: any; successMsg: string, errorMsg: string, toastRef: React.MutableRefObject<any>; }>(
	"users/changeUserPassword",
	async ({ changePasswordData, toastRef, successMsg, errorMsg }, { rejectWithValue }) => {
		try {
			const { email, username, mode, old_password, confirm_password } = changePasswordData;
			const responseHash = await HttpService.postRequest("stores/getClientSalt", { email, mode });
			let { data: new_client_salt }: any = responseHash.data;
			new_client_salt = "$2a" + new_client_salt.substring(3, new_client_salt.length);
			const old_client_hash = bcrypt.hashSync(old_password, new_client_salt);
			const new_client_hash = bcrypt.hashSync(confirm_password, new_client_salt);
			const newPasswordObj = {
				username, old_client_hash, new_client_hash, new_client_salt
			};
			const response = await HttpService.postRequest("stores/changePassword", newPasswordObj);

			toastRef.current.show({
				severity: "success",
				summary: "Success",
				detail: successMsg,
				life: 3000,
			});

			return response;

		} catch (error: any) {
			const { status }: any = error.response;
			const { details }: any = error?.response?.data?.error|| {};;

			if (status === 400) {
				toastRef.current.show({
					severity: "error",
					summary: "Error",
					detail: details,
					life: 3000,
				});
				return rejectWithValue("Incorrect Old password!" || "An error occurred while creating a store");
			} else {
				toastRef.current.show({
					severity: "error",
					summary: "Error",
					detail: error.message,
					life: 3000,
				});
				return rejectWithValue(error.message || "An error occurred while creating a store");
			}
		}
	}
);
export const getAllGifts = createAsyncThunk<AllGiftsData, { toastRef: React.MutableRefObject<any>; }>(
	"users/getAllGifts",
	async ({ toastRef }, { rejectWithValue }) => {
		try {
			console.log("call");

			const response = await HttpService.getRequest("stores?filters[accountType][$eq]=mall");
			const { data } = response;

			const StoreGiftData: AllGiftsData = data.data;
			GlobalService.setStorage("StoreGiftData", StoreGiftData);

			return StoreGiftData;

		} catch (error: any) {
			const { status }: any = error.response;
			const { details }: any = error?.response?.data?.error|| {};;
			if (status === 400) {
				toastRef.current.show({
					severity: "error",
					summary: "Error",
					detail:details,
					life: 3000,
				});
				return rejectWithValue("Gifts failed to fetched " || "An error occurred while creating a store");
			} else {
				return rejectWithValue(error.message || "An error occurred while creating a store");
			}
		}
	}
);
export const uploadProfileImage = createAsyncThunk<UploadProfileImageData, { updateData: any; successMsg: string, errorMsg: string, toastRef: React.MutableRefObject<any>; }>(
	"users/uploadProfileImage",
	async ({ updateData, successMsg, errorMsg, toastRef }, { rejectWithValue }) => {
		try {
			const response = await HttpService.postRequest("upload", updateData);
			toastRef.current.show({
				severity: "success",
				summary: "Success",
				detail: successMsg,
				life: 3000,
			});
			let { data }: any = await response;
			data = data[0];
			const uploadProfileImageData: UploadProfileImageData = data;
			GlobalService.setStorage("uploadProfileImageData", uploadProfileImageData);
			return data;

		} catch (error: any) {
			const { status }: any = error.response;
			const { details }: any = error?.response?.data?.error|| {};;

			if (status === 400) {
				toastRef.current.show({
					severity: "error",
					summary: "Error",
					detail: details,
					life: 3000,
				});
				return rejectWithValue("Image does not updated" || "An error occurred while creating a store");
			} else {
				toastRef.current.show({
					severity: "error",
					summary: "Error",
					detail: error.message,
					life: 3000,
				});
				return rejectWithValue(error.message || "An error occurred while creating a store");
			}
		}
	}
);

interface UserState {
	UploadProfileImageData: UploadProfileImageData | null,
	AllGiftsData: AllGiftsData | [],
	CategorizedWallpapers: CategorizedWallpapers | [],
	cartItems: { [username: string]: cartItem[] };
	AllRetailerDropdownData: AllRetailerDropdownData | [];
	AllCountriesList: AllCountriesList | [];


}
const initialUploadProfileImageDataInfo = localStorage.getItem("uploadProfileImageData");
const initialAllGiftsDataInfo = localStorage.getItem("StoreGiftData");
const initialAllWallPapersDataInfo = localStorage.getItem("StoreWallPapersData");
const initialCartItemsInfo = localStorage.getItem("StoreCartItemsData");
const initialRetailerDropdown = localStorage.getItem("redeemDropdown");
const initialCountryDropdown = localStorage.getItem("AllCountriesList");



const initialState: UserState = {
	AllCountriesList: initialCountryDropdown ? JSON.parse(initialCountryDropdown) : [],

	AllRetailerDropdownData: initialRetailerDropdown ? JSON.parse(initialRetailerDropdown) : [],
	UploadProfileImageData: initialUploadProfileImageDataInfo ? JSON.parse(initialUploadProfileImageDataInfo) : null,
	cartItems: initialCartItemsInfo ? JSON.parse(initialCartItemsInfo) : {},
	AllGiftsData: initialAllGiftsDataInfo ? JSON.parse(initialAllGiftsDataInfo) : null,
	CategorizedWallpapers: initialAllWallPapersDataInfo ? JSON.parse(initialAllWallPapersDataInfo) : null,
};



const mainReducer:any = createSlice({
	name: "mainusers",
	initialState,
	reducers: {
		addToCart: (state, action) => {
			const newItem = action.payload;
			const { username } = newItem;
	  
			// Check if the user's cart exists, create it if not
			if (!state.cartItems[username]) {
			  state.cartItems[username] = [];
			}
	  
			state.cartItems[username].push(newItem);
			GlobalService.setStorage("StoreCartItemsData", state.cartItems);
		  },
		  updateCartQty: (state, action) => {
			const { username, updatedCart } = action.payload;
			state.cartItems[username] = updatedCart;
			GlobalService.setStorage("StoreCartItemsData", state.cartItems);
		  },
		  clearCart: (state, action) => {
			const username = action.payload;
			if (state.cartItems[username]) {
				state.cartItems[username] = [];
			}
			GlobalService.setStorage("StoreCartItemsData", state.cartItems);
		  },
	},
	extraReducers: (builder) => {

		builder.addCase(uploadProfileImage.fulfilled, (state, action: PayloadAction<UploadProfileImageData>) => { state.UploadProfileImageData = action.payload; });
		builder.addCase(placeOrderApi.fulfilled, (state, action: PayloadAction<any>) => {
			const response = action.payload;
			console.log(response,"action.payload");
			const usernameToRemove = response.mallUserName;

			// // Find the index of the item to remove based on the username
			// const indexToRemove = state.cartItems[usernameToRemove].findIndex((item) => item.username === response.mall_username);

			// Remove the item from the cartItems array
			// if (indexToRemove !== -1) {
			// 	state.cartItems[usernameToRemove].splice(indexToRemove, 1);
			// 	console.log(state.cartItems[usernameToRemove],"state.cart");
				
			// 	GlobalService.setStorage("StoreCartItemsData", state.cartItems);
			// }
			state.cartItems[usernameToRemove] = [];
			GlobalService.setStorage("StoreCartItemsData", state.cartItems);
		});
		builder.addCase(getAllGifts.fulfilled, (state, action: PayloadAction<AllGiftsData>) => {
			state.AllGiftsData = action.payload;
		});
		builder.addCase(redeemCartDropdown.fulfilled, (state, action: PayloadAction<AllRetailerDropdownData>) => {
			state.AllRetailerDropdownData = action.payload;
		});
		builder.addCase(getWallpapers.fulfilled, (state, action: PayloadAction<CategorizedWallpapers>) => {
			state.CategorizedWallpapers = action.payload;
		});
	},
});

export const { addToCart, updateCartQty, clearCart } = mainReducer.actions;

export default mainReducer.reducer;
