import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { classNames } from "primereact/utils";
import { useDispatch, useSelector } from "react-redux";
import "../../Assets/Styling/auth.css";
import { createStore, getJwtToken } from "../../../store/Reducers/userAuth";
import { ThunkDispatch } from "redux-thunk";
import {useNavigate,NavigateFunction} from "react-router-dom";
import { FormValues,CreateStoreProps } from "../models";
import { RootState } from "../../../store/models";
import { ProgressSpinner } from "primereact/progressspinner";
import { useTranslation } from "react-i18next";
import { Password } from "primereact/password";

const CreateStoreComponent: React.FC<CreateStoreProps> = ({ appToastRef }) => {  const dispatch: ThunkDispatch<RootState, void, any> = useDispatch();
	const navigate: NavigateFunction = useNavigate(); // Explicitly set the type here
	const [showSpinner, setShowSpinner] = useState(false);
	const calledBy = useSelector((state: RootState) => state.authData.jwtToken?.calledBy);
	const { t } = useTranslation();
	
	const handleSubmit = async (values: FormValues) => {
		try {
			setShowSpinner(true);
			const staticFields = {
				accountType: values.type,
				status: "test",
			};
	
			// Dispatch getJwtToken action
			const jwtTokenAction = dispatch(getJwtToken({
				identifier: values.identifier,
				password: values.password,
				calledBy: "createStore",
				errorMsg: t("identifier_error"),
				toastRef: appToastRef,
			}));
	
			// Wait for the getJwtToken action to complete
			const jwtTokenActionResult = await jwtTokenAction;
	
			// Check if there is an error in getJwtToken
			if (getJwtToken.rejected.match(jwtTokenActionResult)) {
				// Show toast message for the error
				setShowSpinner(false);
				return;
			}
	
			// If there is no error, dispatch createStore action
			await dispatch(createStore({
				dataEmail: { ...values, ...staticFields },
				successMsg: t("store_created_success"),
				errorMsg: t("store_created_error"),
				toastRef: appToastRef,
				navigate,
			}));
	
			setShowSpinner(false);
		} catch (error: any) {
			setShowSpinner(false);
			console.error("Sign-up error:", error);
		}
	};
	
	const languages = [
		{ label: t("english"), value: "English" },
		{ label: t("german"), value: "German" },
	  ];
	  
	  const type = [
		{ label: t("retailer"), value: "retailer" },
		{ label: t("mall"), value: "mall" },
	  ];
	  
	const formik = useFormik({
		initialValues: {
			name: "",
			email: "",
			type:"",
			website: "",
			language: "",
			identifier:"",
			password:""
		},
		validate: (data) => {
			const errors: Partial<{ name: string, email: string, website: string,type:string ,language: string,identifier:string,password:string }> = {};
			if (!data.name) {
				errors.name = t("name_required_error");
			}
			if (!data.identifier) {
				errors.identifier = t("identifier_required");
			}
			if (!data.password) {
				errors.password = t("invalid_password_error");
			}
			if (!data.email) {
				errors.email = t("invalid_email_error_required");
			} else if (!/^\S+@\S+\.\S+$/.test(data.email)) {
				errors.email = t("invalid_email_error");
			}
			if (!data.website) {
				errors.website = t("website_required_error");
			}
			if (!data.type) {
				errors.type = t("type_required_error");
			}
			if (!data.language) {
				errors.language = t("language_required_error");
			}
			return errors;
		},
		onSubmit: (data) => {
			// Handle form submission here
			handleSubmit(data);
		},
	});
	const isFormFieldInvalid = (name: keyof typeof formik.values) =>
		!!(formik.touched[name] && formik.errors[name]);
	const getFormErrorMessage = (name: keyof typeof formik.values) =>
		isFormFieldInvalid(name) ? (
			<small className="p-error margin-top">{formik.errors[name]}</small>
		) : (
			<small className="p-error">&nbsp;</small>
		);
	return (
		<div className="login-parent-div">
			<div className="flex-basis-60">
				<div className="login-partOne-parent">
					<div className="logo-login-parent">
						<img src="/image 2.png"alt="" className="width-complete-100" height="106"/>
					</div>
					<h2 className="text-center store-heading" >{t("create_store")}</h2>
					{showSpinner ? (
						<ProgressSpinner />
					):(
						<form className="login-form-div"
							onSubmit={formik.handleSubmit}
						>
							<div className=" p-mb-2 margin-top login-input-parent-div">
								<label htmlFor="name">{t("name")}</label>
								<InputText
									id="name"
									name="name"
									value={formik.values.name}
									onChange={formik.handleChange}
									placeholder={t("name_placeholder")}
									className={`input-width input-border-radius ${classNames({ "p-invalid": isFormFieldInvalid("name") })}`}
								/>
								{getFormErrorMessage("name")}   
							</div>
							<div className=" p-mb-2 margin-top login-input-parent-div">
								<label htmlFor="email">{t("email")}</label>
								<InputText
									id="email"
									placeholder={t("email_placeholder")}
									name="email"
									value={formik.values.email}
									onChange={formik.handleChange}
									className={`input-width input-border-radius ${classNames({ "p-invalid": isFormFieldInvalid("email") })}`}
								/>
								{getFormErrorMessage("email")}
							</div>
							<div className=" p-mb-2 margin-top login-input-parent-div">
								<label htmlFor="website">{t("website")}</label>
								<InputText
									id="website"
									name="website"
									value={formik.values.website}
									onChange={formik.handleChange}
									placeholder={t("website_placeholder")}
									className={`input-width input-border-radius ${classNames({ "p-invalid": isFormFieldInvalid("website") })}`}
								/>
								{getFormErrorMessage("website")}   
							</div>
							<div className=" p-mb-2 margin-top login-input-parent-div">
								<label htmlFor="language">{t("language")}</label>
								<Dropdown
									id="language"
									name="language"
									value={formik.values.language}
									options={languages}
									onChange={(e) => formik.setFieldValue("language", e.value)}
									placeholder={t("language_placeholder")}
									className={`input-width input-border-radius ${classNames({ "p-invalid": isFormFieldInvalid("language") })}`}
								/>
								{getFormErrorMessage("language")}   
							</div>
							<div className=" p-mb-2 margin-top login-input-parent-div">
								<label htmlFor="type">{t("type")}</label>
								<Dropdown
									id="type"
									name="type"
									value={formik.values.type}
									options={type}
									onChange={(e) => formik.setFieldValue("type", e.value)}
									placeholder={t("type_placeholder")}
									className={`input-width input-border-radius ${classNames({ "p-invalid": isFormFieldInvalid("type") })}`}
								/>
								{getFormErrorMessage("type")}   
							</div>
							<div className=" p-mb-2 margin-top login-input-parent-div">
								<label htmlFor="identifier">{t("identifier")}</label>
								<InputText
									id="identifier"
									name="identifier"
									value={formik.values.identifier}
									onChange={formik.handleChange}
									placeholder={t("identifier_placeholder")}
									className={`input-width input-border-radius ${classNames({ "p-invalid": isFormFieldInvalid("identifier") })}`}
								/>
								{getFormErrorMessage("identifier")}   
							</div>
							<div className=" p-mb-2 margin-top login-input-parent-div">
								<label htmlFor="password">{t("password")}</label>
							
								<Password
									value={formik.values.password}
									onChange={formik.handleChange}
									id="password"
									name="password"
									feedback={false}
									tabIndex={1}
									className={`input-width input-border-radius ${classNames({ "p-invalid": isFormFieldInvalid("password") })}`}
									placeholder={t("password_placeholder")}
								/>
								{getFormErrorMessage("password")}   
							</div>
							
							

							<div className="width-fifty">
								<Button type="submit" label={t("submit")} className="login-submit-button margin-top" /></div>
							<div className="p-field  p-grid store-headingt" >
								<div className="p-col">
									<div className="navigate-auth-option-btn">
										<p>{t("already_have_account")}</p>
										<Button label={t("login")} text  onClick={() => navigate("/auth/login")}/>
									</div>
								</div>
							</div>
						</form>
					)}
				</div>
			</div>
			<div className="flex-basis-40">
				<div>
					<img src="/Sign in 1.png" className="width-complete-100" alt=""/>
				</div>
			</div>
    
		</div>
	);
};
export default CreateStoreComponent;
