import React, { useEffect, useRef, useState } from "react";
import "./App.scss";
import "primereact/resources/themes/md-light-indigo/theme.css";
// import "primereact/resources/themes/md-dark-indigo/theme.css";
import AppRoutes from "./modules/navigation/AppRoute";
import { requestInterceptor } from "./modules/shared/services/http-request.interceptor";
import { responseInterceptor } from "./modules/shared/services/http-response.interceptor";
import { Toast } from "primereact/toast";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "./store/models";
import { getJwtToken } from "./store/Reducers/userAuth";
import { ThunkDispatch } from "@reduxjs/toolkit";
import i18n from "i18next";
import { Dropdown } from "primereact/dropdown";
import { GlobalService } from "./modules/shared/services/global.service";
import PropTypes from "prop-types";
import { MenuItem, TextField,ListItem } from "@mui/material";


function App() {
	const appToast = useRef({} as any);
	const dispatch: ThunkDispatch<RootState, void, any> = useDispatch();
	const [selectedLanguage, setSelectedLanguage] = useState("en");


	const [languages, setLanguages] = useState([
		{
			name: "English",
			value: "en"
		},
		{
			name: "Deutsch",
			value: "de"		
		},

	]);
	const jwtToken = useSelector((state: RootState) => state.authData.jwtToken?.jwt);

	const getDefaultLanguge = async () => {
		let language: any = await GlobalService.getStorage("language");

		if (language) {
			setSelectedLanguage(language);
		}
		else {
			language = navigator.language.split("-")[0];
			setSelectedLanguage(language);
			GlobalService.setStorage("language", language);
		}
		i18n.changeLanguage(language);
	};

	useEffect(() => {
		if (jwtToken == null) {
			 dispatch(getJwtToken({identifier:"",password:"",calledBy:"App",errorMsg: "", toastRef: appToast}));		}
	}, [jwtToken]);

	useEffect(() => {
		getDefaultLanguge();
		requestInterceptor();
		responseInterceptor();
	}, []);

	const handleLanguageChange = (language: any) => {
		i18n.changeLanguage(language).then(() => {
			setSelectedLanguage(language);
			GlobalService.setStorage("language", language);
		});
	};

	const selectedCountryTemplate = (option:any, props:any) => {
		if (option) {
			return (
				<div className="flex align-items-center">
					<img src={`/${option.value}.png`} style={{ width: "20px" }} />
					<div style={{ marginLeft: "7px"}}>{option.name}</div>
				</div>
			);
		}

		return <span>{props.placeholder}</span>;
	};


	  
	const countryOptionTemplate = (option: any) => {
		return (
			<div className="flex align-items-center">
				<img src={`/${option.value}.png`} style={{ width: "20px" }} />
				<div style={{ marginLeft: "7px"}}>{option.name} </div>
			</div>
		);
	};

	return (
		<div>
			<div className="card" style={{ position: "fixed", right: "15px", top: "12px", zIndex: "1000"}}>
				<TextField
					id="standard-select-currency"
					size="small"
					select
					value={selectedLanguage}
					variant="outlined"
					style={{marginTop:"2%", borderRadius: "68px"}}
					className='selectedButton'
					onChange={(e) => {handleLanguageChange(e.target.value); }}
					sx={{ 
					"& .MuiInputBase-input": { py: 0.5, fontSize: "0.875rem" },
					"& .MuiOutlinedInput-root": {
						"& fieldset": { borderColor: "grey" },
						"&:hover fieldset": { borderColor: "grey" },
						"&.Mui-focused fieldset": { borderColor: "grey" },
					},
					}}
				>
				{languages.map((option) => (
					<MenuItem key={option.value} value={option.value} style={{width: "5%", padding: "0px", fontSize: "13px"}}>
						<ListItem style={{ display: "flex", alignItems: "center" }}>
						<img src={`/${option.value}.png`} style={{ width: "20px" }} />
						<div style={{ marginLeft: "7px"}}>{option.name}</div>
						</ListItem>
					</MenuItem>
				))}
  				</TextField>
			</div>
			<Toast ref={appToast} position="top-right" />
			<AppRoutes appToastRef={appToast} />
		</div>
	);
}

App.propTypes = {
	placeholder: PropTypes.any
};

export default App;
