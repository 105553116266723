import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import LoginComponent from "../components/LoginComponent";
import SignupComponent from "../components/SignUpComponent";
import CreateStoreComponent from "../components/CreateStoreComponent";
import ResetPasswordComponent from "../components/ResetPasswordComponent";
import {useSelector } from "react-redux";
import { RootState } from "../../../store/models";
import { useNavigate, NavigateFunction } from "react-router-dom";
import NotFoundComponent from "../components/NotFoundComponent";
const AuthRoutes = ({appToastRef}:any) => {
	const navigate: NavigateFunction = useNavigate();

	const loginData = useSelector((state: RootState) => state.authData.LoginInterface);

	useEffect(() => {
		if ( loginData) {
			navigate("/home/dashboard");
		}
	}, [ loginData, navigate]);
	return (
		<Routes>
			<Route index element={<LoginComponent appToastRef={appToastRef} />} />
			<Route path="/login" element={<LoginComponent appToastRef={appToastRef} />} />
			<Route path="/signup" element={<SignupComponent appToastRef={appToastRef} />} />
			<Route path="/createStore" element={<CreateStoreComponent appToastRef={appToastRef} />} />
			<Route path="/resetPassword" element={<ResetPasswordComponent appToastRef={appToastRef} />} />
			<Route path="*" element={<NotFoundComponent />} />

		</Routes>
	);
};

export default AuthRoutes;