import React from "react";
import "../../DashboardComponent.css";
import { Card } from "primereact/card";
import { useTranslation } from "react-i18next";
import { Chip, Grid, Stack, Typography } from "@mui/material";
import CIcon from "@coreui/icons-react";
import { cilChartLine,cilHistory,cilGift } from "@coreui/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from '@mui/material/Button';

import { RiseOutlined, FallOutlined } from "@ant-design/icons";
type CardProps = {
    title: string;
    value: string;  
    percentage?: number;
    content?: any;
    isLoss?: boolean;
    color?: string;
    window: string;
	icon?: any;
	onClick? :any;
}


const DashboardCard = ({ title, value, content, percentage, isLoss, color, window, icon, onClick} : CardProps) => {
	const cards = [
		{
			title: {title},
			value: {value},
			content: {content},
			percentage: {percentage},
			isLoss: {isLoss},
			color: {color},
			window: {window},
			icon: {icon},
			onClick: {onClick}
		}
	];

	const { t } = useTranslation();

	return (
		<Card id='dashboardCard1'>
			<Stack spacing={0.5}>
				
				<Typography variant="h6" color="textSecondary" id="titleCard">
				<FontAwesomeIcon icon={icon} style={{ color: '#146ebe', fontWeight: 'bold' }} /> <span></span>{title}
				</Typography>
				<Grid container alignItems="center">
					<Grid item>
						<Typography variant="h4" color="inherit" id="valueTitle">
							{value}
							{onClick && (
							<Button className="viewpartnerbutton" onClick={onClick} variant="outlined" color="primary" >
							{t("view_stores")}
							</Button>
						)}
						</Typography>
						
					</Grid>
					{percentage && (
						<Grid item>
							<Chip 
								variant='filled'
								sx={{ml: 1.25, pl: 1,
									borderRadius:"3px", 
									backgroundColor: !isLoss ? "#e6f4ff" : "#fffbe6",
									border: "1px solid #d9d9d9",
									borderColor: !isLoss? "#1677ff" : "#faad14", 
									color: !isLoss? "#1677ff" : "#faad14"}}
								icon={
									<>
										{!isLoss && <RiseOutlined style={{ fontSize: "0.75rem", color: "inherit" }} />}
										{isLoss && <FallOutlined style={{ fontSize: "0.75rem", color: "inherit"}} />}
									</>
								}
								label={`${percentage}%`}
								size="small"
							/>
						</Grid>
					)}
				</Grid> 	
			</Stack>

		</Card>

	);
};


DashboardCard.defaultProps = {
	color: "primary"
};

export default DashboardCard;