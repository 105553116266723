import React, { useState, useEffect } from "react";
import { classNames } from "primereact/utils";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText  } from "primereact/inputtext";
import { Form, Field, useFormState } from "react-final-form";

import { Card } from "primereact/card";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { useNavigate, NavigateFunction } from "react-router-dom";
import { RootState } from "../../../../../../store/models";
import { Typography } from "@mui/material";
import { transactionTableData,downloadTransactions } from "../../../../../../store/Reducers/userAuth";
import { TransactionTable } from "../../../../../main/models";
import "../../DashboardComponent.css";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { cilSearch,cilHistory } from "@coreui/icons";
import CIcon from "@coreui/icons-react";        
import { MenuItem, TextField } from "@mui/material";


interface Transactions {
    id: number;
    transactionhash: string;
    actionName: string;
    type: string;
    amount: number;
    createdAt: string;
    accounts: string;
}

interface Filter {
  name: string | null;
  transactionid: string | null;
  startdate: Date | null;
  enddate: Date | null;
}
export default function TransactionComponent({ appToastRef }: any) {
 
	const dispatch: ThunkDispatch<RootState, void, any> = useDispatch();
	const navigate: NavigateFunction = useNavigate(); // Explicitly set the type here
	const [checked, setChecked] = useState(false);
	const [showSpinner, setShowSpinner] = useState(false);
	const { t } = useTranslation();
	const tableData:any = useSelector((state: RootState) => state.authData.TransactionTableData?.table_data);
	const loginData = useSelector((state: RootState) => state.authData.LoginInterface?.username);
	const accountType = useSelector((state: RootState) => state.authData.LoginInterface?.accountType);

	const [selectedMinDate, setSelectedMinDate] = useState(new Date());


	//For the table
	const [customers, setCustomers] = useState<Transactions[]>(tableData);
	const [selectedTableFilter, setSelectedTableFilter] = useState("purchase");
	const [filters, setFilters] = useState({
		accounts: { value: null, matchMode: FilterMatchMode.CONTAINS },
		actionName: { value: null, matchMode: FilterMatchMode.CONTAINS },
		amount: { value: null, matchMode: FilterMatchMode.CONTAINS },
		createdAt: { value: null, matchMode: FilterMatchMode.CONTAINS },
		id: { value: null, matchMode: FilterMatchMode.CONTAINS },
		transactionhash: { value: null, matchMode: FilterMatchMode.CONTAINS },
		type: { value: null, matchMode: FilterMatchMode.CONTAINS }
	});

 
	const SELECTED_WINDOW_KEY = "selectedWindow";

	const [selectedWindow, setselectedWindow] =  useState(localStorage.getItem(SELECTED_WINDOW_KEY) || "week");

	const windowFrame = [
		{
			value: "week",
			label: t("week")
		},
		{
			value: "month",
			label: t("month")
		},
		{
			value: "year",
			label: t("year")
		}
	];

	const [startDate, setStartDate] = useState(null);

	const [clearSelection, setClearSelection] = useState(false);
	const [loading, setLoading] = useState(false);
	const [formData, setFormData] = useState({});
	const [showMessage, setShowMessage] = useState(false);

	const [mindate, setDate] = useState<Date>(new Date());
	const[defaultStartDate, setDefaultStartDate] = useState(new Date());


	useEffect(() => {
	  const storedFilter = localStorage.getItem(SELECTED_WINDOW_KEY);
	  if (storedFilter !== null) {
			setselectedWindow(storedFilter);
	  }
	}, []);
  
	useEffect(() => {
	  localStorage.setItem(SELECTED_WINDOW_KEY, selectedWindow);
	}, [selectedWindow]);


	useEffect(() => {
		updateMinDate(selectedWindow);
	}, [selectedWindow]);
	
	const updateMinDate = (window: string) => {
		const now = new Date();
		if (window === "week") {
		  now.setDate(now.getDate() - 7);
		} else if (window === "month") {
		  now.setMonth(now.getMonth() - 1);
		} else if (window === "year") {
		  now.setFullYear(now.getFullYear() - 1);
		}
		setSelectedMinDate(now);
	};

	const handleExportDataButtonClick = async() => {
		try {
			const oneMonthFromSystemDate = new Date();
			let count: any;
			if(selectedWindow) {
				count = (selectedWindow === "month" || selectedWindow === "monat") ? 30 : (selectedWindow === "year" || selectedWindow === "jahr") ? 365 : 7;
			}
			oneMonthFromSystemDate.setDate(oneMonthFromSystemDate.getDate()-count);
			const exportFormData:any = {
				username: loginData, 
				createdAtStart: oneMonthFromSystemDate.toLocaleString(),
			};
			await handleExportData(exportFormData);
		}
		catch(error) {
			console.error(error);
		}
	};

	const handleExportData = async (values: any) => {
		try {
			setShowSpinner(true);
			setLoading(true);
			const result = await dispatch(downloadTransactions({ downloadTransaction: { ...values},successMsg:t("transaction_data_sent"),errorMsg: t("An error occured while exporting the data. Please try again."), toastRef: appToastRef }));
			if(downloadTransactions.fulfilled.match(result)) {
				const response = result.payload;
				if(response.statusCode === 200) {
					console.log("The request was successfull");
				} else{
					console.log("The request was not successfull", response.statusCode);
				}
			} else {
				if(result.payload) {
					console.log("Error message", result.payload);
				} else {
					console.log("Error message", result.error);
				}
			}
        
			setLoading(false);
			setShowSpinner(false);
		} catch (error: any) {
			setShowSpinner(false);
			console.error("Login error:", error);
		}
	};

	const paginatorLeft = <Button type="button" text />;
	const paginatorRight = <Button type="submit" icon="pi pi-download" label={t("export_data")}  style={{backgroundColor: "#6366F1"}} onClick={() => handleExportDataButtonClick()} disabled={!customers || customers?.length ===0}/>;
    
	const onSelectedWindowFrame = async (windowFrame: string) => {
		const today: any = new Date();
		setselectedWindow(windowFrame);
		updateMinDate(windowFrame);
		const dayCount: number = windowFrame === "month" ? 30 : windowFrame === "year" ? 365 : 7;
		const oneMonthFromSystemDate = new Date();
		oneMonthFromSystemDate.setDate(oneMonthFromSystemDate.getDate()-dayCount);

		const formData4: TransactionTable = {
			username: loginData ? loginData : "", 
			createdAtStart: oneMonthFromSystemDate.toLocaleString(),
			createdAtEnd: today.toLocaleString(),
			limit: 25,
			actionName: accountType === "retailer" ? "redeem" : selectedTableFilter === "purchase" ? "create" : "redeem",
			start: 0,
			transactionHash: "",
		};
		await handleSubmit(formData4);
		setCustomers(tableData);
	};



    
	// //handle week or month selection
	const handleTableButtonClick = async (buttonType: any) => {
		try {
			const today1: any = new Date();
			setSelectedTableFilter(buttonType);
			const formData1: TransactionTable = {
				username: loginData ? loginData : "",
				createdAtStart: "",
				createdAtEnd: today1.toLocaleString(),
				limit: 25,
				actionName: buttonType === "purchase" ? "create" : "redeem",
				start: 0,
			};
			const dateFromSystemDate = new Date();
			const dayCount: number = selectedWindow === "month" ? 30 : selectedWindow === "year" ? 365 : 7;
			if(buttonType === "purchase") {
				dateFromSystemDate.setDate(dateFromSystemDate.getDate()-dayCount);
				formData1.createdAtStart = dateFromSystemDate.toLocaleString();
			} else if(buttonType === "redeem") {
				dateFromSystemDate.setDate(dateFromSystemDate.getDate()-dayCount);
				formData1.createdAtStart = dateFromSystemDate.toLocaleString();
			}
			await handleSubmit(formData1);
		}
		catch(error) {
			console.error(error);
		} 
	};

	const handleSubmit = async (values: TransactionTable) => {
		try {
			setShowSpinner(true);
			setTimeout(() => {
				setLoading(true);
				dispatch(transactionTableData({ tableData: { ...values },successMsg:t(""),errorMsg: t("Error Loading data from server"), toastRef: appToastRef, navigate }));
			}, 500);
			setShowSpinner(false);
		} catch (error: any) {
			setShowSpinner(false);
			console.error("Login error:", error);
		}
	};

	useEffect(() => {
		setLoading(false);
		if(tableData) {
			setCustomers(tableData);
		}
	}, [tableData]);

	const onSubmit = async (data: any, form:any ) => {
		setClearSelection(true);
		const formData2: TransactionTable = {
			username: loginData ? loginData : "",
			createdAtStart: data.startdate,
			createdAtEnd: data.enddate,
			limit: 25,
			actionName: selectedTableFilter === "purchase" ? "create" : selectedTableFilter,
			start: 0,
			transactionHash: data.transactionid,
			customer_username: data.name,
		};
		await handleSubmit(formData2);
		setCustomers(tableData);
		setFormData(data);
		setShowMessage(true);
	};

	const isFormFieldValid = (meta: any) => !!(meta.touched && meta.error);
	const getFormErrorMessage = (meta: any) => {
		return isFormFieldValid(meta) && <small className="p-error">{meta.error}</small>;
	};

	const handleReset = async (form:any) => {
		const value:any = selectedWindow === "month" ? 30 : selectedWindow === "year" ? 365 : 7;
		const oneMonthFromSystemDate = new Date();
		oneMonthFromSystemDate.setDate(oneMonthFromSystemDate.getDate()-value);
		const today3: any = new Date();
		const formData3: TransactionTable = {
			username: loginData ? loginData : "",
			createdAtStart: oneMonthFromSystemDate.toLocaleString(),
			createdAtEnd: today3.toLocaleString(),
			limit: 25,
			actionName: selectedTableFilter === "purchase" ? "create" : selectedTableFilter,
			start: 0,
			transactionHash: "",
		};
		await handleSubmit(formData3);
		setCustomers(tableData);
		form.reset();
		setClearSelection(false);
	};

	const setMindate = (e:any) => {
		if(e && e!==null) {
			setDate(new Date(e));
		}
	};

	const headerStyle = { fontFamily: "'Public Sans', sans-serif", fontWeight: "600", fontSize: "1rem", lineHeight: "1.5"};

	return (
		<div>
			<div className='searchField'>
				<Typography variant="h5" id="dashboardTitle"><CIcon icon={cilSearch} size="sm" style={{width: "20px"}}/> Search Transaction </Typography>
				<Card  id='dashboardCard'  style={{marginTop: "1%", height:"88px"}} >
					<div>
						<Form onSubmit={onSubmit} initialValues={{name: "",transactionid: "",startdate: null,enddate: null,}} render={({ handleSubmit,values,form  }) => (
							<form onSubmit={handleSubmit} className="p-fluid" style={{display: "flex", gap: "2rem"}}>
								<div className="col-md-4" style={{width: "18%"}}>
									<Field name="name" render={({ input, meta }) => (
										<div className="field">
											<span className="p-float-label">
												<InputText id="name" {...input} className={classNames({ "p-invalid": isFormFieldValid(meta) })} />
												<label htmlFor="name" className={classNames({ "p-error": isFormFieldValid(meta) })}>{t("customer_name")}</label>
											</span>
											{getFormErrorMessage(meta)}
										</div>
									)} />
								</div>
								<div className="col-md-4" style={{width: "23%"}}>
									<Field name="transactionid" render={({ input, meta }) => (
										<div className="field">
											<span className="p-float-label p-input-icon-right">
												<InputText id="transactionid" {...input} className={classNames({ "p-invalid": isFormFieldValid(meta) })} />
												<label htmlFor="transactionid" className={classNames({ "p-error": isFormFieldValid(meta) })}>{t("transaction_id")}</label>
											</span>
											{getFormErrorMessage(meta)}
										</div>
									)} />
								</div>
								<div className="col-md-4" style={{width: "18%"}}>
									<Field name="startdate" render={({ input }) => (
										<div className="field">
											<span className="p-float-label">
												<Calendar id="startdate" value={selectedMinDate}
													onSelect={(e) => {
														input.onChange(e.value);
													}} minDate={selectedMinDate} maxDate={new Date()} dateFormat="dd/mm/yy" mask="99/99/9999" showIcon />
												<label htmlFor="startdate">{t("start_date")}</label>
											</span>
										</div>
									)} />
								</div>
								<div className="col-md-4" style={{width: "18%"}}>
									<Field name="enddate" render={({ input }) => (
										<div className="field">
											<span className="p-float-label">
												<Calendar id="enddate" {...input}  minDate={selectedMinDate} dateFormat="dd/mm/yy" mask="99/99/9999" maxDate={new Date()} showIcon  />
												<label htmlFor="enddate">{t("end_date")}</label>
											</span>
										</div>
									)} />
								</div>
								<div className="col-md-4"  style={{width: "8%"}}>
									<Button type="submit" label={t("search")} className="mt-2" style={{backgroundColor: "#6366F1"}} disabled={
										!(values.name || values.transactionid || values.startdate || values.enddate) }/>
								</div>
								<div className="col-md-4"  style={{width: "10%"}}>
									<Button type="reset" label={t("reset")} className="mt-2" style={{backgroundColor: "#6366F1"}}  onClick={() => {form.reset(); handleReset(form);}} 
										disabled={!form.getState().dirty || !(values.name || values.transactionid || values.startdate || values.enddate) || !clearSelection}/>
								</div>       
							</form>
						)} />
					</div>
				</Card>
			</div>

			<div className='transactiontable'>
				<Typography variant="h5" id="dashboardTitle"><span><CIcon icon={cilHistory} size="sm" style={{width: "20px"}}/> </span> {t("transaction_history")}</Typography>
				<div className='tableToggleButton'>
					{accountType !== "retailer" && (
						<>
							<Button type="submit" className={selectedTableFilter === "purchase" ? "selectedButton" : "myButton"} onClick={() => handleTableButtonClick("purchase")}>{t("purchase")}</Button>      
							<Button type="submit" className={selectedTableFilter === "redeem" ? "selectedButton" : "myButton"} onClick={() => handleTableButtonClick("redeem")}>{t("redeem")}</Button>
						</>
					)}
              
					<TextField
						id="standard-select-currency"
						size="small"
						select
						value={selectedWindow}
						style={{marginTop:"2%",borderColor: "#2196F3"}}
						className='selectedButton'
						onChange={(e) => {setselectedWindow(e.target.value); onSelectedWindowFrame(e.target.value);}}
						sx={{ "& .MuiInputBase-input": { py: 0.5, fontSize: "0.875rem" } }}
					>
						{windowFrame.map((option) => (
							<MenuItem key={option.value} value={option.value}>
								{option.label}
							</MenuItem>
						))}
					</TextField>

				</div>
				<Card id='dashboardCard' style={{marginTop: "1%", width: "99%"}}>
					<DataTable value={customers} paginator rows={10} dataKey="id" loading={loading} 
						rowsPerPageOptions={[5, 10, 25, 50]} paginatorLeft={paginatorLeft} paginatorRight={paginatorRight}
						paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"   emptyMessage={t("no_transactions_found")} >
						<Column field="transactionhash" header={<span style={headerStyle}>{t("transaction_id")}</span>} filter filterPlaceholder="Search by transaction hash" sortable  style={{ minWidth: "15rem",whiteSpace: "nowrap" }} showFilterMenu={false} filterHeaderClassName='small-text'/>
						<Column field="accounts" header={<span style={headerStyle}>{t("customer_name")}</span>} filter filterPlaceholder="Search by accounts" sortable  style={{ minWidth: "8rem",whiteSpace: "nowrap" }} showFilterMenu={false} filterHeaderClassName='small-text' />
						<Column field="amount" header={<span style={headerStyle}>{t("amount")}</span>} filter filterPlaceholder="Search by amount" sortable  style={{ minWidth: "8rem", whiteSpace: "nowrap" }} showFilterMenu={false} body={(rowData: any) => `${rowData.amount}€`} filterHeaderClassName='small-text'/>
						<Column field="createdAt" header={<span style={headerStyle}>{t("created_date")}</span>} filter filterPlaceholder="Search by created at" sortable  style={{ minWidth: "10rem",whiteSpace: "nowrap"  }} showFilterMenu={false} filterHeaderClassName='small-text'   body={(rowData: Transactions) => {
							const date = new Date(rowData.createdAt);
							return date.toLocaleString("en-US", { timeZone: "UTC", year: "numeric", month: "short", day: "numeric", hour: "2-digit", minute: "2-digit", second: "2-digit", }); }} />  
					</DataTable>
				</Card>
			</div>

		</div>
	);
}
        
