import React from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import AuthRoutes from "../auth/routes/auth.routes";
import HomeRoutes from "../main/routes/home.routes";
import GiftComponent from "../main/components/Cards/GiftComponent";
import SuccessfullComponent from "../main/components/Cards/SuccessfullComponent";
import CartScreenComponent from "../main/components/Cards/CartScreenComponent";
import DetailGiftComponent from "../main/components/Cards/DetailGiftComponent";
import ProceedToCheckoutComponent from "../main/components/Cards/ProceedToCheckoutComponent";
import RedeemCardComponent from "../main/components/Cards/RedeemCardComponent";
const AppRoutes = ({ appToastRef }: any) => {

	return (
		<BrowserRouter>
			<Routes>
				<Route
					path="/auth/*"
					element={<AuthRoutes appToastRef={appToastRef} />}
				></Route>
				<Route
					path="/home/*"
					element={<HomeRoutes appToastRef={appToastRef} />}
				></Route>
				<Route path="/giftCards" element={<GiftComponent appToastRef={appToastRef} />} />
				<Route path="/redeemCard" element={<RedeemCardComponent appToastRef={appToastRef} queryParams={location.search} />} />
				<Route path="/successfully" element={<SuccessfullComponent appToastRef={appToastRef} />} />
				<Route path="/cartScreen/:username" element={<CartScreenComponent appToastRef={appToastRef} />} />
				<Route path="/detailGiftCard/:username" element={<DetailGiftComponent appToastRef={appToastRef} />} />
				<Route path="/proceedToCheckout" element={<ProceedToCheckoutComponent appToastRef={appToastRef} queryParams={location.search} />} />
				<Route
					path="/*"
					element={<Navigate to="/auth/login" />}
				></Route>
			</Routes>
		</BrowserRouter>
	);
};

export default AppRoutes;
