import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { ProgressSpinner } from "primereact/progressspinner";
import { RootState } from "../../../../store/models";
import { getAllGifts } from "../../../../store/Reducers/userMain";
import { DataView, DataViewLayoutOptions } from "primereact/dataview";
import { useNavigate, NavigateFunction } from "react-router-dom";
import { GiftObject } from "../../models";
import { Image } from "primereact/image";
import "../../../Assets/Styling/home.css";
import { CCard,CCardImage,CCardBody,CCardTitle,CCardText,CButton} from "@coreui/react";
import { useTranslation } from "react-i18next";
import "../Dashboard/DashboardComponent.css";
import { Card, CardContent, Grid,Typography } from "@mui/material";
import { Input } from 'antd';
import { Pagination } from 'antd';


export default function GiftComponent({ appToastRef }: any) {
	const dispatch: ThunkDispatch<RootState, void, any> = useDispatch();
	const giftData: any = useSelector((state: RootState) => state.mainData.AllGiftsData || []);
	const [showSpinner, setShowSpinner] = useState(false);
	const [layout, setLayout] = useState<"grid" | "list">("grid");
	const navigate: NavigateFunction = useNavigate();
	const { t } = useTranslation();
    const [filteredData, setFilteredData] = useState<{id: number, name: string, cover:string}[]>([]);
    const [searchQuery, setSearchQuery] = useState("");
	const [currentPage, setCurrentPage] = useState(1);
	const itemsPerPage = 6;

	

	useEffect(() => {
		setTimeout(() => {
			setShowSpinner(true);
			getAllGift();
		}, 1000);
		setFilteredData(giftData);
	}, [dispatch, appToastRef]);

	useEffect(() => {
		setShowSpinner(true);
		getAllGift();
	}, [dispatch]);
	
	useEffect(() => {
		setFilteredData(giftData);
		setShowSpinner(false);
	}, [giftData]);


	const getAllGift = async () => {
		try {
			setShowSpinner(true);
			await dispatch(getAllGifts({ toastRef: appToastRef }));
			setShowSpinner(false);
		} catch (err) {
			setShowSpinner(false);
		}
	};
	const onChange = (page:number) => {
		setCurrentPage(page);
	};

	const displayedData = filteredData.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

	const listItem = (product: GiftObject) => {
		return (
			<div className="col-12">
				<div className="flex flex-column xl:flex-row xl:align-items-start lg:align-items-center md:align-items-center sx:align-items-center xs::align-items-center p-4 gap-4 cursor-pointer" onClick={() => navigate(`/detailGiftCard/${product.username}`)}>

					{product.cover.length == 0 ?
						<div
							style={{
								width: "210px",
								height: "82px",
								margin: "5px",
								alignSelf: "center"
                                 
							}}
						>
							<Image imageStyle={{ width: "100%", height: "100%", objectFit: "contain" }} src="/image 2.png" alt={product.name} />
						</div> : <div
							style={{
								width: "210px",
								height: "82px",
								margin: "5px 2px",
								alignSelf: "center"

							}}
						><Image imageStyle={{ width: "100%", height: "100%", objectFit: "contain" }} src={product.cover} alt={product.name} /></div>}

					<div className="flex flex-column sm:flex-row justify-content-between align-items-center xl:align-items-start flex-1 gap-4" style={{ alignSelf: "center" }}>
						<div className="flex flex-column align-items-center sm:align-items-start gap-3 ">
							<div className="text-2xl font-bold text-900"><span className="gift-text-center-heading">{product.name}</span></div>
						</div>
					</div>
				</div>
			</div>
		);
	};

	const gridItem = (product: GiftObject) => {
		return (
				<div>
				<Card id='dashboardCard' style={{ flexBasis: '40%', margin: '1rem', width: '222px', height: '235px', cursor: "pointer" }} onClick={() => navigate(`/detailGiftCard/${product.username}`)}>
					<CCard style={{ width: '100%', padding: '13px', borderRadius: '7px' }}>
					<div style={{ width: '100%', height: '140px', overflow: 'hidden', margin: 'auto' }}>
						{product.cover ? (
						<CCardImage
							orientation="top"
							src={product.cover}
							style={{ width: '100%', height: '100%', objectFit: 'contain', objectPosition: 'center' }}
						/>
						) : (
						<div
							style={{
							width: '100%',
							height: '100%',
							display: 'flex',
							fontSize: '14px',
							justifyContent: 'center',
							alignItems: 'center',
							border: '1px solid #ccc',
							}}
						>
							{t('no_photo_available')}
						</div>
						)}
					</div>
					<CCardBody>
						<CCardTitle style={{ textAlign: 'center', fontSize: '14px', color: 'black', overflow: 'hidden', textOverflow: 'ellipsis' }}>
						{product.name}
						</CCardTitle>
					</CCardBody>
					</CCard>
				</Card>
				</div>
		);
	};
	const itemTemplate = (product: any, layout: "list" | "grid") => {
		if (!product) {
			return;
		}
	
		if (layout === "list") return listItem(product);
		else if (layout === "grid") return gridItem(product);
	};	
	
    const handleSearch = (event:any) => {
        const query = event.target.value.toLowerCase();
        setSearchQuery(query);

        const filtered = giftData.filter((item: { name: string; }) => {
            return item.name.toLowerCase().includes(query); 
        });

        setFilteredData(filtered);
		setCurrentPage(1);
    };

	const onPageChange = (event:any) => { 
		setCurrentPage(event.page + 1);
	};


	const header = () => {
		return (
			<div className="flex gift-header">
				<DataViewLayoutOptions layout={layout} onChange={(e) => setLayout(e.value as "grid" | "list")} />
				<div className="pgift-inputgroup">
                	<Input className="giftCardSearchInput" allowClear placeholder="Search" value={searchQuery} onChange={handleSearch} style={{borderRadius:'50px'}}/>

				</div>
			</div>
		);
	};
	return (
		<>
			<div className="header">
            	<h1 style={{textAlign:'center',fontSize:'24px', fontFamily:'"Public Sans", sans-serif'}}>{t("gift_card_purschase_portal")}</h1>
        	</div>
			{showSpinner ? <div className="card flex justify-content-center align-items-center" style={{ height: "100vh" }}>
				<ProgressSpinner />
			</div> : <div className="card">
				<DataView key={currentPage}  value={displayedData} itemTemplate={itemTemplate} layout={layout} header={header()}  rows={itemsPerPage} paginatorPosition={"bottom"} onPage={onPageChange}/>
				<div style={{ display: 'flex', justifyContent: 'center' }}>
  					<Pagination defaultCurrent={1} total={giftData.length} defaultPageSize={itemsPerPage} onChange={onChange} />
				</div>	
			</div>}
		</>);
}
