import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store/models";
import { Button } from "primereact/button";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { placeOrderApi } from "../../../../store/Reducers/userMain";
import { NavigateFunction, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { GlobalService } from "../../../shared/services/global.service";
import { HttpService } from "../../../shared/services/http.service";
import "../../../Assets/Styling/gift.css";
import { Card } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { clearCart } from "../../../../store/Reducers/userMain";
import { faBuildingColumns, faLock, faEnvelope, faCity, faAddressCard, faUser, faPhone, faMapPin } from '@fortawesome/free-solid-svg-icons';

interface PaymentDetail {
	name: string;
}

export default function ProceedToCheckoutComponent({ appToastRef, queryParams: locationSearch }: any) {
	const cartData: any = useSelector((state: RootState) => state.mainData.cartItems || {});
	const paymentDetails1: any = localStorage.getItem("StoreGiftData"); //useSelector((state: RootState) => state.authData.LoginInterface?.paymentDetails);
	const queryParams = new URLSearchParams(locationSearch);
	const RETURNMAC = queryParams.get("RETURNMAC") || "";
	const hostedCheckoutId = queryParams.get("hostedCheckoutId") || "";
	const storedPhoneNumber = localStorage.getItem("phoneNumber") || "";
	const [phoneNumber, setPhoneNumber] = useState(storedPhoneNumber.replace(/"/g, ""));
	let mallUserName=localStorage.getItem("mallUserName")||"";
	mallUserName=mallUserName.replace(/"/g, "");
	const [phoneNumberError, setPhoneNumberError] = useState("");
	const storedUserName = localStorage.getItem("userName") || "";
	const [userName, setUserName] = useState(storedUserName.replace(/"/g, ""));
	const [userNameError, setuserNameError] = useState("");
	const storedEmail = localStorage.getItem("storedEmail") || "";
	const [userEmail, setUserEmail] = useState(storedEmail.replace(/"/g, ""));
	const [userEmailError, setuserEmailError] = useState("");
	const storedReceiverEmail = localStorage.getItem("storedReceiverEmail") || "";
	const [userReceiverEmail, setUserReceiverEmail] = useState(storedReceiverEmail.replace(/"/g, ""));
	const [userReceiverEmailError, setUserReceiverEmailError] = useState("");
	const storedCity = localStorage.getItem("storedCity") || "";
	const [userCity, setUserCity] = useState(storedCity.replace(/"/g, ""));
	const [userCityError, setuserCityError] = useState("");
	const storedAddress = localStorage.getItem("storedAddress") || "";
	const [userAddress, setUserAddress] = useState(storedAddress.replace(/"/g, ""));
	const [userAddressError, setuserAddressError] = useState("");
	const storedPostalCode = localStorage.getItem("storedPostalCode") || "";
	const [userPostalCode, setUserPostalCode] = useState(storedPostalCode.replace(/"/g, ""));
	const [userPostalCodeError, setuserPostalCodeError] = useState("");
	const dispatch: ThunkDispatch<RootState, void, any> = useDispatch();
	const navigate: NavigateFunction = useNavigate();
	const { t } = useTranslation();
	const validateAndPlaceOrderCalled = useRef(false);
	let language: any = localStorage.getItem("language");
	language = language.replace(/"/g, "");
	const [isAnyOptionSelected, setIsAnyOptionSelected] = useState(false);
	const [selectedPayment, setSelectedPayment] = useState(null);

	// Calculate the subtotal and totals
	let subtotal = 0;
	mallUserName && cartData[mallUserName] && cartData[mallUserName].forEach((item: any) => {
		subtotal += item.total;
	});


	const paymentImages: { [key: string]: string } = {
		"payone": "/payone.png",
		"paypal": "/paypal.png",
		"stripe": "/stripe.png",
		"bank": "/bank.png"
	};

	const paymentDetails2 = JSON.parse(paymentDetails1);


	const paymentDetails: any[] = [];

	paymentDetails2.forEach((mall: any) => {
		if (mall.paymentDetails && mall.username === mallUserName) {
			paymentDetails.push(mall.paymentDetails);
		}
	});

	useEffect(() => {
		// Only call validateAndPlaceOrder if it hasn't been called yet
		if ( phoneNumber.length>0){
			if (!validateAndPlaceOrderCalled.current) {
				validateAndPlaceOrderCalled.current = true;
				setTimeout(() => {
					validateAndPlaceOrder();
				}, 1500);
			}
		}
	}, []);

	const validateAndPlaceOrder = async () => {
		try {
		  if (RETURNMAC.length > 0 && hostedCheckoutId.length > 0) {
			
			  const mallUserNameData = mallUserName ? cartData[mallUserName] : "";
	  
			  if (mallUserNameData.length>0) {
					const giftcards = mallUserNameData.map((item:any) => ({
						amount: item.price.toString(),
						info: { isGift: false },
						cover: item.wallPaperId
					}));
					const data={
						mall_username:mallUserName,
						mall_hash:"",
						postal_address:{
							street: userAddress,
							postcode: userPostalCode,
							city: userCity
						},
						full_name:userName,
						email_address:userEmail,
						receiver_email_address: userReceiverEmail,
						phonenumber:phoneNumber,
						giftcards:giftcards
					};
					const response = await dispatch(placeOrderApi({ formattedOrders: data, mallUserName: mallUserName || "", successMsg: t("order_placed_success"), errorMsg: t("order_placed_error"), toastRef: appToastRef}));
					if (response.type === "users/formattedOrders/fulfilled") {
						navigate("/successfully");
						dispatch(clearCart());
						GlobalService.setStorage("StoreCartItemsData", []);
					} else {
						if(response.type === "users/formattedOrders/rejected") {
							handleOrderFailure("Payment failed");
						}
					}
			  	}
			}
		}
		catch (err: any) {
			console.error("Error in validateAndPlaceOrder:", err);
			handleOrderFailure(err.message || "An unexpected error occurred");
		}
	};

	const handleOrderFailure = (errorMessage: any) => {
		navigate("/proceedToCheckout");
		const errorMessageStr: string = errorMessage.message ? errorMessage.message : JSON.stringify(errorMessage);
		appToastRef.current.show({
			severity: "error",
			summary: "Error",
			detail: errorMessageStr.toString(),
			life: 3000,
		});
	};

	const handlePlaceCard = async () => {
		
		setPhoneNumberError("");
		let isFormValid = true;


		if ( phoneNumber.length < 8) {
			setPhoneNumberError(t("proceed_phone_error"));
			isFormValid = false;
		}
		if ( userName.length < 5) {
			setuserNameError(t("proceed_fullname_error"));
			isFormValid = false;
		}
		if ( userAddress.length ==0) {
			setuserAddressError(t("address_required_error"));
			isFormValid = false;
		}
		if ( userPostalCode.length ==0) {
			setuserPostalCodeError(t("postcode_required_error"));
			isFormValid = false;
		}
		if ( userCity.length < 3) {
			setuserCityError(t("proceed_city_error"));
			isFormValid = false;
		}
		if ( userEmail.length == 0) {
			setuserEmailError(t("proceed_email_error"));
			isFormValid = false;
		}

		if (userEmail.length > 0 && !/^\S+@\S+\.\S+$/.test(userEmail)){
			setuserEmailError(t("invalid_email_error"));
			isFormValid = false;
		}

		if ( userReceiverEmail.length == 0) {
			setuserEmailError(t("proceed_email_error"));
			isFormValid = false;
		}

		if (userReceiverEmail.length > 0 && !/^\S+@\S+\.\S+$/.test(userReceiverEmail)){
			setUserReceiverEmailError(t("invalid_email_error"));
			isFormValid = false;
		}

		if (isFormValid) {
			try {
				GlobalService.setStorage("storedCity", userCity);

				GlobalService.setStorage("userName", userName);
				GlobalService.setStorage("storedEmail", userEmail);
				GlobalService.setStorage("storedReceiverEmail", userReceiverEmail);
				GlobalService.setStorage("storedPostalCode", userPostalCode);
				GlobalService.setStorage("storedAddress", userAddress);
				GlobalService.setStorage("phoneNumber", phoneNumber);
				const sendingLanguage = language + "_GB";
				const response = await HttpService.postRequest("stores/initiatePayment", {
					subtotal,
					username: mallUserName,
					method: "payone",
					phonenumber:phoneNumber,
					merchantCustomerId: userName,
					language:sendingLanguage
				});
				
				const { data } = response;
				if(data && data.message) {
					const { message,statusCode } = data;
					const { redirectUrl } = message;
					if (statusCode == 200) {
						window.location.href = redirectUrl; // Redirect to the provided URL
					} else {
						console.error("Invalid or missing redirectUrl in the response:", data);
					}
				} else {
					console.error("Unexpected response format:", data);
				}
			}
			catch (error) {
				console.error("Error while processing payment:", error);
			}
		}
	};
	
	const handleOptionChange = (paymentName: any) => {
		setSelectedPayment(paymentName);
		setIsAnyOptionSelected(true);
	};

	const PaymentButtons = ({ paymentDetails }: { paymentDetails: PaymentDetail[] }) => {


		return (
		  <div>
			{Object.keys(paymentDetails[0]).map((paymentName) => (
			  <Card
				key={paymentName}
				style={{
				  display: 'flex',
				  alignItems: 'center',
				  marginBottom: '10px',
				  height: '60px'
				}}
			  >
				<input type="radio" name="paymentOption" value={paymentName} checked={selectedPayment === paymentName} style={{marginLeft: '2%'}} onChange={() => handleOptionChange(paymentName)} disabled={!(paymentName.toLowerCase() === "payone")}/>
				<img src={paymentImages[paymentName.toLowerCase()] ? paymentImages[paymentName.toLowerCase()] : "" }
					style={{ width: '10%', marginLeft: '10px', marginRight: '10px' }}
				  alt={`${paymentName} Logo`}
				/>
				<strong>{t("pay_with")} {paymentName}</strong>
				{paymentName.toLowerCase() !== "payone" && <span style={{marginLeft: '10px', animation: 'blink 1s step-end infinite', color: 'red'}}>{t("")}{t("coming_soon")}</span>}
			  </Card>
			))}
			<FontAwesomeIcon icon={faLock} style={{color: '#667483', marginLeft: '3px'}} /><span style={{color: '#667483', fontSize: '11px', marginLeft: '8px'}}>{t("data_proctection")}</span>
			<br />
			<Button label={t("place_order")} severity="info" onClick={handlePlaceCard} disabled={!isAnyOptionSelected} raised style={{ width: "20%", backgroundColor: '#0354CC', marginTop: '1%' }} />

		  </div>
		);
	};
	  


	return (
		<div className="proceed-to-checkout">
			<h1 style={{ textAlign: "center" }}>{t("checkout")}</h1>
			<div className="proceed-to-checkout-parent">

				<div className="card" style={{ flexBasis: "40%", border: "1px solid #CDCDCD", padding: "10px", borderRadius: "8px", height: '50%' }}>
					<h1 style={{ textAlign: "center",fontSize: "25px" }}>{t("your_details")}</h1>
					<div>
						<h2 style={{ fontSize: "20px", fontWeight: "bold" }}>
						<FontAwesomeIcon icon={faUser} />	{t("full_name")}</h2>
					
						<input
							type="text"
							value={userName}
							onChange={(e) => {
								setUserName(e.target.value);
								setuserNameError(""); // Reset the error message on change
							}}
							placeholder={t("fullname_placeholder")}
							style={{
								width: "100%",
								padding: "10px",
								fontSize: "18px",
								borderColor: userNameError ? "red" : "",
								marginBottom: "2%",
							}}
						/>
						{userNameError && <span style={{ color: "red" }}>{userNameError}</span>} 
						<div className="proceed-to-checkout-fields">
						
							<div className="flexBasis45">
								<h2 style={{ fontSize: "20px", fontWeight: "bold" }}>
								<FontAwesomeIcon icon={faEnvelope} /> {t("email")}</h2>
					
								<input
									type="text"
									value={userEmail}
									onChange={(e) => {
										setUserEmail(e.target.value);
										setuserEmailError(""); // Reset the error message on change
									}}
									placeholder={t("email_placeholder")}
									style={{
										width: "100%",
										padding: "10px",
										fontSize: "18px",
										borderColor: userEmailError ? "red" : "",
										marginBottom: "2%",
									}}
								/>
								{userEmailError && <span style={{ color: "red" }}>{userEmailError}</span>} 
							</div>
							<div className="flexBasis45">
								<h2 style={{ fontSize: "20px", fontWeight: "bold" }}>
								<FontAwesomeIcon icon={faCity} />	{t("city")}</h2>
					
								<input
									type="text"
									value={userCity}
									onChange={(e) => {
										setUserCity(e.target.value);
										setuserCityError(""); // Reset the error message on change
									}}
									placeholder={t("city_placeholder")}
									style={{
										width: "100%",
										padding: "10px",
										fontSize: "18px",
										borderColor: userCityError ? "red" : "",
										marginBottom: "2%",
									}}
								/>
								{userCityError && <span style={{ color: "red" }}>{userCityError}</span>} 

							</div>
						</div>
						<div className="proceed-to-checkout-fields">
						
							<div className="flexBasis45">
								<h2 style={{ fontSize: "20px", fontWeight: "bold" }}>
								<FontAwesomeIcon icon={faAddressCard} />	{t("address")}</h2>
					
								<input
									type="text"
									value={userAddress}
									onChange={(e) => {
										setUserAddress(e.target.value);
										setuserAddressError(""); // Reset the error message on change
									}}
									placeholder={t("address_required")}
									style={{
										width: "100%",
										padding: "10px",
										fontSize: "18px",
										borderColor: userAddressError ? "red" : "",
										marginBottom: "2%",
									}}
								/>
								{userAddressError && <span style={{ color: "red" }}>{userAddressError}</span>} 
							</div>
							<div className="flexBasis45">
								<h2 style={{ fontSize: "20px", fontWeight: "bold" }}>
								<FontAwesomeIcon icon={faMapPin} />	{t("postcode")}</h2>
					
								<input
									type="text"
									value={userPostalCode}
									onChange={(e) => {
										setUserPostalCode(e.target.value);
										setuserPostalCodeError(""); // Reset the error message on change
									}}
									placeholder={t("postcode_required")}
									style={{
										width: "100%",
										padding: "10px",
										fontSize: "18px",
										borderColor: userPostalCodeError ? "red" : "",
										marginBottom: "2%",
									}}
								/>
								{userPostalCodeError && <span style={{ color: "red" }}>{userPostalCodeError}</span>} 

							</div>
						</div>




						<hr style={{borderTop: "dotted 1px"}} />
						<div className="proceed-to-checkout-fields">
						
							<div className="flexBasis45">
								<h2 style={{ fontSize: "20px", fontWeight: "bold" }}>
								<FontAwesomeIcon icon={faEnvelope} /> {t("receiver_email_placeholder")}</h2>
					
								<input
									type="text"
									value={userReceiverEmail}
									onChange={(e) => {
										setUserReceiverEmail(e.target.value);
										setUserReceiverEmailError(""); // Reset the error message on change
									}}
									placeholder={t("email_placeholder")}
									style={{
										width: "100%",
										padding: "10px",
										fontSize: "18px",
										borderColor: userReceiverEmailError ? "red" : "",
										marginBottom: "2%",
									}}
								/>
								{userReceiverEmailError && <span style={{ color: "red" }}>{userReceiverEmailError}</span>} 
							</div>

							<div className="flexBasis45">

								<h2 style={{ fontSize: "20px", fontWeight: "bold" }}>
								<FontAwesomeIcon icon={faPhone} />	{t("phone")}</h2>
							
								<input
									className={`inputWithCustomPlaceholder ${phoneNumberError ? 'inputError' : ''}`}							
									type="text"
									value={phoneNumber}
									onChange={(e) => {
										setPhoneNumber(e.target.value);
										setPhoneNumberError(""); // Reset the error message on change
									}}
									placeholder={t("phone_placeholder")}
									style={{
										width: "100%",
										padding: "10px",
										fontSize: "18px",
										borderColor: phoneNumberError ? "red !important" : "grey !important",
										marginBottom: "2%",
									}}
								/>
								
							</div>
							
						</div>			
						{phoneNumberError ? <span style={{ color: "red" }}>{phoneNumberError}</span> : <><span style={{ color: "red", fontSize: "13px"}}>{t("phone_format")} : 00491234512345</span>
						<span style={{ color: "red", display: "block", marginTop: "5px", fontSize: "13px" }}>{t("phone_text")}</span></>}
							
					</div>
				</div>

				<div style={{ flexBasis: "60%", paddingLeft: "3%" }}>
					<h1 style={{ textAlign: "center", fontSize: "25px" }}>{t("your_order")}</h1>
					{mallUserName && cartData[mallUserName] && cartData[mallUserName].length > 0 ? (
						<>
							<div style={{ width: "100%", border: "1px solid rgba(33, 150, 243, 0.92)", borderRadius: "5px" }} className="cursor-pointer">
								<div style={{ display: "flex", justifyContent: "space-between", borderBottom: "1px solid gray", width: "100%", padding: "2% 3%" }}>
									<span style={{ fontSize: "20px", fontWeight: "bold" }}>{t("product")}</span>
									<span style={{ fontSize: "20px", fontWeight: "bold" }}>{t("subtotal")}</span>
								</div>
								{mallUserName && cartData[mallUserName] && cartData[mallUserName].map((item: any, index: any) => {
									return (
										<div key={index + "m"} style={{ display: "flex", justifyContent: "space-between", borderBottom: "1px solid gray", width: "100%", padding: "2% 3%" }}>
											<span style={{ fontSize: "20px", flexBasis: "70%" }}>
												{item.username.length > 12 ? (
													<span>{item.username.slice(0, 15)}...</span>
												) : (
													<span>{item.username}</span>
												)} <span style={{ fontWeight: "bold", paddingLeft: "2%" }}>X {item.qty}</span>
											</span>
											<span style={{ fontSize: "20px", fontWeight: "bold", flexBasis: "30%", display: "flex", justifyContent: "flex-end" }}>
												{item.total}
											</span>
										</div>
									);
								})}
								<div style={{ display: "flex", justifyContent: "space-between", borderBottom: "1px solid gray", width: "100%", padding: "2% 3%" }}>
									<span style={{ fontSize: "20px", fontWeight: "bold" }}>{t("subtotal")}</span>
									<span style={{ fontSize: "20px", fontWeight: "bold" }}>{subtotal}</span>
								</div>

								<div style={{ display: "flex", justifyContent: "space-between", width: "100%", padding: "2% 3%" }}>
									<span style={{ fontSize: "20px", fontWeight: "bold" }}>{t("total")}</span>
									<span style={{ fontSize: "20px", fontWeight: "bold" }}>{subtotal}</span>
								</div>
							</div>
							<h3>{t("select_payment_method")}</h3>
							<PaymentButtons paymentDetails={paymentDetails} />
						</>
					) : (
						<h1 style={{ textAlign: "center", color: "rgba(33, 150, 243, 0.92)" }}>{t("no_order_found")}</h1>
					)}
				</div>

				
			</div>
		</div>
	);
}
