import axios from "axios";
import { GlobalValues } from "./global.service";

const getRequest = (endPoint: string) => {
	return new Promise((resolve, reject) => {
		try {
			axios.get(GlobalValues.baseUrl + endPoint)
				.then((data) => {
					resolve(data);
				})
				.catch((error) => {
					reject(error);
				});
		} catch (error) {
			reject(error);
		}
	});
};

const postRequest = (endPoint: string, data: any) => {
	return new Promise((resolve, reject) => {
		try {
			axios.post(GlobalValues.baseUrl + endPoint, data)
				.then((data) => {
					resolve(data);
				})
				.catch((error) => {
					reject(error);
				});
		} catch (error) {
			reject(error);
		}
	});
};

const putRequest = (endPoint: string, data: any) => {
	return new Promise((resolve, reject) => {
		try {
			axios.put(GlobalValues.baseUrl + endPoint, data)
				.then((data) => {
					resolve(data);
				})
				.catch((error) => {
					reject(error);
				});
		} catch (error) {
			reject(error);
		}
	});
};

interface HttpServiceFunctions {
  getRequest: (endpoint: string) => Promise<any>;
  postRequest: (endpoint: string, data: any) => Promise<any>;
  putRequest: (endpoint: string, data: any) => Promise<any>;
}

const HttpService: HttpServiceFunctions = {
	getRequest,
	postRequest,
	putRequest,
};

export { HttpService };
