import React from "react";
import { Card } from "primereact/card";

const NotFoundComponent = () => {
	const centerStyle = {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		minHeight: "100vh",
	};

	return (
		<div style={centerStyle}>
			<Card
				title="404 - Page Not Found"
				subTitle="The requested page could not be found."
				style={{ width: "400px" }}
			/>
		</div>
	);
};

export default NotFoundComponent;
