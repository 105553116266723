// SidebarComponent.js
import React, { useState } from "react";
import { classNames } from "primereact/utils";
import { Button } from "primereact/button"; // Import Button from primereact
import { Dialog } from "primereact/dialog"; // Import Dialog from primereact
import "../../../Assets/Styling/home.css";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { RootState } from "../../../../store/models";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { logout } from "../../../../store/Reducers/userAuth";
import { NavigateFunction, useNavigate } from "react-router-dom";

export default function SidebarComponent({ selectedItem, handleItemClick, items,appToastRef }:any) {
	const [logoutDialogVisible, setLogoutDialogVisible] = useState(false);
	const dispatch: ThunkDispatch<RootState, void, any> = useDispatch();
	const navigate: NavigateFunction = useNavigate();
	const { t } = useTranslation();

	const showLogoutDialog = () => {
		setLogoutDialogVisible(true);
	};

	const hideLogoutDialog = () => {
		setLogoutDialogVisible(false);
	};

	const confirmLogout = async() => {	
		localStorage.removeItem("selectedFilter");
		localStorage.removeItem("selectedWindow");	
		  dispatch(logout());
		 navigate("/auth/login");
		 appToastRef.current.show({
			severity: "success",
			summary: t("success"),
			detail:t("logout_success"),
			life: 3000,
		});
		hideLogoutDialog();
	};
	

	return (
		<div className="sidebar">
			<div className="logo-login-parent align-self-logo-resetPassword">
				<img src="/image 2.png" alt="" className="width-complete-100" />
			</div>
			{items.map((item:any, index:any) => (
				<div
					key={index}
					className={classNames("sidebar-item", {
						selected: selectedItem === item.name,
					})}
					onClick={() => handleItemClick(item.name)}
				>
					<i className={item.icon} style={{ paddingRight: "4%" }}></i> {item.name}
				</div>
			))}
			<div className="sidebar-item" onClick={showLogoutDialog}>
				<i className="pi pi-sign-out" style={{ paddingRight: "4%" }}></i> {t("logout")}
			</div>

			<Dialog
				visible={logoutDialogVisible}
				className="logout-modal-width"
				header={t("confirm_logout")}
				onHide={hideLogoutDialog}
			>
				<div>
					<p>{t("logout_msg")}</p>
				</div>
				<div style={{width:"100%",display:"flex",justifyContent:"flex-end"}}>
					<Button label={t("yes")}  onClick={confirmLogout} className="p-button-success" style={{background:"#6666FF"}} />
					<Button label={t("no")} style={{marginLeft:"10px"}}  onClick={hideLogoutDialog} outlined className="p-button-secondary" />
				</div>
			
			</Dialog>
		</div>
	);
}
