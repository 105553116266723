import React, { useEffect, useRef, useState } from "react";
import Highcharts from "highcharts";
import "../../DashboardComponent.css";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { useNavigate, NavigateFunction } from "react-router-dom";
import { RootState } from "../../../../../../store/models";
import { storeTransactionData } from "../../../../../../store/Reducers/userAuth";
import { DashboardDataForm } from "../../../../../main/models";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Typography } from "@mui/material";
import { cilChartLine,cilHistory,cilGift } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import i18n from "i18next";
import { GlobalService } from "../../../../../../modules/shared/services/global.service";
import CryptoJS from "crypto-js";
import { useTheme } from "@mui/material/styles";

        
type Data = {
  amount: number;
  createdAt: string;
}
type Result = {
  month: string;
  amount: number;
};
type DayResult = {
  day: string;
  amount: number;
};

interface purchaseTransactions {
  id: number;
  transactionhash: string;
  actionName: string;
  type: string;
  amount: number;
  createdAt: string;
  accounts: string;
}
const SELECTED_FILTER_KEY = "selectedFilter";
const SECRET_KEY = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789"; 
export default function UniqueVisitorChartComponent({ onSelectedWindow,appToastRef }: any) {

	const dispatch: ThunkDispatch<RootState, void, any> = useDispatch();
	const navigate: NavigateFunction = useNavigate(); // Explicitly set the type here
	const [checked, setChecked] = useState(false);
	const [showSpinner, setShowSpinner] = useState(false);
	const { t } = useTranslation();


	const purchaseChartData:any = useSelector((state: RootState) => state.authData.DashboardData?.purchase_chart_data || []);
	const redeemChartData:any = useSelector((state: RootState) => state.authData.DashboardData?.redeem_chart_data || []);
	const tablePurchaseData:any = useSelector((state: RootState) => state.authData.TransactionTableData?.table_data || []);

	//console.log("tablePurchaseData",tablePurchaseData);
	const loginData = useSelector((state: RootState) => state.authData.LoginInterface?.username);
	const accountType = useSelector((state: RootState) => state.authData.LoginInterface?.accountType);
	const dashboardDataTransaction = useSelector((state: RootState) => state.authData.DashboardData || []) as any;
	const activeGiftCards = useSelector((state: RootState) => state.authData.DashboardData?.n_active_giftcards || []);

	let combinedTransactions:any = [];

	if (dashboardDataTransaction && dashboardDataTransaction.purchase_transactions && dashboardDataTransaction.redeem_transactions) {
		const dashboardTransaction = {
			purchase: dashboardDataTransaction.purchase_transactions,
			redeem: dashboardDataTransaction.redeem_transactions
		};
		combinedTransactions = [
			...dashboardTransaction.purchase.map((transaction: any) => ({ ...transaction, type: 'purchase' })),
			...dashboardTransaction.redeem.map((transaction: any) => ({ ...transaction, type: 'redeem' }))
		];
	}
	
	const sortedTransactions = combinedTransactions.sort((a:any, b:any) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());

	const [transactions, setTransactions] = useState<purchaseTransactions[]>([]);
	const [selectedTableFilter, setSelectedTableFilter] = useState(t("purchase"));
	const [loading, setLoading] = useState(true);
	const [activeCopyButton, setActiveCopyButton] = useState(null);
	const [loadingChartData, setLoadingChartData] = useState(false); 
	const chartRef = useRef<Highcharts.Chart | null>(null); 
	const [showLoadingMessage, setShowLoadingMessage] = useState(false);

	const theme = useTheme();
	const { primary, secondary } = theme.palette.text;
	const line = theme.palette.divider;

	//Group the Data according to the day
	const assignDataToEachDay = (data: Data[]) => {
		const dayTotals: Record<string, number> = {
			"Sunday": 0,
			"Monday": 0,
			"Tuesday": 0,
			"Wednesday": 0,
			"Thursday": 0,
			"Friday": 0,
			"Saturday": 0,
		};

		const today = new Date().getDay(); // Get current day index (0 for Sunday, 1 for Monday, ..., 6 for Saturday)
		const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

		// Corrected line below
		const weekCategory = [...daysOfWeek.slice(today+1), ...daysOfWeek.slice(0, today+1)];
		data.forEach(item => {
			const dayFromDate = daysOfWeek[new Date(item.createdAt).getDay()];
			dayTotals[dayFromDate] += item.amount;
		});
		const result = weekCategory.map(day => ({ date: day, amount: dayTotals[day] }));
		return result;
	};


	//Group data according to the year
	const groupDataByYear = (data: Data[]): Result[] => {
		const months = [t("jan"), t("feb"), t("mar"), t("apr"), t("may"),t("jun"),t("jul"),t("aug"),t("sep"),t("oct"),t("nov"),t("dec")];
		const currentMonth = new Date().getMonth();
		const yearCategory = [...months.slice(currentMonth + 1), ...months.slice(0, currentMonth + 1)];
  
		const sums: Record<string, number> = {};
		yearCategory.forEach(month => {
			sums[month] = 0;
		});
  
		data.forEach(item => {
			const date = new Date(item.createdAt);
			const month = months[date.getUTCMonth()];
			sums[month] += item.amount;
		});
  
		return yearCategory.map(month => ({ month, amount: sums[month] }));
	};

	const currentYear: number = new Date().getFullYear();
	const groupDataByMonth = (data: Data[]) => {
		const dayTotals: Record<string, number> = {};
		const monthDates = monthCategory.map((day) => `${currentYear}-${day}`);
		monthDates.forEach((date) => {
			dayTotals[date] = 0;
		});
		data.forEach((item) => {
			const date = item.createdAt.split("T")[0]; // Extract the date part
			dayTotals[date] += item.amount;
		});
		const result = Object.entries(dayTotals).map(([date, amount]) => ({ date, amount }));
		return result;
	};



	//For the Week category filter
	const today = new Date().getDay();
	const daysOfWeek = ["Sun","Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
	//const daysOfWeek = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

	const weekCategory = [...daysOfWeek.slice(today+1), ...daysOfWeek.slice(0, today+1)];

	// Data for the line chart
	const [data,setData] =  useState(accountType !== "retailer" ? assignDataToEachDay(purchaseChartData).map(item => item.amount) : assignDataToEachDay(redeemChartData).map(item => item.amount));
	const [redeemData,setRedeemData] = useState(assignDataToEachDay(redeemChartData).map(item => item.amount));
	const [category,setCategory] = useState(weekCategory);
  
	const months = [t("jan"), t("feb"), t("mar"), t("apr"), t("may"),t("jun"),t("jul"),t("aug"),t("sep"),t("oct"),t("nov"),t("dec")];
	const currentMonth = new Date().getMonth();
	const yearCategory = [...months.slice(currentMonth + 1), ...months.slice(0, currentMonth + 1)];

	const currentDate = new Date();
	const days = 30;
  
	//For the Month category filter
	const monthCategory = Array.from({ length: days }, (_, index) => {
		const date = new Date();
		date.setDate(currentDate.getDate() - index);
		const formattedDay = date.getDate().toString().padStart(2, "0");
		const formattedMonth = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based  
		return `${formattedMonth}-${formattedDay}`;
	}).reverse(); 

	const [selectedLanguage, setSelectedLanguage] = useState();
	const getDefaultLanguge = async () => {
		let language: any = await GlobalService.getStorage("language");

		if (language) {
			setSelectedLanguage(language);
		}
		else {
			language = navigator.language.split("-")[0];
			setSelectedLanguage(language);
			GlobalService.setStorage("language", language);
		}
		i18n.changeLanguage(language);
    
	};

  	function encrypt(message = "") {
    	return CryptoJS.AES.encrypt(message, SECRET_KEY).toString();
  	}

  	function decrypt(ciphertext = ""){
    	const bytes = CryptoJS.AES.decrypt(ciphertext, SECRET_KEY);
    	return bytes.toString(CryptoJS.enc.Utf8);
  	}

	useEffect(() => {
		getDefaultLanguge();
	}, []);


	const storedFilter = localStorage.getItem(SELECTED_FILTER_KEY);
	const initialFilter = storedFilter ? decrypt(storedFilter) : "week";
	const [selectedFilter, setSelectedFilter] = useState(initialFilter);

	useEffect(() => {
		const storedFilter = localStorage.getItem(SELECTED_FILTER_KEY);
		if (storedFilter !== null) {
			setSelectedFilter(decrypt(storedFilter));
		}
	}, []);

	// Save the selected filter to localStorage whenever it changes
	useEffect(() => {
		localStorage.setItem(SELECTED_FILTER_KEY, encrypt(selectedFilter));
	}, [selectedFilter]);

	useEffect(() => {
		if (accountType !== "retailer" && purchaseChartData && redeemChartData) {
			let processedPurchaseData: any;
			let processedReedemData: any;
			setLoadingChartData(true);
			if (selectedFilter === "month") {
				processedPurchaseData = groupDataByMonth(purchaseChartData).map(item => item.amount);
				processedReedemData = groupDataByMonth(redeemChartData).map(item => item.amount);
				setCategory(monthCategory);
			} else if (selectedFilter === "week") {
				processedPurchaseData = assignDataToEachDay(purchaseChartData).map(item => item.amount);
				processedReedemData = assignDataToEachDay(redeemChartData).map(item => item.amount);
				setCategory(weekCategory);
			} else if (selectedFilter === "year") {
				processedPurchaseData = groupDataByYear(purchaseChartData).map(item => item.amount);
				processedReedemData = groupDataByYear(redeemChartData).map(item => item.amount);
				setCategory(yearCategory);
			}
			setData(processedPurchaseData);
			setRedeemData(processedReedemData);
			setLoadingChartData(false);
		}
    
		else {
			if(redeemChartData) {
				let processedReedemData: any;
				setLoadingChartData(true);
				if (selectedFilter === "month") {
					processedReedemData = groupDataByMonth(redeemChartData).map(item => item.amount);
					setCategory(monthCategory);
				} else if (selectedFilter === "week") {
					processedReedemData = assignDataToEachDay(redeemChartData).map(item => item.amount);
					setCategory(weekCategory);
				} else if (selectedFilter === "year") {
					processedReedemData = groupDataByYear(redeemChartData).map(item => item.amount);
					setCategory(yearCategory);
				}
				setRedeemData(processedReedemData);
				setLoadingChartData(false);
			}
		}
	}, [redeemChartData, selectedFilter]);

	//handle week or month selection
	const handleButtonClick = async (buttonType: any) => {
		try {
			setData([]);
			setRedeemData([]);
			setCategory([]);
			setLoadingChartData(true);
			setSelectedFilter(buttonType);
			onSelectedWindow(buttonType);
			const formData: DashboardDataForm = {
				username: loginData ? loginData : "",//'redimidemomall',
				window: "",
				createdAtStart: "",
				createdAtEnd: "",
				limit: 25,
				start: 0,
			};
			if(buttonType === "month") {
				const oneMonthFromSystemDate = new Date();
				oneMonthFromSystemDate.setDate(oneMonthFromSystemDate.getDate()-30);
				formData.window = "day";
				formData.createdAtStart = oneMonthFromSystemDate.toLocaleString();
			} else if(buttonType === "week") {
				const oneWeekFromSystemDate = new Date();
				oneWeekFromSystemDate.setDate(oneWeekFromSystemDate.getDate()-7);
				formData.window = "day";
				formData.createdAtStart = oneWeekFromSystemDate.toLocaleString();
			} else if(buttonType === "year") {
				const oneWeekFromSystemDate = new Date();
				oneWeekFromSystemDate.setDate(oneWeekFromSystemDate.getDate()-365);
				formData.window = "month";
				formData.createdAtStart = oneWeekFromSystemDate.toLocaleString();
			}
			await handleSubmit(formData);
		}
		catch(error) {
			console.error(error);
		}
	};

	const handleSubmit = async (values: DashboardDataForm) => {
		try {
			setShowSpinner(true);
			setShowSpinner(true);
			setLoadingChartData(true);
			dispatch(storeTransactionData({ storeData: { ...values },successMsg:t(""),errorMsg: t("user_login_error"), toastRef: appToastRef, navigate }));
			setLoadingChartData(false);
			setShowSpinner(false);
		} catch (error: any) {
			setShowSpinner(false);
			console.error("Login error:", error);
		}
	};

	const formatMonth = (monthDate: string) => {
		const [month, day] = monthDate.split("-");
		const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
		const monthName = months[parseInt(month, 10) - 1];  
		return `${monthName} ${parseInt(day, 10)}`;
	}; 

	useEffect(() => {
		// Highcharts configuration object
		// const options1: Highcharts.Options = {
		// 	chart: {
		// 		type: "areaspline",
		// 		backgroundColor: "white",
		// 		borderWidth: 1,
		// 		borderColor: "white",
		// 		zooming:{
		// 			type: "xy",
		// 		},
		// 		height: "550px",
		// 	},
		// 	title: {
		// 		text: " ",
		// 	},
      
		// 	xAxis: {
		// 		categories: category,
		// 		labels: {
		// 			style: {
		// 				color: secondary//"grey"
		// 			},
		// 			formatter: function () {
		// 				const stringValue = this.value.toString();
		// 				if (stringValue.length === 3) {
		// 					return stringValue; 
		// 				} else {
		// 					const formattedDay = stringValue.split("-")[1];
		// 					return formattedDay;
		// 				}
		// 			},
		// 		},
		// 		lineColor: "grey",
		// 		lineWidth: 0,
        
		// 	},
		// 	yAxis:[
		// 		...(accountType !== "retailer" ? [{
		// 			title: {
		// 				text: t("purchase_amount"),
		// 			},
		// 			tickColor: "grey",
		// 			lineColor: "grey",
		// 			labels: {
		// 				style: {
		// 					color: secondary//"grey",
		// 				}
		// 			}
		// 		}]: []),
		// 		{
		// 			title: {
		// 				text: t("redeem_amount"),
		// 			},
		// 			tickColor: "grey",
		// 			lineColor: "grey",
		// 			labels: {
		// 				style: {
		// 					color: secondary//"grey",
		// 				}
		// 			},
		// 			opposite: accountType !== "retailer",
		// 		}
		// 	],
		// 	tooltip: {
		// 		useHTML: true,
		// 		valueSuffix: " €",
		// 		formatter: function() {
		// 			const formattedMonth = selectedFilter === "month" ? formatMonth(this.x as string) : this.x;
		// 			return `
        //     <div style="color: #333;">
        //       <strong>${selectedFilter}: </strong>${formattedMonth}
        //       <br/>
        //       <span style="font-size: 14px; color: #666;"><strong>Amount: €</strong> ${this.y}</span>
        //     </div>
        //   `;
		// 		}
		// 	},
    
		// 	plotOptions: {
		// 		areaspline: {
		// 			marker: {
		// 				enabled: false
		// 			}
		// 		},
		// 		column: {
		// 			borderRadius: 4,
		// 			groupPadding: 0.2, //selectedFilter === 'month' ? 0.2 : 0,
		// 			pointPadding: 0.2, //selectedFilter === 'month' ? 0.2 : 0,
		// 			pointWidth: selectedFilter === "month" ? 10 : 30,
		// 		},
		// 	},
		// 	series: accountType !== "retailer" ?  [
		// 		{
		// 			type: "areaspline" as const,
		// 			name: t("purchase_amount"),
		// 			data: data,
		// 			showInLegend: true,
		// 			color: "#1677FF",
		// 			yAxis: 0,
		// 		} ,
		// 		{
		// 			type: "areaspline",
		// 			name: t("redeem_amount"),
		// 			data: redeemData,
		// 			showInLegend: true,
		// 			color: "#003EB3",
		// 			yAxis: 1,//accountType === "retailer" ? 0 : 1, 
		// 		}
		// 	] : [
		// 		{
		// 			type: "areaspline",
		// 			name: t("redeem_amount"),
		// 			data: redeemData,//(redeemData && redeemData.length > 10) ? redeemData : [],
		// 			showInLegend: false,
		// 			color: "#c90825",
		// 			yAxis: 0,//accountType === "retailer" ? 0 : 1, 
		// 		}
		// 	],
		// 	credits: {
		// 		enabled: false,
		// 	},
		// 	legend: {
		// 		align: "center",
		// 		verticalAlign: "bottom",
		// 		itemStyle: {
		// 			color: "grey",
		// 		},
		// 		symbolRadius: 0,
		// 		symbolWidth: 16,
		// 		symbolHeight: 16,
		// 	},
		// };
			const options1: Highcharts.Options = {
				chart: {
				type: "areaspline",
				height: 450, // Adjusted height to match ApexCharts
				backgroundColor: "white",
				borderWidth: 1,
				borderColor: "white",
				zooming: {
					type: "xy"
				},
				},
				title: {
				text: " ",
				},
				xAxis: {
				categories: category,
				labels: {
					style: {
					color: secondary
					},
					formatter: function () {
					const stringValue = this.value.toString();
					if (stringValue.length === 3) {
						return stringValue; 
					} else {
						const formattedDay = stringValue.split("-")[1];
						return formattedDay;
					}
					},
				},
				lineColor: "grey",
				lineWidth: 0,
				},
				yAxis:[
				...(accountType !== "retailer" ? [{
					title: {
					text: t("purchase_amount"),
					},
					tickColor: "grey",
					lineColor: "grey",
					labels: {
					style: {
						color: secondary
					}
					}
				}]: []),
				{
					title: {
					text: t("redeem_amount"),
					},
					tickColor: "grey",
					lineColor: "grey",
					labels: {
					style: {
						color: secondary
					}
					},
					opposite: accountType !== "retailer",
				}
				],
				tooltip: {
				useHTML: true,
				valueSuffix: " €",
				formatter: function() {
					const formattedMonth = selectedFilter === "month" ? formatMonth(this.x as string) : this.x;
					return `
					<div style="color: #333;">
						<strong>${selectedFilter}: </strong>${formattedMonth}
						<br/>
						<span style="font-size: 14px; color: #666;"><strong>Amount: €</strong> ${this.y}</span>
					</div>
					`;
				}
				},
				plotOptions: {
				areaspline: {
					marker: {
					enabled: false
					},
					fillOpacity: 0.1, // Added fillOpacity to mimic ApexCharts area fill
				},
				},
				series: accountType !== "retailer" ?  [
				{
					type: "areaspline",
					name: t("purchase_amount"),
					data: data,
					showInLegend: true,
					color: "#1677FF",
					yAxis: 0,
				},
				{
					type: "areaspline",
					name: t("redeem_amount"),
					data: redeemData,
					showInLegend: true,
					color: "#003EB3",
					yAxis: 1,
				}
				] : [
				{
					type: "areaspline",
					name: t("redeem_amount"),
					data: redeemData,
					showInLegend: false,
					color: "#003EB3",
					yAxis: 0,
				}
				],
				credits: {
				enabled: false,
				},
				legend: {
				align: "center",
				verticalAlign: "bottom",
				itemStyle: {
					color: "grey",
				},
				symbolRadius: 0,
				symbolWidth: 16,
				symbolHeight: 16,
				},
			};
		  
		chartRef.current = Highcharts.chart("unique-chart-container", options1);
	}, [data,category]); 

	useEffect(() => {
		if (chartRef.current) {
			if (loadingChartData) {
				chartRef.current.showLoading("Loading Data...");
			} else {
				chartRef.current.hideLoading();
			}
		}
	}, [loadingChartData]);

	const formatField = (rowData: any, field: string) => {
		return (
			<div>
				<span onClick={() => setActiveCopyButton(rowData.id)}>
					{rowData[field].length > 10 ? `${rowData[field].substring(0, 10)}...` : rowData[field]}
				</span>
				{activeCopyButton === rowData.id && (
					<button 
						onClick={(event) => {
							navigator.clipboard.writeText(rowData[field]);
							event.stopPropagation();
							setActiveCopyButton(null); 
						}}
						style={{ cursor: "pointer" }}
					>
						{t("copy")}
					</button>
				)}
			</div>
		);
	};


	useEffect(() => {
		if(tablePurchaseData && tablePurchaseData !== undefined && tablePurchaseData.length > 0) {
			setTransactions(tablePurchaseData);
		}
		setLoading(false);
	}, []); 


	const headerStyle = { fontFamily: "'Public Sans', sans-serif", fontWeight: "600", fontSize: "1rem"};

	return (
		// <div>
		// 	<div className="p-grid p-gap-8" style={{width: "100%",height:"100%"}}>
		// 		<div className="p-col-12 p-md-4"  style={{width: "100%",height:"100%"}} >
		// 			<div className="uniqueVisitorChartHeader">
		// 				<h1 id="unique-visitor-label"><CIcon icon={cilChartLine} size="sm" style={{width: "20px"}}/> {t("statistics")} </h1> 
		// 				<div className="uniqueVisitorChartFilterbutton">
		// 					<Button type="submit" className={selectedFilter === "year" ? "selectedButton" : "myButton"} onClick={() => handleButtonClick("year")}>{t("year")}</Button>
		// 					<Button type="submit" className={selectedFilter === "month" ? "selectedButton" : "myButton"} onClick={() => handleButtonClick("month")}>{t("month")}</Button>      
		// 					<Button type="submit" className={selectedFilter === "week" ? "selectedButton" : "myButton"} onClick={() => handleButtonClick("week")}>{t("week")}</Button>
		// 				</div>      
		// 			</div>
		// 			<Card className="uniqueVisitorChartCard" id="dashboardCard">
		// 				<div id="unique-chart-container" style={{ width: "100%", height: "100%", position: "relative", borderRadius: 10 }}>
		// 				</div>
		// 			</Card>
		// 			<div className="highestspend">
		// 				<Typography variant="h5" style={{ fontSize: "14px", textAlign: "center"}}> {t("showing")} </Typography>
		// 			</div>
		// 		</div>


		// 	</div>

		// </div>
		<div style={{ display: 'grid', gridTemplateColumns: '67% 34%', width: '100%', height: '100%', gridGap: '1%' }}>
			<div style={{ width: '100%', height: '100%' }}>
				<div className="uniqueVisitorChartHeader">
					<h1 id="unique-visitor-label">
						<CIcon icon={cilChartLine} size="sm" style={{ width: '20px' }} /> {t("statistics")}
					</h1>
					<div className="uniqueVisitorChartFilterbutton">
						<Button type="submit" className={selectedFilter === "year" ? "selectedButton" : "myButton"} onClick={() => handleButtonClick("year")}>{t("year")}</Button>
						<Button type="submit" className={selectedFilter === "month" ? "selectedButton" : "myButton"} onClick={() => handleButtonClick("month")}>{t("month")}</Button>
						<Button type="submit" className={selectedFilter === "week" ? "selectedButton" : "myButton"} onClick={() => handleButtonClick("week")}>{t("week")}</Button>
					</div>
				</div>
				<Card className="uniqueVisitorChartCard" id="dashboardCard" style={{ height: 'calc(100% - 70px)', display: 'flex', flexDirection: 'column' }}>
					<div id="unique-chart-container" style={{ flexGrow: 1, position: 'relative', borderRadius: 10 }}>
					</div>
				</Card>
			</div>
			<div style={{ width: '100%', height: '100%' }}>
					<h1 id="unique-visitor-label">
						{t("transaction_history")}
					</h1>
				<Card className="transactionhistorytable tran-card" style={{width: '95%'}}>
				<DataTable value={sortedTransactions} rows={4} dataKey="id" loading={loading}  emptyMessage={t("no_transactions_found")} className="hide-table-header" header={null} >
						<Column field="type" body={(rowData: any) => rowData.type === 'purchase' ? <span style={{color: '#1677FF', fontSize: '30px'}}>●</span> : <span style={{color: '#003EB3', fontSize: '30px'}}>●</span>} />
						<Column field="transactionhash" style={{ minWidth: "15rem",whiteSpace: "nowrap" }} showFilterMenu={false} filterHeaderClassName='small-text' body={(rowData: any) => {
    						const date = new Date(rowData.createdAt);
    						const today = new Date();
    						const yesterday = new Date(today);
    						yesterday.setDate(today.getDate() - 1);  

    						let dateString;
    						if (date.toDateString() === today.toDateString()) {
        						dateString = `${t("today")}, ${date.toLocaleTimeString(t("timeformat"), { hour: "2-digit", minute: "2-digit", second: "2-digit" })}`;
    						} else if (date.toDateString() === yesterday.toDateString()) {
        						dateString = `${t("yesterday")}, ${date.toLocaleTimeString(t("timeformat"), { hour: "2-digit", minute: "2-digit", second: "2-digit" })}`;
    						} else {
        						dateString = date.toLocaleString(t("timeformat"), { timeZone: "UTC", year: "numeric", month: "short", day: "numeric", hour: "2-digit", minute: "2-digit", second: "2-digit" });
    						}
							const transactionHashSliced = rowData.transactionhash.length > 13 ? `${rowData.transactionhash.substring(0, 13)}...` : rowData.transactionhash;

								return (
									<>
										<div style={{fontWeight: 'bold'}}>{transactionHashSliced}</div>
										<div style={{fontSize: '14px', color: 'darkgray'}}>{dateString}</div>
									</>
								);
							}} />
						<Column field="amount"  style={{ minWidth: "8rem",whiteSpace: "nowrap", fontWeight: 'bold' }} showFilterMenu={false} body={(rowData: any) => rowData.type === 'purchase' ? `+ €${rowData.amount.toFixed(2)}` :  `- €${rowData.amount.toFixed(2)}`} filterHeaderClassName='small-text'/>
				</DataTable>

				</Card>

			</div>
		</div>


	);
}
