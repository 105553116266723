import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState, cartItem } from "../../../../store/models";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputNumber } from "primereact/inputnumber";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { updateCartQty } from "../../../../store/Reducers/userMain";
import { Button } from "primereact/button";
import { classNames } from "primereact/utils";
import { NavigateFunction, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { GlobalService, GlobalValues } from "../../../shared/services/global.service";
import "../../../Assets/Styling/gift.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCartShopping } from '@fortawesome/free-solid-svg-icons';
import { Pagination } from 'antd';

export default function CartScreenComponent({ appToastRef }: any) {
	const cartData: any = useSelector((state: RootState) => state.mainData.cartItems || []);
	const [total, setTotal] = useState(0); // Added total state
	const { t } = useTranslation();
	const navigate: NavigateFunction = useNavigate();
	const { username } = useParams();
	const [localCartData, setLocalCartData] = useState<cartItem[]>([]);
	const [filteredData, setFilteredData] = useState<cartItem[]>([]);

	const [currentPage, setCurrentPage] = useState(1);
	const itemsPerPage = 5;

	useEffect(() => {
		if (username && cartData[username]) {
			setLocalCartData(
				cartData[username].map((item: any) => ({
					...item,
					total: item.price * item.qty,
				}))
			);
			setFilteredData(
				cartData[username].map((item: any) => ({
					...item,
					total: item.price * item.qty,
				}))
			);
		}
	}, [cartData, username]);
	
	const dispatch: ThunkDispatch<RootState, void, any> = useDispatch();
	useEffect(() => {
		let calculatedTotal = 0;
		localCartData.forEach((item: any) => {
			calculatedTotal += item.total;
		});
		setTotal(calculatedTotal);
	}, [localCartData]);
	

	const representativeBodyTemplate = (rowData:any) => {				
		return (
		  <div className="flex align-items-center gap-2">
				<img
			  alt={rowData.username}
			  src={`${GlobalValues.baseWebUrl}${rowData.thumbnail_url}`}			  
			  style={{borderRadius:"50%",width:"30px" ,height:"30px"}}
				/>
				<span>{rowData.username}</span>
		  </div>
		);
	};

	  const onChange = (page:number) => {
		setCurrentPage(page);
	};


	const displayedData = filteredData.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

	const removeItemFromCart = (itemToRemove: any) => {
		const itemIndexToRemove = localCartData.findIndex(
		  (item: any) =>
				item.wallPaperId === itemToRemove.wallPaperId && item.price === itemToRemove.price
		);  
		if (itemIndexToRemove !== -1) {
		  const updatedCart = [...localCartData];
		  updatedCart.splice(itemIndexToRemove, 1);  
		  setLocalCartData(updatedCart);
		  dispatch(updateCartQty({ username, updatedCart }));
		  appToastRef.current.show({
				severity: "success",
				summary: t("success"),
				detail: t("gifts_deleted"),
				life: 3000,
		  });
		} else {
		  appToastRef.current.show({
				severity: "error",
				summary: t("error"),
				detail: t("gifts_not_found"),
				life: 3000,
		  });
		}
	  };
	return (
		<div className="padding-top">

			<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
				<FontAwesomeIcon icon={faCartShopping} style={{fontSize: '22px'}}/>
				<h1 className="text-center" style={{fontSize: '24px'}}>{t("shopping_cart")}</h1>
			</div>
			<div className="card cursor-pointer" style={{width:'94%', marginLeft:'3%',marginTop:'2%'}}>
				<DataTable key={currentPage}
					value={displayedData}
					//paginator
					rows={5}
					rowsPerPageOptions={[5, 7,10]}
					tableStyle={{ minWidth: "50rem",border: '1px solid #e4e4e4' }}
					editMode="cell"
				>
					<Column
						header={t("actions")} 
						body={(rowData) => (
							<Button
								icon="pi pi-times"
								className={classNames("p-button-danger", "p-button-outlined")}
								onClick={() => removeItemFromCart(rowData)}
							/>
						)}
						style={{ width: "5%" }}
					></Column>
					<Column field="username"  body={representativeBodyTemplate} header={t("name")} className="cartscreen-column-width"></Column>
					<Column field="price" header={t("price")}className="cartscreen-column-width"></Column>
					<Column
						field="qty"
						header={t("quantity")} 
						className="cartscreen-column-width"
					></Column>
					<Column field="total" header={t("total")} className="cartscreen-column-width"></Column>
				</DataTable>
				<div style={{ display: 'flex', justifyContent: 'center', marginTop: '1%' }}>
  					<Pagination defaultCurrent={1} total={localCartData.length} defaultPageSize={5} onChange={onChange} />
				</div>	
			</div>
			<div className="cart-screen-footer-parent">
				<div className="cart-screen-footer-child-1">
					<span style={{fontSize:"17px",fontWeight:"bold"}}>{t("grand_total")}</span>
					<span style={{fontSize:"17px",fontWeight:"bold"}}>{total}</span>

				</div>
				<div className="cart-screen-footer-child-2">
					<Button label={t("proceed_checkout")} style={{ width: "100%",padding:"5% 0", backgroundColor: 'rgb(3, 84, 204)' }} severity="info" raised onClick={() => {navigate("/proceedToCheckout");
						localCartData.length>0 && GlobalService.setStorage("mallUserName",username);
					}} />
				</div>
			</div>
		</div>
	);
}
