import { createSlice, createAsyncThunk, PayloadAction, createAction } from "@reduxjs/toolkit";
import { GlobalService } from "../../modules/shared/services/global.service";
import { HttpService } from "../../modules/shared/services/http.service";
import { NavigateFunction } from "react-router-dom";
import { StoreRegisterData, createStoreSuccess, validationEmailSuccess, JwtUserObject, LoginInterface,AllCountriesList, AllRetailerDropdownData, DashboardData, TransactionTableData,PartnerStore } from "../models";
import bcrypt from "bcryptjs";

//register store function
export const registerStore = createAsyncThunk<StoreRegisterData, { dataRegister: any; successMsg: string, errorMsg: string, toastRef: React.MutableRefObject<any>; navigate: NavigateFunction, setSelectCategory: any }>("users/registerStore",
	async ({ dataRegister, toastRef, navigate, setSelectCategory, successMsg, errorMsg }, { rejectWithValue }) => {
		try {						
			const response = await HttpService.postRequest("stores/registerCustomer", dataRegister);
			const { data }: any = await response.data;
			const StoreRegisterData: StoreRegisterData = data;
			GlobalService.setStorage("registerStoreData", StoreRegisterData);
			toastRef.current.show({
				severity: "success",
				summary: "Success",
				detail: successMsg,
				life: 3000,
			});
			setSelectCategory("successfully");
			return StoreRegisterData;
		} catch (error: any) {
			const { status }: any = error.response;
			console.log(error);
			const { details }: any = error?.response?.data?.error|| {};;
			if (status === 400) {
				toastRef.current.show({
					severity: "error",
					summary: "Error",
					detail: details,
					life: 3000,
				});
				return rejectWithValue(error.message || "An error occurred while creating a store");
			} else {
				toastRef.current.show({
					severity: "error",
					summary: "Error",
					detail: error.message,
					life: 3000,
				});
				return rejectWithValue(error.message || "An error occurred while creating a store");
			}
		}
	}
);
export const addStorePartners = createAsyncThunk<any, { updateData: any; toastRef: React.MutableRefObject<any>; }>(
	"users/addStorePartners",
	async ({ updateData, toastRef }, { rejectWithValue }) => {
		try {
			const response = await HttpService.postRequest("stores/addPartners", updateData);
			return response;
		} catch (error: any) {
			const { status }: any = error.response;
			const { details }: any = error?.response?.data?.error|| {};;
			if (status === 400) {
				toastRef.current.show({
					severity: "error",
					summary: "Error",
					detail:details,
					life: 3000,
				});
				return rejectWithValue("User Profile does not updated" || "An error occurred while creating a store");
			} else {
				toastRef.current.show({
					severity: "error",
					summary: "Error",
					detail: error.message,
					life: 3000,
				});
				return rejectWithValue(error.message || "An error occurred while creating a store");
			}
		}
	}
);
export const loginStore = createAsyncThunk<LoginInterface, { loginData: any; successMsg: string, errorMsg: string, toastRef: React.MutableRefObject<any>; navigate: NavigateFunction }>(
	"users/loginStore",
	async ({ loginData, toastRef, successMsg, errorMsg, navigate }, { rejectWithValue }) => {
		try {
			const { email, password, mode } = loginData;
			const responseHash = await HttpService.postRequest("stores/getClientSalt", { email, mode });
			let { data: client_salt }: any = responseHash.data;
			client_salt = "$2a" + client_salt.substring(3, client_salt.length);
			const client_hash = bcrypt.hashSync(password, client_salt);			
			const response = await HttpService.postRequest("stores/loginCustomer", { email, client_hash, mode });
			const { data }: any = await response.data;
			const LoginInterfaceData: LoginInterface = { ...data, client_hash };
			GlobalService.setStorage("loginSuccessData", LoginInterfaceData);
			toastRef.current.show({
				severity: "success",
				summary: "Success",
				detail: successMsg,
				life: 3000,
			});
			setTimeout(() => {
				navigate("/home/dashboard");
			}, 3000);
			return LoginInterfaceData;
		} catch (error: any) {
			const { status }: any = error.response;
			const { details }: any = error?.response?.data?.error|| {};;
			if (status === 400) {
				toastRef.current.show({
					severity: "error",
					summary: "Error",
					detail:details,
					life: 3000,
				});
				return rejectWithValue("This Store is already created" || "An error occurred while creating a store");
			} else {
				toastRef.current.show({
					severity: "error",
					summary: "Error",
					detail: error.message,
					life: 3000,
				});
				return rejectWithValue(error.message || "An error occurred while creating a store");
			}
		}
	}
);



// Get Store Transactions
export const storeTransactionData = createAsyncThunk<DashboardData, { storeData: any; successMsg: string, errorMsg: string, toastRef: React.MutableRefObject<any>; navigate: NavigateFunction }>(
	"users/storeTransactionData",
	async ({ storeData, toastRef, successMsg, errorMsg, navigate }, { rejectWithValue }) => {
		try {
			const { username, window, createdAtStart, limit, start } = storeData;
			const createdAtStartFormatted = new Date(createdAtStart).toISOString();
			const url = `transactions/getStoreTransactions?limit=${limit}&start=${start}&filters[createdAt][$gte]=${createdAtStartFormatted}&window=${window}`;
			const response = await HttpService.postRequest(url,{ username });
			console.log("api data",response);
			const { data }: any = await response.data;
			const DashboardData: DashboardData = { ...data };
			GlobalService.setStorage("dashboardSuccessData", DashboardData);
			return DashboardData;

		} catch (error: any) {
			const { status }: any = error.response;
			const { details }: any = error?.response?.data?.error|| {};;

			if (status === 400) {
				toastRef.current.show({
					severity: "error",
					summary: "Error",
					detail:details,
					life: 3000,
				});
				return rejectWithValue("An error occurred while loading the data from the server");
			} else {
				toastRef.current.show({
					severity: "error",
					summary: "Error",
					detail: error.message,
					life: 3000,
				});
				return rejectWithValue(error.message || "An error occurred while loading the data from the server");
			}
		}
	}
);


// Get Transaction Table Data
export const transactionTableData = createAsyncThunk<TransactionTableData, { tableData: any; successMsg: string, errorMsg: string, toastRef: React.MutableRefObject<any>; navigate: NavigateFunction }>(
	"users/transactionData",
	async ({ tableData, toastRef, successMsg, errorMsg, navigate }, { rejectWithValue }) => {
		try {
			const { username, createdAtStart, limit, start, actionName, transactionHash, customer_username, createdAtEnd } = tableData;
			const createdAtStartFormatted = new Date(createdAtStart).toISOString();

			const createdAtEndDate = new Date(createdAtEnd);
            createdAtEndDate.setHours(23, 59, 59, 999);
            const createdAtEndFormatted = createdAtEndDate.toISOString();
			let url = `transactions/getStoreTransactionsTable?limit=${limit}&start=${start}&filters[createdAt][$gte]=${createdAtStartFormatted}&filters[actionName][$eq]=${actionName}`; //&filters[transactionhash][$eq]=${transactionHash}`;
			if (transactionHash && transactionHash.trim() !== "") {
				url += `&transaction_hash=${transactionHash}`;
			}
			if( customer_username && customer_username.trim() !== "" ) {
				url += `&customer_username=${customer_username}`;
			}
			if( createdAtEnd && createdAtEnd !== null) {
				url+= `&filters[createdAt][$lte]=${createdAtEndFormatted}`;
			}
			
			const response = await HttpService.postRequest(url,{ username });
			const { data }: any = await response.data;
			const TransactionTableData: TransactionTableData = { ...data };
			GlobalService.setStorage("tableSuccessData", TransactionTableData);
			return TransactionTableData;

		} catch (error: any) {
			const { status }: any = error.response;
			const { details }: any = error?.response?.data?.error|| {};;

			if (status === 400) {
				toastRef.current.show({
					severity: "error",
					summary: "Error",
					detail:details,
					life: 3000,
				});
				return rejectWithValue("An error occurred while loading the data from the server");
			} else {
				toastRef.current.show({
					severity: "error",
					summary: "Error",
					detail: error.message,
					life: 3000,
				});
				return rejectWithValue(error.message || "An error occurred while loading the data from the server");
			}
		}
	}
);

//download Transactions
export const downloadTransactions = createAsyncThunk<any, { downloadTransaction: any; successMsg: string, errorMsg: string, toastRef: React.MutableRefObject<any>; }>(
	"users/downloadTransactions",
	async ({ downloadTransaction, toastRef, successMsg, errorMsg }, { rejectWithValue }) => {
		try {
			const { username, createdAtStart} = downloadTransaction;
			const createdAtStartFormatted = new Date(createdAtStart).toISOString();
			const url = `transactions/downloadTransactions?filters[createdAt][$gte]=${createdAtStartFormatted}`;
			const response = await HttpService.postRequest(url,{ username });
			console.log(response,"response");
			const { data }:any = await response;	
			const responseCode:any = {...data};
		
			toastRef.current.show({
				severity: "success",
				summary: "Success",
				detail: successMsg,
				life: 3000,
			});
			return responseCode;	

		} catch (error: any) {
			const { status }: any = error.response;
			const { details }: any = error?.response?.data?.error|| {};;
			if (status === 400) {
				toastRef.current.show({
					severity: "error",
					summary: "Error",
					detail:details,
					life: 3000,
				});
				return rejectWithValue("An error occurred. Please try again.");
			} else {
				toastRef.current.show({
					severity: "error",
					summary: "Error",
					detail: error.message,
					life: 3000,
				});
				return rejectWithValue(error.message || "An error occurred. Please try again.");
			}
		}
	}
);




// Get Details of Partner store
export const getPartnerStores = createAsyncThunk<PartnerStore, { username: any; successMsg: string, errorMsg: string, toastRef: React.MutableRefObject<any>; navigate: NavigateFunction }>(
	"users/partnerStore",
	async ({ username, toastRef, successMsg, errorMsg, navigate }, { rejectWithValue }) => {
		try {
			const url = "stores/getPartners";
			const response = await HttpService.postRequest(url,{ username });
			console.log("api data",response);
			const { data }: any = await response.data;
			const PartnerStore: PartnerStore = { ...data };
			GlobalService.setStorage("partnerStoreData", PartnerStore);
			return PartnerStore;

		} catch (error: any) {
			const { status }: any = error.response;
			const { details }: any = error?.response?.data?.error|| {};;

			if (status === 400) {
				toastRef.current.show({
					severity: "error",
					summary: "Error",
					detail:details,
					life: 3000,
				});
				return rejectWithValue("An error occurred while loading the data from the server");
			} else {
				toastRef.current.show({
					severity: "error",
					summary: "Error",
					detail: error.message,
					life: 3000,
				});
				return rejectWithValue(error.message || "An error occurred while loading the data from the server");
			}
		}
	}
);




export const redeemCartDropdown = createAsyncThunk<AllRetailerDropdownData>(
	"users/redeemCartDropdown",
	async (_, { rejectWithValue }) => {
		try {
			const response = await HttpService.getRequest("stores?filters[accountType][$eq]=mall");			
			const { data } = response.data;
			GlobalService.setStorage("mallDropdown", data);
			return data;
		} catch (error: any) {
			const { status }: any = error.response;
			console.log(error.response, "redeemCart");
			return rejectWithValue(error.message || "An error occurred while creating a store");
		}
	}
);

export const createStore = createAsyncThunk<createStoreSuccess, { dataEmail: any; successMsg: string, errorMsg: string, toastRef: React.MutableRefObject<any>; navigate: NavigateFunction }>(
	"users/createStore",
	async ({ dataEmail, toastRef, successMsg, errorMsg, navigate }, { rejectWithValue }) => {
		try {
			const response = await HttpService.postRequest("stores/createStore", dataEmail);
			const { statusCode, message }: any = await response.data;
			const { email,name } = dataEmail;
			const createStoreSuccess: createStoreSuccess = { statusCode, message, email,name };
			GlobalService.setStorage("createStoreSuccess", createStoreSuccess);
			toastRef.current.show({
				severity: "success",
				summary: "Success",
				detail: successMsg,
				life: 3000,
			});
			navigate("/auth/signup");
			return createStoreSuccess;
		} catch (error: any) {
			const { status }: any = error.response;
			const { details }: any = error?.response?.data?.error|| {};;
			if (status === 400) {
				toastRef.current.show({
					severity: "error",
					summary: "Error",
					detail: details,
					life: 3000,
				});
				return rejectWithValue("This Store is already created" || "An error occurred while creating a store");
			} else {
				toastRef.current.show({
					severity: "error",
					summary: "Error",
					detail: error.message,
					life: 3000,
				});
				return rejectWithValue(error.message || "An error occurred while creating a store");
			}
		}
	}
);

export const validationEmail = createAsyncThunk<validationEmailSuccess, { verifyEmail: any; successMsg: string, errorMsg: string, verifyEmailtoastRef: React.MutableRefObject<any>; setSelectCategory: any }>(
	"users/validationEmail",
	async ({ verifyEmail, verifyEmailtoastRef, successMsg, errorMsg, setSelectCategory }, { rejectWithValue }) => {
		try {
			const response = await HttpService.postRequest("stores/verifyEmailCode", verifyEmail);			
			const { statusCode, message,data }: any = await response.data;			
			const { email, verificationCode } = verifyEmail;			
			const {accountType,id,name}:any=data;
			const validationEmailSuccess: validationEmailSuccess = { statusCode, message, email, verificationCode,accountType,id,name };			
			GlobalService.setStorage("validationEmailSuccess", validationEmailSuccess);
			setSelectCategory("yourDetails");
			verifyEmailtoastRef.current.show({
				severity: "success",
				summary: "Success",
				detail: successMsg,
				life: 3000,
			});
			return validationEmailSuccess;
		} catch (error: any) {
			const { status }: any = error.response;
			const { details }: any = error?.response?.data?.error|| {};;			
			if (status === 400) {
				verifyEmailtoastRef.current.show({
					severity: "error",
					summary: "Error",
					detail: details,
					life: 3000,
				});
				return rejectWithValue("Your email or verification code is wrong" || "An error occurred while validating a email");
			} else {
				verifyEmailtoastRef.current.show({
					severity: "error",
					summary: "Error",
					detail: error.message,
					life: 3000,
				});
				return rejectWithValue(error.message || "An error occurred while validating a email");
			}
		}
	}
);

//forgot email verification code
export const forgotPasswordEmail = createAsyncThunk<any, { verifyEmail: any; successMsg: string, errorMsg: string, verifyEmailtoastRef: React.MutableRefObject<any>; setSelectCategory: any }>(
	"users/forgotPasswordEmail",
	async ({ verifyEmail, verifyEmailtoastRef, successMsg, errorMsg, setSelectCategory }, { rejectWithValue }) => {
		try {
			const response = await HttpService.postRequest("stores/sendEmailCode", verifyEmail);
			setSelectCategory("verifyEmail");
			verifyEmailtoastRef.current.show({
				severity: "success",
				summary: "Success",
				detail: successMsg,
				life: 3000,
			});
			return response;
		} catch (error: any) {
			const { status }: any = error.response;
			const { details }: any = error?.response?.data?.error|| {};;

			if (status === 400) {
				verifyEmailtoastRef.current.show({
					severity: "error",
					summary: "Error",
					detail:details,
					life: 3000,
				});
				return rejectWithValue("Your email is wrong" || "An error occurred while validating a email");
			} else {
				verifyEmailtoastRef.current.show({
					severity: "error",
					summary: "Error",
					detail: error.message,
					life: 3000,
				});
				return rejectWithValue(error.message || "An error occurred while validating a email");
			}
		}
	}
);
//handle forgot password recreation
export const forgotPasswordRecreation = createAsyncThunk<any, { verifyEmail: any; successMsg: string, errorMsg: string, verifyEmailtoastRef: React.MutableRefObject<any>; setSelectCategory: any }>(
	"users/forgotPasswordRecreation",
	async ({ verifyEmail, verifyEmailtoastRef, successMsg, errorMsg, setSelectCategory }, { rejectWithValue }) => {
		try {
			const response = await HttpService.postRequest("stores/forgetPassword", verifyEmail);
			setSelectCategory("loginIn");
			verifyEmailtoastRef.current.show({
				severity: "success",
				summary: "Success",
				detail: successMsg,
				life: 3000,
			});
			return response;
		} catch (error: any) {
			const { status }: any = error.response;
			const { details }: any = error?.response?.data?.error|| {};;

			if (status === 400) {
				verifyEmailtoastRef.current.show({
					severity: "error",
					summary: "Error",
					detail: details,
					life: 3000,
				});
				return rejectWithValue("Wrong email verification code" || "An error occurred while validating a email");
			} else {
				verifyEmailtoastRef.current.show({
					severity: "error",
					summary: "Error",
					detail: error.message,
					life: 3000,
				});
				return rejectWithValue(error.message || "An error occurred while validating a email");
			}
		}
	}
);
export const getJwtToken = createAsyncThunk<JwtUserObject, {identifier:string,password:string,calledBy:string,errorMsg: string, toastRef: React.MutableRefObject<any>}>(
	"users/getJwtToken",
	async ({identifier,password,calledBy,errorMsg,toastRef}, { rejectWithValue }) => {
			
		if (calledBy==="createStore"){
			try {

				const sendingData = {
					identifier: identifier,
					password: password,
				};				
				const response = await HttpService.postRequest(
					"auth/local", sendingData
				);
				const { jwt, user: UserTokenData }: any = response.data;
				const jwtUserObject: JwtUserObject = { jwt, UserTokenData,calledBy:"createStore" };
				GlobalService.setStorage("token", jwt); //for interceptors
				GlobalService.setStorage("jwtToken", jwtUserObject);//user complete obj with user
				return jwtUserObject;
			} catch (error: any) {
				const { message }: any = error?.response?.data?.error|| {};;

				toastRef.current.show({
					severity: "error",
					summary: "Error",
					detail: message,
					life: 3000,
				});
				console.log(error);
				
				return rejectWithValue("Error in creating JWT token " + error.message || "An error occurred");
			}
		}else{
			try {
				const sendingData = {
					identifier: "ak.pumacy@gmail.com",
					password: "WUtQn6Pdg1Y2Wxo5",
				};
				const response = await HttpService.postRequest(
					"auth/local", sendingData
				);
				const { jwt, user: UserTokenData }: any = response.data;
				const jwtUserObject: JwtUserObject = { jwt, UserTokenData,calledBy:"App" };
				GlobalService.setStorage("token", jwt); //for interceptors
				GlobalService.setStorage("jwtToken", jwtUserObject);//user complete obj with user
				return jwtUserObject;
			} catch (error: any) {
				console.error("JWT token error:", error);
				return rejectWithValue("Error in creating JWT token " + error.message || "An error occurred");
			}
		}
	}
);

interface UserState {
	StoreRegisterData: StoreRegisterData | null,
	LoginInterface: LoginInterface | null;
	createStoreSuccess: createStoreSuccess | null;
	jwtToken: JwtUserObject | null;
	validationEmailSuccess: validationEmailSuccess | null;
	createStoreError: string | null;
	jwtTokenError: string | null;
	AllRetailerDropdownData: AllRetailerDropdownData | [];
	AllCountriesList:AllCountriesList|[];
	DashboardData: DashboardData | null;
	TransactionTableData: TransactionTableData | null;
	PartnerStore: PartnerStore | null;
}
const initialStoreRegisterInfo = localStorage.getItem("registerStoreData");
const initialLoginInterfaceInfo = localStorage.getItem("loginSuccessData");
const initialValidationEmailInfo = localStorage.getItem("validationEmailSuccess");
const initialUserInfo = localStorage.getItem("createStoreSuccess");
const initialJwtToken = localStorage.getItem("jwtToken");
const initialRetailerDropdown = localStorage.getItem("mallDropdown");
const initialCountryDropdown = localStorage.getItem("AllCountriesList");
const initialDashboardData = localStorage.getItem("dashboardSuccessData");
const initialTransactionTableData = localStorage.getItem("tableSuccessData");
const initialPartnerStore = localStorage.getItem("partnerStoreData");

const initialState: UserState = {
	AllCountriesList: initialCountryDropdown ? JSON.parse(initialCountryDropdown) : [],
	AllRetailerDropdownData: initialRetailerDropdown ? JSON.parse(initialRetailerDropdown) : [],
	LoginInterface: initialLoginInterfaceInfo ? JSON.parse(initialLoginInterfaceInfo) : null,
	StoreRegisterData: initialStoreRegisterInfo ? JSON.parse(initialStoreRegisterInfo) : null,
	validationEmailSuccess: initialValidationEmailInfo ? JSON.parse(initialValidationEmailInfo) : null,
	createStoreSuccess: initialUserInfo ? JSON.parse(initialUserInfo) : null,
	jwtToken: initialJwtToken ? JSON.parse(initialJwtToken) : null,
	createStoreError: null,
	jwtTokenError: null,
	DashboardData: initialDashboardData ? JSON.parse(initialDashboardData) : null,
	TransactionTableData: initialTransactionTableData ? JSON.parse(initialTransactionTableData) : null,
	PartnerStore: initialPartnerStore ? JSON.parse(initialPartnerStore) : null,
};
const userReducer = createSlice({
	name: "users",
	initialState,
	reducers: {
		logout(state) {
			state.LoginInterface = null;
			state.StoreRegisterData = null;
			state.validationEmailSuccess = null;
			state.createStoreSuccess = null;
			state.DashboardData = null;
			GlobalService.removeStorage("loginSuccessData");
			GlobalService.removeStorage("loginSuccessData");
			GlobalService.removeStorage("PartnersDataResponse");
			GlobalService.removeStorage("registerStoreData");
			GlobalService.removeStorage("uploadProfileImageData");
			GlobalService.removeStorage("validationEmailSuccess");
			GlobalService.removeStorage("createStoreSuccess");
			GlobalService.removeStorage("jwtToken");
			GlobalService.removeStorage("ChartDataResponse");
			GlobalService.removeStorage("StoreGiftData");
			GlobalService.removeStorage("TablesDataResponse");
			GlobalService.removeStorage("StoreWallPapersData");
			GlobalService.removeStorage("selectedItem");
			GlobalService.removeStorage("mallDropdown");
			
			GlobalService.removeStorage("dashboardSuccessData");
			GlobalService.removeStorage("tableSuccessData");
			GlobalService.removeStorage("partnerStoreData");
			state.createStoreSuccess = null;
			state.jwtToken = null;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(createStore.fulfilled, (state, action: PayloadAction<createStoreSuccess>) => {
			state.createStoreSuccess = action.payload;
			state.createStoreError = null;
		});
		builder.addCase(validationEmail.fulfilled, (state, action: PayloadAction<validationEmailSuccess>) => {
			state.validationEmailSuccess = action.payload;
		});
		builder.addCase(loginStore.fulfilled, (state, action: PayloadAction<LoginInterface>) => {
			state.LoginInterface = action.payload;
		});
		builder.addCase(storeTransactionData.fulfilled, (State, action: PayloadAction<DashboardData>) => {
			State.DashboardData = action.payload;
		});
		builder.addCase(transactionTableData.fulfilled, (State, action: PayloadAction<TransactionTableData>) => {
			State.TransactionTableData = action.payload;
		});
		builder.addCase(getPartnerStores.fulfilled, (State, action: PayloadAction<PartnerStore>) => {
			State.PartnerStore = action.payload;
		});
		builder.addCase(registerStore.fulfilled, (state, action: PayloadAction<StoreRegisterData>) => {
			state.StoreRegisterData = action.payload;
		});
		builder.addCase(getJwtToken.fulfilled, (state, action: PayloadAction<JwtUserObject>) => {
			state.jwtToken = action.payload;
			state.jwtTokenError = null;
		});
		builder.addCase(redeemCartDropdown.fulfilled, (state, action: PayloadAction<AllRetailerDropdownData>) => {
			state.AllRetailerDropdownData = action.payload;
		});
		builder.addCase(createStore.rejected, (state, action) => {
			state.createStoreError = action.payload as string;
		});
		builder.addCase(getJwtToken.rejected, (state, action) => {
			state.jwtTokenError = action.payload as string;
		});
		builder.addCase(updateProfileInStore, (state, action) => {
			if (state.LoginInterface) {
				const { name, country, city,address,postcode,paymentMethodData,paymentId } = action.payload;
				const { paymentDetails } = state.LoginInterface;

				if (name) {
					state.LoginInterface.name = name;
				}
				if (country) {
					state.LoginInterface.info.country = country;
				}
				if (address) {
					state.LoginInterface.info.address = address;
				}
				if (postcode) {
					state.LoginInterface.info.postcode = postcode;
				}
				if (city){
					state.LoginInterface.info.city = city;
				}
				if (paymentMethodData ) {					
					state.LoginInterface.paymentDetails=paymentMethodData;
				  }
				GlobalService.setStorage("loginSuccessData", state.LoginInterface);
			}
		});

	},
});
export const updateProfileInStore = createAction<any>("users/updateProfileInStore");
export const { logout } = userReducer.actions;
export default userReducer.reducer;