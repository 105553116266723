// HomeRoutes.js
import React from "react";
import { Route, Routes } from "react-router-dom";
import LayoutComponent from "../components/Sidebar/LayoutComponent";
import ProfileComponent from "../components/Profile/ProfileComponent";
import NotFoundComponent from "./NotFoundComponent";
import DashboardComponent from "../components/Dashboard/DashboardComponent";
import TransactionComponent from "../components/Dashboard/components/Transaction/TransactionComponent";


const HomeRoutes = ({ appToastRef }: any) => {


	return (
		<Routes>
			<Route element={<LayoutComponent appToastRef={appToastRef} />}>
				<Route index element={<DashboardComponent appToastRef={appToastRef} />} />
				<Route path="/profile" element={<ProfileComponent appToastRef={appToastRef} />} />
				<Route path="/Dashboard" element={<DashboardComponent appToastRef={appToastRef} />} />
				<Route path="/Transactions" element={<TransactionComponent appToastRef={appToastRef} />} />
				<Route path="*" element={<NotFoundComponent />} />
			</Route>
		</Routes>
	);
};

export default HomeRoutes;
