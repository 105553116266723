// LayoutComponent.js
import React, { useEffect, useState } from "react";
import SidebarComponent from "./SidebarComponent";
import { Outlet } from "react-router-dom";
import { useNavigate, NavigateFunction } from "react-router-dom";
import "../../../Assets/Styling/home.css";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store/models";
import { Sidebar } from "primereact/sidebar";
import { Button } from "primereact/button";
export default function LayoutComponent({ appToastRef }:any) {
	const [selectedItem, setSelectedItem] = useState(() => {
		// Retrieve selected item from localStorage if available, else default to "Dashboard"
		return localStorage.getItem("selectedItem") || "Dashboard";
	});
	const [visible, setVisible] = useState(false);

	const { t } = useTranslation();
	const navigate: NavigateFunction = useNavigate();
	const loginData = useSelector((state: RootState) => state.authData.LoginInterface);
	useEffect(() => {
		if (!loginData) {
			navigate("/auth/login");
		}
	}, [loginData, navigate]);
	const handleItemClick = (itemName:any) => {
		setSelectedItem(itemName);        
		if (itemName===t("Profile")){
			navigate("/home/profile");
		}
		else if (itemName === t("dashboard")){
			navigate("/home/Dashboard");
		}
		else if(itemName === t("transaction")) {
			navigate("/home/Transactions");
		}
	};

	const items = [
		{ name: t("dashboard"), icon: "pi pi-qrcode" },
		{ name: t("transaction"), icon: "pi pi-wallet" },
		{ name: t("Profile"), icon: "pi pi-user" }
	];

	return (
		<div style={{ display: "flex",height:"100vh"}}>
			<SidebarComponent
				selectedItem={selectedItem}
				handleItemClick={handleItemClick}
				items={items}
				appToastRef={appToastRef}
			/>
			<div style={{ flexBasis: "100%",overflowY:"auto",height:"100%",backgroundColor:"#f9fafb" }}>
				<Outlet />
			</div>
		</div>
	);
}
