import React, { useEffect, useState } from "react";
import { classNames } from "primereact/utils";
import { useFormik } from "formik";
import { Timeline } from "primereact/timeline";
import { InputText } from "primereact/inputtext"; import { Button } from "primereact/button";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, NavigateFunction } from "react-router-dom";
import "../../Assets/Styling/auth.css";
import { ThunkDispatch } from "redux-thunk";
import { addStorePartners, registerStore, validationEmail } from "../../../store/Reducers/userAuth";
import bcrypt from "bcryptjs";
import { SignupProps, VerifiactionEmailFormValues, userAddress, userDetails, userPassword ,UserPaymentDetails} from "../models";
import { RootState } from "../../../store/models";
import { Dropdown } from "primereact/dropdown";
import { Password } from "primereact/password";
import { ProgressSpinner } from "primereact/progressspinner";
import { redeemCartDropdown } from "../../../store/Reducers/userAuth";
import { useTranslation } from "react-i18next";
import { Country, City } from "country-state-city";
import { Checkbox } from "primereact/checkbox";

const SignupComponent: React.FC<SignupProps> = ({ appToastRef }) => {
	const dispatch: ThunkDispatch<RootState, void, any> = useDispatch();
	const navigate: NavigateFunction = useNavigate();
	const { t } = useTranslation();
	const statusCreateStoreEmail = useSelector((state: RootState) => state.authData.createStoreSuccess?.email);
	const allVerifiedEmailData= useSelector((state: RootState) => state.authData.validationEmailSuccess);	
	const accountType = useSelector((state: RootState) => state.authData.validationEmailSuccess?.accountType);
	const [showSpinner, setShowSpinner] = useState(false);
	const [showOtherSpinner, setShowOtherSpinner] = useState(false);
	const [Cities, setCities] = useState([]);
	const [selectCategory, setSelectCategory] = useState("verifyEmail"); // Set the selected number
	const redeemDropdown = useSelector((state: RootState) => state.authData.AllRetailerDropdownData);
	const [allCheckBox, setAllCheckbox] = useState(["paypal"]);
	//Timeline layout handled
	const [layout, setLayout] = useState<"vertical" | "horizontal" | undefined>("vertical");
	const [showSubtitle, setShowSubtitle] = useState(true);

	useEffect(() => {
		const handleResize = () => {
			const screenWidth = window.innerWidth;
			if (screenWidth <= 750) {
				setLayout("horizontal");
				setShowSubtitle(false);
			} else {
				setLayout("vertical");
				setShowSubtitle(true);
			}
		};

		// Initial check on component mount
		handleResize();

		// Add event listener for window resize
		window.addEventListener("resize", handleResize);

		// Cleanup the event listener
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	const [userPayment, setUserPayment] = useState<UserPaymentDetails>({
	  paymentMethod: ["paypal"],
	  stripePaymentId: "",
	  paypalPaymentId: "",
	  payoneMerchantId: "",
	  payoneSecretId: "",
	  payoneApiId: "",
	  bankAccountName:"",
		bankIban:"",
		bankBic:""
	});	
	const onIngredientsChange = (e: any) => {
		const _userPayment = { ...userPayment };
		const selectedValue = e.value;
	
		if (e.checked) {
		  _userPayment.paymentMethod.push(selectedValue);
	
		  // Check if selectedValue is not already present in allCheckBox, then add it
		  if (!allCheckBox.includes(selectedValue)) {
				setAllCheckbox((prevAllCheckBox) => [...prevAllCheckBox, selectedValue]);
		  }
		} else {
		  _userPayment.paymentMethod = _userPayment.paymentMethod.filter(
				(method) => method !== selectedValue
		  );
	
		  // Use type assertion to inform TypeScript about the dynamic key
		  (_userPayment as any)[selectedValue + "PaymentId"] = "";
	
		  // Check if selectedValue is present in allCheckBox, then remove it
		  if (allCheckBox.includes(selectedValue)) {
				setAllCheckbox((prevAllCheckBox) =>
			  prevAllCheckBox.filter((method) => method !== selectedValue)
				);
		  }
		}
	
		setUserPayment(_userPayment);
	  };
	
	const formattedCountryData = Country.getAllCountries().map((country: any) => {
		return {
			value: country.isoCode,
			label: country.name,
		};
	});
	const fetchCities = (country: any) => {
		const mappedCities: any = City.getCitiesOfCountry(country)?.map((city) => ({ label: city.name, value: city.name }));
		setCities(mappedCities);
	};
	
	const retailerOptions = redeemDropdown?.map((item) => ({
		label: item.name,
		value: item.username,
	}));

	const [userDetails, setUserDetails] = useState<userDetails>({
		name: allVerifiedEmailData?.name||"",
		email:allVerifiedEmailData?.email || "",
		username: "",
		verificationCode: allVerifiedEmailData?.verificationCode || "",
		mall: ""
	});
	const [userAddress, setUserAddress] = useState<userAddress>({
		country: "",
		city: "",
		postcode: "",
		address: "",
	});

	//email data
	const emailFormik = useFormik({
		initialValues: {
			email: statusCreateStoreEmail || "",
			verificationCode: "",
		},
		validate: (data) => {
			const errors: Partial<{ name: string, email: string, verificationCode: string }> = {};
			if (!data.email) {
				errors.email = t("invalid_email_error_required");
			} else if (!/^\S+@\S+\.\S+$/.test(data.email)) {
				errors.email = t("invalid_email_error");
			}
			if (!data.verificationCode) {
				errors.verificationCode = t("verificationcode_required_error");
			}
			return errors;
		},
		onSubmit: (data) => {
			// Handle form submission here
			handleVerifiactionEmailSubmit(data);
		},
	});	
	const isFormFieldEmailVerificationInvalid = (name: keyof typeof emailFormik.values) =>
		!!(emailFormik.touched[name] && emailFormik.errors[name]);
	const getFormEmailVerificationErrorMessage = (name: keyof typeof emailFormik.values) =>
		isFormFieldEmailVerificationInvalid(name) ? (
			<small className="p-error margin-top">{emailFormik.errors[name]}</small>
		) : (
			<small className="p-error">&nbsp;</small>
		);
	const handleVerifiactionEmailSubmit = async (values: VerifiactionEmailFormValues) => {
		try {
			setShowSpinner(true);
			await dispatch(validationEmail({ verifyEmail: values, successMsg: t("verified_email_success"), errorMsg: t("verified_email_error"), verifyEmailtoastRef: appToastRef, setSelectCategory }));
			setShowSpinner(false);

		} catch (error) {
			// Handle errors here
			setShowSpinner(false);
			console.error("Sign-up error:", error);
		}
	};	
	//payment details
	const userPaymentFormik = useFormik({
		initialValues: {
		  paymentMethod:allCheckBox,
		  stripePaymentId: "",
		  paypalPaymentId: "",
		  payoneMerchantId: "",
	  payoneSecretId: "",
	  payoneApiId: "",
	  bankAccountName:"",
	  bankIban:"",
	  bankBic:""
		},
		validate: (data) => {
		  const errors: Partial<UserPaymentDetails> = {};		
		  // Validate individual payment methods
		  
		  if (data.paymentMethod.includes("paypal") && !data.paypalPaymentId) {
				errors.paypalPaymentId = t("paypal_error");
		  }
	
		  if (data.paymentMethod.includes("stripe") && !data.stripePaymentId) {
				errors.stripePaymentId = t("stripe_error");
		  }
	
		  if (data.paymentMethod.includes("payone") && !data.payoneMerchantId) {
				errors.payoneMerchantId = t("payone_merchant_error");
		  }
		  if (data.paymentMethod.includes("payone") && !data.payoneApiId) {
				errors.payoneApiId = t("payone_api_error");
	  }
	  if (data.paymentMethod.includes("payone") && !data.payoneSecretId) {
				errors.payoneSecretId = t("payone_secret_error");
			}
			if (data.paymentMethod.includes("bank") && !data.bankAccountName) {
				errors.bankAccountName = t("bank_name_error");
		  }
		  if (data.paymentMethod.includes("bank") && !data.bankIban) {
				errors.bankIban = t("bank_iban_error");
	  }
	  if (data.paymentMethod.includes("bank") && !data.bankBic) {
				errors.bankBic = t("bank_bic_error");
			}
	
		  return errors;
		},
		onSubmit: (data) => {
		  setSelectCategory("securePassword");
		  setUserPayment(data);
		},
	  });
	
		  
	const isFormFieldPaymentDetailsInvalid = (name: keyof typeof userPaymentFormik.values) =>
		!!(userPaymentFormik.touched[name] && userPaymentFormik.errors[name]);
	const getFormUserPaymentDetailsErrorMessage = (name: keyof typeof userPaymentFormik.values) =>
		isFormFieldPaymentDetailsInvalid(name) ? (
			<small className="p-error margin-top">{userPaymentFormik.errors[name]}</small>
		) : (
			<small className="p-error">&nbsp;</small>
		);
	useEffect(() => {
		userPaymentFormik.setValues({
			  ...userPaymentFormik.values,
			  paymentMethod: allCheckBox,
		});
		  }, [allCheckBox]);
	//your details
	const userDetailsFormik = useFormik({
		initialValues: {
			email: allVerifiedEmailData?.email || "",
			name: allVerifiedEmailData?.name||"",
			username: "",
			verificationCode: allVerifiedEmailData?.verificationCode || "",
			mall: ""
		},
		validate: (data) => {
			const errors: Partial<{ name: string, email: string, verificationCode: string, username: string, mall: string }> = {};
			if (!data.email) {
				errors.email = t("invalid_email_error_required");
			} else if (!/^\S+@\S+\.\S+$/.test(data.email)) {
				errors.email = t("invalid_email_error");
			}
			if (accountType == "retailer") {
				if (!data.mall) {
					errors.mall = t("mall_required_error");
				}
			}
			if (!data.name) {
				errors.name = t("name_required_error");
			}
			if (!data.username) {
				errors.username = t("username_required_error");
			} else if (data.username.length < 5) {
				errors.username = t("username_length_error");
			}
			if (data.username) {
				// Use a regular expression to check if the username contains only allowed characters
				const usernameRegex = /^[a-zA-Z0-9\-_]+$/; // Alphanumeric, "-", and "_" are allowed
				if (!usernameRegex.test(data.username)) {
					errors.username = t("username_format_required_error");
				}
			}
			return errors;
		},
		onSubmit: (data) => {
			// Handle form submission here
			setSelectCategory("currentAddress");
			setUserDetails(data);
		},
	});
	useEffect(() => {
		setTimeout(() => {
			getDropdownData();
		}, 1000);
	}, [dispatch, appToastRef]);
	
	const getDropdownData = async () => {
		await dispatch(redeemCartDropdown());

	};
	const isFormFieldUserDetailsInvalid = (name: keyof typeof userDetailsFormik.values) =>
		!!(userDetailsFormik.touched[name] && userDetailsFormik.errors[name]);
	const getFormUserDetailsErrorMessage = (name: keyof typeof userDetailsFormik.values) =>
		isFormFieldUserDetailsInvalid(name) ? (
			<small className="p-error margin-top">{userDetailsFormik.errors[name]}</small>
		) : (
			<small className="p-error">&nbsp;</small>
		);
	useEffect(() => {
		// Update formik values when allVerifiedEmailData changes
		userDetailsFormik.setValues({
				  email: allVerifiedEmailData?.email || "",
				  name: allVerifiedEmailData?.name || "",
				  username: "",
				  verificationCode: allVerifiedEmailData?.verificationCode || "",
				  mall: ""
		});
			  }, [allVerifiedEmailData]);
	//userAddress
	const userAddressFormik = useFormik({
		initialValues: {
			city: "",
			country: "",
			postcode: "",
			address: "",

		},
		validate: (data) => {
			const errors: Partial<{ city: string, country: string, postcode: string, address: string }> = {};
			if (!data.city) {
				errors.city = t("city_required_error");
			}
			if (!data.country) {
				errors.country = t("country_required_error");
			}
			if (!data.address) {
				errors.address = t("address_required_error");
			}
			if (!data.postcode) {
				errors.postcode = t("postcode_required_error");
			}
			return errors;
		},
		onSubmit: (data) => {
			// Handle form submission here
			setSelectCategory("paymentDetails");
			setUserAddress(data);
		},
	});
	const isFormFieldUserAddressInvalid = (name: keyof typeof userAddressFormik.values) =>
		!!(userAddressFormik.touched[name] && userAddressFormik.errors[name]);
	const getFormUserAddressErrorMessage = (name: keyof typeof userAddressFormik.values) =>
		isFormFieldUserAddressInvalid(name) ? (
			<small className="p-error margin-top">{userAddressFormik.errors[name]}</small>
		) : (
			<small className="p-error">&nbsp;</small>
		);
	const handleRegisterFormValuesSubmit = async (values: userPassword) => {
		try {
			setShowOtherSpinner(true);
			const saltRounds = 10;
			let client_salt = bcrypt.genSaltSync(saltRounds);
			client_salt = "$2a" + client_salt.substring(3, client_salt.length);
			const client_hash = bcrypt.hashSync(values.confirm_password, client_salt);
			const paymentDetails: any = {};

			userPayment.paymentMethod.forEach((method) => {
				const paymentIdKey = method + "PaymentId";
	
				if (method === "payone") {
					paymentDetails[method] = {
						merchantId: userPayment.payoneMerchantId,
						apiKeyId: userPayment.payoneApiId,
						secretApiKey: userPayment.payoneSecretId,
					};
				}else if (method === "bank") {
					paymentDetails[method] = {
						bankAccountName: userPayment.bankAccountName,
						bankIban: userPayment.bankIban,
						bankBic: userPayment.bankBic,
					};
				} else {
					paymentDetails[method] = {
						id: userPayment[paymentIdKey as keyof UserPaymentDetails],
					};
				}
			});
			// Create the final object with all the values			
			const formData = {
				email:allVerifiedEmailData?.email,
				verificationCode: allVerifiedEmailData?.verificationCode,
				name: userDetails?.name,
				username: userDetails.username,
				encryptionData: {
					client_salt,
					client_hash,
					pinCode: values.pin
				},
				info: {
					address: userAddress.address,
					address_additional: "",
					city: userAddress.city,
					country: userAddress.country,
					postcode: userAddress.postcode,
					status: "",
				},
				paymentDetails
			};			
			await dispatch(registerStore({ dataRegister: formData, successMsg: t("store_registered_success"), errorMsg: t("store_registered_error"), toastRef: appToastRef, navigate, setSelectCategory }));
			const addStoreData = {
				mall_username: userDetails.mall,
				retailer_usernames: [`${userDetails.username}`]
			};
			if (accountType == "retailer") {
				await dispatch(addStorePartners({ updateData: addStoreData, toastRef: appToastRef }));
		    }
			setShowSpinner(false);
			setShowOtherSpinner(false);
		} catch (error) {
			// Handle errors here
			setShowOtherSpinner(false);
			console.error("Sign-up error:", error);
		}
	};
	//password data
	const userPasswordFormik = useFormik({
		initialValues: {
			password: "",
			confirm_password: "",
			pin: ""
		},
		validate: (data) => {
			const errors: Partial<{ password: string, confirm_password: string, pin: string }> = {};
			if (!data.password) {
				errors.password = t("invalid_password_error");
			}
			if (!data.confirm_password) {
				errors.confirm_password = t("confirm_password_required_error");
			}
			if (accountType == "retailer") {
				if (!data.pin) {
					errors.pin = t("pin_code_required_error");
				}
			}
			if (data.password !== data.confirm_password) {
				errors.confirm_password = t("passwords_match_error");
			}
			return errors;
		},
		onSubmit: (data) => {
			// Handle form submission here
			handleRegisterFormValuesSubmit(data);
		},
	});
	const isFormFieldUserPasswordInvalid = (name: keyof typeof userPasswordFormik.values) =>
		!!(userPasswordFormik.touched[name] && userPasswordFormik.errors[name]);
	const getFormUserPasswordErrorMessage = (name: keyof typeof userPasswordFormik.values) =>
		isFormFieldUserPasswordInvalid(name) ? (
			<small className="p-error margin-top">{userPasswordFormik.errors[name]}</small>
		) : (
			<small className="p-error">&nbsp;</small>
		);
	const events = [
		{
			status: showSubtitle ?t("check_email"):t("email"),
			subTitle: t("verify_codes_delivered"),
			date: "15/10/2020 10:30",
			icon: "pi pi-envelope",
			color: selectCategory === "verifyEmail" && "black",
			moveAnotherTab: () => {  setSelectCategory("verifyEmail"); }

		},
		{
			status:  showSubtitle ?t("your_details"):t("details"),
			subTitle: t("provide_details_information"),
			date: "15/10/2020 14:00",
			icon: "pi pi-cog",
			color: selectCategory === "yourDetails" && "black",
			moveAnotherTab: () => {  setSelectCategory("yourDetails"); }

		},
		{
			status: showSubtitle ? t("your_property"):t("property"),
			subTitle: t("provide_current_address"),
			date: "15/10/2020 16:15",
			icon: "pi pi-wrench",
			color: selectCategory === "currentAddress" && "black",
			moveAnotherTab: () => {  setSelectCategory("currentAddress"); }

		},
		{
			status: showSubtitle ? t("payment_details"):t("payment"),
			subTitle: t("payment_para"),
			date: "16/10/2020 10:00",
			icon: "pi pi-paypal",
			color: selectCategory === "paymentDetails" && "black",
			moveAnotherTab: () => {  setSelectCategory("paymentDetails"); }

		},
		{
			status: showSubtitle ?t("choose_password"):t("password"),
			subTitle: t("choose_secure_password"),
			date: "16/10/2020 10:00",
			icon: "pi pi-check",
			color: selectCategory === "securePassword" && "black",
			moveAnotherTab: () => {  setSelectCategory("securePassword"); }

		},
		
		{
			status:showSubtitle ? t("successfully"):t("success"),
			subTitle: t("go_back_to_login"),
			date: "16/10/2020 10:00",
			icon: "pi pi-check",
			color: selectCategory === "successfully" && "black",
		},
	];
	const customizedMarker = (item: any) => {
		const circleStyle = {
			width: "2rem",
			height: "2rem",
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
			borderRadius: "50%",
			border: `1px solid ${item.color ? item.color : "gray"}`,
		};
		return (
			<span className="flex w-2rem h-2rem align-items-center justify-content-center text-white border-circle z-1 shadow-1">
				<span style={circleStyle}>
					<i className={item.icon} style={{ color: item.color ? item.color : "gray" }}></i>
				</span>
			</span>
		);
	};

	return (
		<div className="reset-password-parent">
			<div className="reset-password-partOne">
				
				<div className="logo-login-parent align-self-logo-resetPassword">
					<img src="/image 2.png" alt="" className="width-complete-100" />
				</div>
				<div className="timeline-parent">
					<Timeline
						value={events}
						layout={layout}
						marker={customizedMarker}
						content={(item) => {
							return (
								<div className="timeline-content-parent" onClick={() => item.moveAnotherTab()}>
									<span
										className="timeline-status" style={{ color: item.color ? item.color : "gray" }}>
										{item.status}
									</span>
									{showSubtitle && (
										<span className="timeline-subtitle" style={{color:item.color?item.color:"gray"}}>
											{item.subTitle}
										</span>
									)}
								</div>
							);
						}}
						className="w-full cursor-pointer"
					/>
				</div>
			</div>
			<div className="reset-password-partTwo">
				<div style={{display: "flex",
					alignItems: "center"}}>
					{selectCategory === "verifyEmail" ? <div className="reset-form-align-center">
						<div className="login-partOne-parent signup-padding-top">
							<div className="logo-forgotpassword-parent ">
								<span className="circleStyle">
									<i className="pi pi-envelope"></i>
								</span>
							</div>
							<h2 className="text-center forgotpassword-heading" >{t("verify_email")}</h2>
							<p className="text-center forgotpassword-para">{t("sent_verification_code")}</p>
							{showSpinner ? (
								<ProgressSpinner />
							) : (
								<form className="login-form-div"
									onSubmit={emailFormik.handleSubmit}
								>
									<div className=" p-mb-2 margin-top forgotpassword-input-parent-div">
										<label htmlFor="email" style={{paddingBottom: '8px', paddingLeft: '1px'}}>{t("email")}</label>
										<InputText
											id="email"
											name="email"
											placeholder={t("invalid_email_error_required")}
											value={emailFormik.values.email}
											onChange={emailFormik.handleChange}
											className={`input-width input-border-radius ${classNames({ "p-invalid": isFormFieldEmailVerificationInvalid("email") })}`}
										/>
										{getFormEmailVerificationErrorMessage("email")}
									</div>
									<div className=" p-mb-2 margin-top forgotpassword-input-parent-div">
										<label htmlFor="verificationCode" style={{paddingBottom: '8px', paddingLeft: '1px'}}>{t("verification_code")}</label>
										<InputText
											id="verificationCode"
											name="verificationCode"
											placeholder={t("verification_code_placeholder")}
											value={emailFormik.values.verificationCode}
											onChange={emailFormik.handleChange}
											className={`input-width input-border-radius ${classNames({ "p-invalid": isFormFieldEmailVerificationInvalid("verificationCode") })}`}
										/>
										{getFormEmailVerificationErrorMessage("verificationCode")}
									</div>
									<div className="confirmPassword-submit-parent-div">
										<Button type="submit" label={t("verify_email")} className="login-submit-button" /></div>
								</form>)}
							{/* <div className="p-field  p-grid">
  <div className="p-col">
    <div className="navigate-auth-option-btn">
      <p>Did not receive an email?</p>
      <Button label="click to resend" text  />
    </div>
  </div>
</div> */}
							<div className="navigate-back-reset-parent">
								<i className="pi pi-arrow-left"></i>
								<button onClick={() => { navigate("/auth/login"); }} className="navigate-back-reset-text-padding">{t("back_to_login")}</button>
							</div>
						</div>
					</div> : selectCategory === "yourDetails" ?
						<div className="reset-form-align-center">
							<div className="login-partOne-parent signup-padding-top">
								<div className="logo-forgotpassword-parent ">
									<span className="circleStyle">
										<img src="/user-01.png" alt="reset-logo-img" className="reset-logo-img-width" />
									</span>
								</div>
								<h2 className="text-center forgotpassword-heading" >{t("your_details")}</h2>
								<p className="text-center forgotpassword-para">{t("your_details_para")} </p>
								<form className="login-form-div"
									onSubmit={userDetailsFormik.handleSubmit}
								>
									<div className=" p-mb-2 margin-top forgotpassword-input-parent-div">
										<label htmlFor="name" style={{paddingBottom: '8px', paddingLeft: '1px'}}>{t("name")}*</label>
										<InputText
											id="name"
											name="name"
											placeholder={t("name_placeholder")}
											value={userDetailsFormik.values.name}
											onChange={userDetailsFormik.handleChange}
											className={`input-width input-border-radius ${classNames({ "p-invalid": isFormFieldUserDetailsInvalid("name") })}`}
										/>
										{getFormUserDetailsErrorMessage("name")}
									</div>
									<div className=" p-mb-2 margin-top forgotpassword-input-parent-div">
										<label htmlFor="email" style={{paddingBottom: '8px', paddingLeft: '1px'}}>{t("email")}*</label>
										<InputText
											id="email"
											name="email"
											placeholder={t("email_placeholder")}
											value={userDetailsFormik.values.email}
											onChange={userDetailsFormik.handleChange}
											className={`input-width input-border-radius ${classNames({ "p-invalid": isFormFieldUserDetailsInvalid("email") })}`}
										/>
										{getFormUserDetailsErrorMessage("email")}
									</div>
									<div className=" p-mb-2 margin-top forgotpassword-input-parent-div">
										<label htmlFor="username" style={{paddingBottom: '8px', paddingLeft: '1px'}}>{t("username")}*</label>
										<InputText
											id="username"
											name="username"
											placeholder={t("username_placeholder")}
											value={userDetailsFormik.values.username}
											onChange={userDetailsFormik.handleChange}
											className={`input-width input-border-radius ${classNames({ "p-invalid": isFormFieldUserDetailsInvalid("username") })}`}
										/>
										{getFormUserDetailsErrorMessage("username")}
									</div>
									<div className=" p-mb-2 margin-top forgotpassword-input-parent-div">
										<label htmlFor="verificationCode" style={{paddingBottom: '8px', paddingLeft: '1px'}}>{t("verification_code")}*</label>
										<InputText
											id="verificationCode"
											name="verificationCode"
											placeholder={t("verification_code_placeholder")}
											value={userDetailsFormik.values.verificationCode}
											onChange={userDetailsFormik.handleChange}
											className={`input-width input-border-radius ${classNames({ "p-invalid": isFormFieldUserDetailsInvalid("verificationCode") })}`}
										/>{getFormUserDetailsErrorMessage("verificationCode")}  </div>
									{accountType == "retailer" && <div className=" p-mb-2 margin-top forgotpassword-input-parent-div">
										<label htmlFor="mall" style={{paddingBottom: '8px', paddingLeft: '1px'}}>{t("select_mall")}*</label>
										<Dropdown
											id="mall"
											name="mall"
											value={userDetailsFormik.values.mall}
											options={retailerOptions}
											onChange={(e) => userDetailsFormik.setFieldValue("mall", e.value)}
											placeholder={t("select_mall")}
											className={`input-width input-border-radius ${classNames({ "p-invalid": isFormFieldUserDetailsInvalid("mall") })}`}
										/>
										{getFormUserDetailsErrorMessage("mall")}
									</div>}
									<div className="confirmPassword-submit-parent-div">
										<Button type="submit" label={t("continue")} className="login-submit-button" /></div>
									<div className="p-field  p-grid">
										<div className="p-col">
											<div className="navigate-auth-option-btn">
												<p>{t("already_have_account")}</p>
												<Button label={t("sign_in")} text onClick={() => navigate("/auth/login")} />
											</div>
										</div>
									</div>
								</form>
							</div>
						</div> : selectCategory === "currentAddress" ?
							<div className="reset-form-align-center">
								<div className="login-partOne-parent signup-padding-top">
									<div className="logo-forgotpassword-parent ">
										<span className="circleStyle">
											<img src="/Primary Icons.png" alt="reset-logo-img" className="reset-logo-img-width" />
										</span>
									</div>
									<h2 className="text-center forgotpassword-heading" >{t("your_address")}</h2>
									<p className="text-center forgotpassword-para">{t("address_info")} </p>
									<form className="login-form-div"
										onSubmit={userAddressFormik.handleSubmit}
									>
										<div className=" p-mb-2 margin-top forgotpassword-input-parent-div">
											<label htmlFor="country" style={{paddingBottom: '8px', paddingLeft: '1px'}}>{t("country")}*</label>
											<Dropdown
												id="country"
												name="country"
												filter={true}
												showFilterClear={true}
												value={userAddressFormik.values.country}
												options={formattedCountryData}
												onChange={(e) => { userAddressFormik.setFieldValue("country", e.value); fetchCities(e.value); }}
												placeholder={t("select_country")}
												className={`input-width input-border-radius ${classNames({ "p-invalid": isFormFieldUserAddressInvalid("country") })}`}
											/>
											{getFormUserAddressErrorMessage("country")}
										</div>
										<div className=" p-mb-2 margin-top forgotpassword-input-parent-div">
											<label htmlFor="address" style={{paddingBottom: '8px', paddingLeft: '1px'}}>{t("address")}*</label>
											<InputText
												id="address"
												name="address"
												placeholder={t("address_required")}
												value={userAddressFormik.values.address}
												onChange={userAddressFormik.handleChange}
												className={`input-width input-border-radius ${classNames({ "p-invalid": isFormFieldUserAddressInvalid("address") })}`}
											/>
											{getFormUserAddressErrorMessage("address")}
										</div>
										<div className=" p-mb-2 margin-top forgotpassword-input-parent-div">
											<label htmlFor="postcode" style={{paddingBottom: '8px', paddingLeft: '1px'}}>{t("postcode")}*</label>
											<InputText
												id="postcode"
												name="postcode"
												placeholder={t("postcode_required")}
												value={userAddressFormik.values.postcode}
												onChange={userAddressFormik.handleChange}
												className={`input-width input-border-radius ${classNames({ "p-invalid": isFormFieldUserAddressInvalid("postcode") })}`}
											/>
											{getFormUserAddressErrorMessage("postcode")}
										</div>
										<div className=" p-mb-2 margin-top forgotpassword-input-parent-div">
											<label htmlFor="city" style={{paddingBottom: '8px', paddingLeft: '1px'}}>{t("city")}*</label>
											<Dropdown
												id="city"
												name="city"
												filter={true}
												showFilterClear={true}
												value={userAddressFormik.values.city}
												options={Cities}
												onChange={(e) => userAddressFormik.setFieldValue("city", e.value)}
												placeholder={t("select_city")}
												className={`input-width input-border-radius ${classNames({ "p-invalid": isFormFieldUserAddressInvalid("city") })}`}
											/>
											{getFormUserAddressErrorMessage("city")}
										</div>

										<div className="confirmPassword-submit-parent-div">
											<Button type="submit" label={t("continue")} className="login-submit-button" /></div>
										<div className="p-field  p-grid">
											<div className="p-col">
												<div className="navigate-auth-option-btn">
													<p>{t("already_have_account")}</p>
													<Button label={t("sign_in")} text onClick={() => navigate("/auth/login")} />
												</div>
											</div>
										</div>
									</form>
								</div>
							</div> : selectCategory === "paymentDetails" ? 
								<div className="reset-form-align-center margin-top">
									<div className="login-partOne-parent signup-padding-top">
										<div className="logo-forgotpassword-parent ">
											<span className="circleStyle">
												<i className="pi pi-paypal"></i>

											</span>
										</div>
										<h2 className="text-center forgotpassword-heading" >{t("select_payment_method")}</h2>
										<p className="text-center forgotpassword-para">{t("fill_payment_info")}</p>
										{showOtherSpinner ? (
											<ProgressSpinner />
										) : (
											<form className="login-form-div" 
												onSubmit={userPaymentFormik.handleSubmit}
											>
											
												<h4>{t("choose_payment_method")}*</h4>
												<div className="flex flex-wrap justify-content-center gap-3">
													<div className="flex align-items-center">
														<Checkbox inputId="paypal" name="paypal" value="paypal" onChange={onIngredientsChange}   checked={userPayment.paymentMethod.includes("paypal")}
														/>
														<label htmlFor="paypal" className="ml-2">Paypal</label>
													</div>
													<div className="flex align-items-center">
														<Checkbox
															inputId="stripe"
															name="stripe"
															value="stripe"
															onChange={onIngredientsChange}
															checked={userPayment.paymentMethod.includes("stripe")}
														/>
														<label htmlFor="stripe" className="ml-2">Stripe</label>
													</div>
													<div className="flex align-items-center">
														<Checkbox
															inputId="payone"
															name="payone"
															value="payone"
															onChange={onIngredientsChange}
															checked={userPayment.paymentMethod.includes("payone")}
														/>
														<label htmlFor="payone" className="ml-2">Payone</label>
													</div>
													<div className="flex align-items-center">
														<Checkbox
															inputId="bank"
															name="bank"
															value="bank"
															onChange={onIngredientsChange}
															checked={userPayment.paymentMethod.includes("bank")}
														/>
														<label htmlFor="stripe" className="ml-2">Bank</label>
													</div>
												</div>
												<div className=" p-mb-2 margin-top forgotpassword-input-parent-div" >
													{userPayment.paymentMethod.includes("paypal") && (
														<div className="margin-top forgotpassword-input-parent-div input-width" >
															<h4>{t("paypal_payment_id")}*</h4>
															<InputText
																id="paypalPaymentId"
																name="paypalPaymentId"
																placeholder={t("payment_placeholder")}
																value={userPaymentFormik.values.paypalPaymentId}
																onChange={userPaymentFormik.handleChange}
																className={`input-width input-border-radius ${classNames({
																	"p-invalid": isFormFieldPaymentDetailsInvalid("paypalPaymentId"),
																})}`}
															/>
															{getFormUserPaymentDetailsErrorMessage("paypalPaymentId")}
														</div>
													)}
													{userPayment.paymentMethod.includes("stripe") && (
														<div className="forgotpassword-input-parent-div input-width" >
															<h4>{t("stripe_payment_id")}*</h4>
															<InputText
																id="stripePaymentId"
																name="stripePaymentId"
																placeholder={t("stripePaymentId_placeholder")}
																value={userPaymentFormik.values.stripePaymentId}
																onChange={userPaymentFormik.handleChange}
																className={`input-width input-border-radius ${classNames({
																	"p-invalid": isFormFieldPaymentDetailsInvalid("stripePaymentId"),
																})}`}
															/>
															{getFormUserPaymentDetailsErrorMessage("stripePaymentId")}
														</div>
													)}
													{userPayment.paymentMethod.includes("payone") && (
														<div className="forgotpassword-input-parent-div input-width"  >
															<h4>{t("payone_merchant_id")}*</h4>
															<InputText
																id="payoneMerchantId"
																name="payoneMerchantId"
																placeholder={t("payonePaymentId_placeholder")}
																value={userPaymentFormik.values.payoneMerchantId}
																onChange={userPaymentFormik.handleChange}
																className={`input-width input-border-radius ${classNames({
																	"p-invalid": isFormFieldPaymentDetailsInvalid("payoneMerchantId"),
																})}`}
															/>
															{getFormUserPaymentDetailsErrorMessage("payoneMerchantId")}
														</div>
													)}
													{userPayment.paymentMethod.includes("payone") && (
														<div className="forgotpassword-input-parent-div input-width"  >
															<h4>{t("payone_api_id")}*</h4>
															<InputText
																id="payoneApiId"
																name="payoneApiId"
																placeholder={t("payonePaymentId_placeholder")}
																value={userPaymentFormik.values.payoneApiId}
																onChange={userPaymentFormik.handleChange}
																className={`input-width input-border-radius ${classNames({
																	"p-invalid": isFormFieldPaymentDetailsInvalid("payoneApiId"),
																})}`}
															/>
															{getFormUserPaymentDetailsErrorMessage("payoneApiId")}
														</div>
													)}
													{userPayment.paymentMethod.includes("payone") && (
														<div className="forgotpassword-input-parent-div input-width"  >
															<h4>{t("payone_secret_id")}*</h4>
															<InputText
																id="payoneSecretId"
																name="payoneSecretId"
																placeholder={t("payonePaymentId_placeholder")}
																value={userPaymentFormik.values.payoneSecretId}
																onChange={userPaymentFormik.handleChange}
																className={`input-width input-border-radius ${classNames({
																	"p-invalid": isFormFieldPaymentDetailsInvalid("payoneSecretId"),
																})}`}
															/>
															{getFormUserPaymentDetailsErrorMessage("payoneSecretId")}
														</div>
													)}
													{userPayment.paymentMethod.includes("bank") && (
														<div className="forgotpassword-input-parent-div input-width"  >
															<h4>{t("bank_name")}*</h4>
															<InputText
																id="bankAccountName"
																name="bankAccountName"
																placeholder={t("payonePaymentId_placeholder")}
																value={userPaymentFormik.values.bankAccountName}
																onChange={userPaymentFormik.handleChange}
																className={`input-width input-border-radius ${classNames({
																	"p-invalid": isFormFieldPaymentDetailsInvalid("bankAccountName"),
																})}`}
															/>
															{getFormUserPaymentDetailsErrorMessage("bankAccountName")}
														</div>
													)}
													{userPayment.paymentMethod.includes("bank") && (
														<div className="forgotpassword-input-parent-div input-width"  >
															<h4>{t("bank_iban")}*</h4>
															<InputText
																id="bankIban"
																name="bankIban"
																placeholder={t("payonePaymentId_placeholder")}
																value={userPaymentFormik.values.bankIban}
																onChange={userPaymentFormik.handleChange}
																className={`input-width input-border-radius ${classNames({
																	"p-invalid": isFormFieldPaymentDetailsInvalid("bankIban"),
																})}`}
															/>
															{getFormUserPaymentDetailsErrorMessage("bankIban")}
														</div>
													)}
													{userPayment.paymentMethod.includes("bank") && (
														<div className="forgotpassword-input-parent-div input-width"  >
															<h4>{t("bank_bic")}*</h4>
															<InputText
																id="bankBic"
																name="bankBic"
																placeholder={t("payonePaymentId_placeholder")}
																value={userPaymentFormik.values.bankBic}
																onChange={userPaymentFormik.handleChange}
																className={`input-width input-border-radius ${classNames({
																	"p-invalid": isFormFieldPaymentDetailsInvalid("bankBic"),
																})}`}
															/>
															{getFormUserPaymentDetailsErrorMessage("bankBic")}
														</div>
													)}
												
												</div>
										
												<div className="confirmPassword-submit-parent-div">
													<Button type="submit" label={t("continue")} className="login-submit-button" /></div>
												<div className="p-field  p-grid">
													<div className="p-col">
														<div className="navigate-auth-option-btn">
															<p>{t("already_have_account")}</p>
															<Button label={t("sign_in")} text onClick={() => navigate("/auth/login")} />
														</div>
													</div>
												</div>
											</form>)}
									</div>
								</div> :selectCategory === "securePassword" ? 
									<div className="reset-form-align-center">
										<div className="login-partOne-parent signup-padding-top">
											<div className="logo-forgotpassword-parent ">
												<span className="circleStyle">
													<img src="/Icon (1).png" alt="reset-logo-img" className="reset-logo-img-width" />
												</span>
											</div>
											<h2 className="text-center forgotpassword-heading" >{t("set_new_password")}</h2>
											<p className="text-center forgotpassword-para">{t("password_different")}</p>
											{showOtherSpinner ? (
												<ProgressSpinner />
											) : (
												<form className="login-form-div"
													onSubmit={userPasswordFormik.handleSubmit}
												>
													<div className=" p-mb-2 margin-top forgotpassword-input-parent-div">
														<label htmlFor="password" style={{paddingBottom: '8px', paddingLeft: '1px'}}>{t("password")}</label>
														<Password value={userPasswordFormik.values.password} onChange={userPasswordFormik.handleChange} id="password" name="password" feedback={false} tabIndex={1} className={`input-width input-border-radius ${classNames({ "p-invalid": isFormFieldUserPasswordInvalid("password") })}`} placeholder={t("password_placeholder")} />
														{getFormUserPasswordErrorMessage("password")}
													</div>
													<div className=" p-mb-2 margin-top forgotpassword-input-parent-div">
														<label htmlFor="confirm_password" style={{paddingBottom: '8px', paddingLeft: '1px'}}>{t("confirm_password")}</label>
														<Password value={userPasswordFormik.values.confirm_password} onChange={userPasswordFormik.handleChange} id="confirm_password" name="confirm_password" feedback={false} tabIndex={1} className={`input-width input-border-radius ${classNames({ "p-invalid": isFormFieldUserPasswordInvalid("confirm_password") })}`} placeholder={t("password_placeholder")} />
														{getFormUserPasswordErrorMessage("confirm_password")}
													</div>
													{accountType == "retailer" && <div className=" p-mb-2 margin-top forgotpassword-input-parent-div">
														<label htmlFor="pin" style={{paddingBottom: '8px', paddingLeft: '1px'}}>{t("pin_code")}*</label>
														<InputText
															id="pin"
															name="pin"
															placeholder={t("pin_placeholder")}
															value={userPasswordFormik.values.pin}
															onChange={userPasswordFormik.handleChange}
															className={`input-width input-border-radius ${classNames({ "p-invalid": isFormFieldUserPasswordInvalid("pin") })}`}
														/>
														{getFormUserPasswordErrorMessage("pin")}
													</div>}
													<div className="confirmPassword-submit-parent-div">
														<Button type="submit" label={t("reset_password")} className="login-submit-button" /></div>
													<div className="p-field  p-grid">
														<div className="p-col">
															<div className="navigate-auth-option-btn">
																<p>{t("already_have_account")}</p>
																<Button label={t("sign_in")} text onClick={() => navigate("/auth/login")} />
															</div>
														</div>
													</div>
												</form>)}
										</div>
									</div> : selectCategory === "successfully" ? <div className="reset-form-align-center">
										<div className="login-partOne-parent signup-padding-top">
											<div className="logo-forgotpassword-parent ">
												<span className="circleStyle">
													<img src="/check-circle.png" alt="reset-logo-img" className="reset-logo-img-width" />
												</span>
											</div>
											<h2 className="text-center forgotpassword-heading" >{t("successfully")}</h2>
											<p className="text-center forgotpassword-para">{t("account_success_msg")}</p>
											<div className="forgotpassword-submit-parent-div">
												<Button type="submit" label={t("continue")} className="login-submit-button" onClick={() => { navigate("/auth/login"); }} /></div>
											<div className="navigate-back-reset-parent">
												<i className="pi pi-arrow-left"></i>
												<button onClick={() => { navigate("/auth/login"); }} className="navigate-back-reset-text-padding">{t("back_to_login")}</button>
											</div>
										</div>
									</div> : <div></div>}</div>
			</div>
		</div>

	);
};
export default SignupComponent;