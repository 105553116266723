import axios from "axios";
import { GlobalService } from "./global.service";

// Add a request interceptor
export const requestInterceptor = () => {
	axios.interceptors.request.use(
		async (config) => {
			const token = await GlobalService.getStorage("token");
			if (token && !config.url?.includes("/auth/local")) {
				config.headers["Authorization"] = "Bearer " + token;
			}
			// config.headers['Content-Type'] = 'application/json';
			return config;
		},
		error => {
			console.log(error,"errr");
    
			Promise.reject(error);
		}
	);
};