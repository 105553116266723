import UniqueVisitorChartComponent from "./components/UniqueVisitorChart/UniqueVisitorChartComponent";
import React, { useEffect, useState } from "react";
import "./DashboardComponent.css";
import DashboardCard from "./components/DashboardCardComponent/DashboardCard";
import { Card, CardContent, Grid,Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store/models";
import { useTranslation } from "react-i18next";
import { ThunkDispatch } from "redux-thunk";
import { useNavigate, NavigateFunction } from "react-router-dom";
import { cilHome } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import { Button } from "primereact/button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import { CCard,CCardImage,CCardBody,CCardTitle,CCardText,CButton} from "@coreui/react";
import { cilChartLine,cilHistory,cilGift } from "@coreui/icons";
import { faGift, faPeopleGroup, faMoneyBills } from "@fortawesome/free-solid-svg-icons";

export default function DashboardComponent({ appToastRef }: any) {
	const dispatch: ThunkDispatch<RootState, void, any> = useDispatch();
	const navigate: NavigateFunction = useNavigate();  
	const { t } = useTranslation();
	const storeData = useSelector((state: RootState) => state.authData.DashboardData);
	const partnerStoreData = useSelector((state: RootState) => state.authData.PartnerStore);
	const accountType = useSelector((state: RootState) => state.authData.LoginInterface?.accountType);
	const activeGiftCards:any = useSelector((state: RootState) => state.authData.DashboardData?.n_active_giftcards || []);


	const[value, setValue] = useState("today");
	const[slot, setSlot] = useState("week");

	//Gift Card Purchased
	const[totalGiftCardPurchsed, setTotalGiftCardPurchsed] = useState(0);
	const[giftCardPurchasedTrendValue, setgiftCardPurchasedTrendValue] = useState(0);
	const[giftCardPurchasedTrend, setGiftCardPurchasedTrend] = useState(false);

	//Total Redeem Amount
	const[totalReedemAmount, setTotalRedeemAmount] = useState(0);
	const[totalRedeemAmountTrendValue, setTotalRedeemAmountTrendValue] = useState(0);
	const[totalRedeemAmountTrend, setTotalRedeemAmountTrend] = useState(false);
    
	//Redeem transactions
	const[redeemTransactions, setRedeemTransactions] = useState(0);
    

	const[selectedWindow, setSelectedWindow] = useState(localStorage.getItem("selectedFilter") || t("week"));

	const[open, setOpen] = useState(false);

	const handleSelectedWindow = (data: any) => {
		setSelectedWindow(data);
	};

	useEffect(() => {
		let newAmount:any = 0; 
		let giftCardPurchasedTrendValue:any;
		let giftCardPurchasedTrend = false;
		if(storeData?.n_purchase_transactions) {
			const newData:any = storeData?.purchase_chart_data;
			if(newData && newData.length > 0) {
				const amounts = newData.map((item:any) => item.amount);
				const maxAmount = Math.max(...amounts);
				const minAmount = Math.min(...amounts);
				newAmount = maxAmount === minAmount ? maxAmount : maxAmount - minAmount;
				giftCardPurchasedTrendValue = ((newAmount / minAmount) * 100).toFixed(1);

				//calculate trend
				const newnewData1 = [...newData].sort((a:any, b:any) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime());
				const diff:any = newnewData1[newnewData1.length - 1].amount - newnewData1[0].amount;
				giftCardPurchasedTrend = diff > 0 ? false : diff < 0 ? true : false;
			}
		}
		setTotalGiftCardPurchsed(newAmount);
		setgiftCardPurchasedTrendValue(giftCardPurchasedTrendValue);
		setGiftCardPurchasedTrend(giftCardPurchasedTrend);
       
		let redeemAmount = 0;
		let redeemAmountTrendValue: any;
		let redeemAmountTrend = false;
		if(storeData?.total_redeem_amount) {
			const redeemData:any = storeData?.redeem_chart_data;
			if(redeemData && redeemData.length > 0) {
				const amounts = redeemData.map((item:any) => item.amount);
				const maxRedeemAmount = Math.max(...amounts);
				const minRedeemAmount = Math.min(...amounts);
				redeemAmount = maxRedeemAmount === minRedeemAmount ? maxRedeemAmount : maxRedeemAmount - minRedeemAmount;
				redeemAmountTrendValue = ((redeemAmount / minRedeemAmount) * 100).toFixed(1);

				//calculating the trend
				const newredeemData = [...redeemData].sort((a:any, b:any) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime());
				const diff:any = newredeemData[newredeemData.length - 1].amount - newredeemData[0].amount;
				redeemAmountTrend = diff > 0 ? false : diff < 0 ? true : false;
			}
		}
		setTotalRedeemAmount(redeemAmount);
		setTotalRedeemAmountTrendValue(redeemAmountTrendValue);
		setTotalRedeemAmountTrend(redeemAmountTrend);
	},[storeData]);



	const handleButtonClick = (() => {
		setOpen(true);
	});

	const handleClose = () => {
		setOpen(false);
	};

	const BootstrapDialog = styled(Dialog)(({ theme }) => ({
		"& .MuiDialogContent-root": {
			padding: theme.spacing(2),
		},
		"& .MuiDialogActions-root": {
			padding: theme.spacing(1),
		},
	}));
      
	return (
		<div>
			<Grid container rowSpacing={4.5} columnSpacing={2.75} style={{marginTop: "47px", width: "99%", marginLeft: "0px"}}>
				<Grid item xs={12} sx={{ mb: -2.25 }}>
					<Typography variant="h5" id="dashboardTitle"><CIcon icon={cilHome} size="sm" style={{width: "20px"}}/> {t("dashboard")} </Typography>    
				</Grid>
				{accountType === "retailer" && ( 
					<>
						<Grid item xs={12} sm={6} md={4} lg={3}>
							<DashboardCard title={t("total_redemed_amount")} value={storeData?.total_redeem_amount ? `€${storeData.total_redeem_amount}` : "N/A"}
								percentage={totalRedeemAmountTrendValue} isLoss={totalRedeemAmountTrend} color="warning" content={totalReedemAmount} window={selectedWindow} />
						</Grid>
						<Grid item xs={12} sm={6} md={4} lg={3}>
							<DashboardCard title={t("redeem_transactions")} value={storeData?.n_redeem_transactions ? storeData?.n_redeem_transactions.toString() : "0" } percentage={giftCardPurchasedTrendValue} isLoss={giftCardPurchasedTrend}   color="warning" content={totalGiftCardPurchsed} window={selectedWindow} />
						</Grid>
					</>
				)}

				{accountType !== "retailer" && (
					<>
						<Grid item xs={2} sm={2} md={3} lg={2}>
							<DashboardCard title={t("gift_cards_purchased")} value={storeData?.n_purchase_transactions.toString() || "N/A"} percentage={giftCardPurchasedTrendValue} isLoss={giftCardPurchasedTrend}  content={totalGiftCardPurchsed} window={selectedWindow} icon={faGift}/>
						</Grid>
						<Grid item xs={2} sm={2} md={3} lg={2}>
							<DashboardCard title={t("total_customers")} value={storeData?.n_purchase_customers.toString() || "N/A"}  window={selectedWindow} icon={faPeopleGroup}/>
						</Grid>
						<Grid item xs={2} sm={2} md={3} lg={2}>
							<DashboardCard title={t("total_redemed_amount")} value={storeData?.total_redeem_amount ? `€${storeData.total_redeem_amount}` : "N/A"}
								percentage={totalRedeemAmountTrendValue} isLoss={totalRedeemAmountTrend} color="warning" content={totalReedemAmount} window={selectedWindow} icon={faMoneyBills}/>
						</Grid>
						<Grid item xs={2} sm={2} md={3} lg={2}>
							<DashboardCard title={t("total_purchase_amount")} value={storeData?.total_purchase_amount ? `€${storeData.total_purchase_amount}` : "N/A"}
								percentage={giftCardPurchasedTrendValue} isLoss={giftCardPurchasedTrend}   color="warning" content={totalGiftCardPurchsed} window={selectedWindow} icon={faMoneyBills}/>
						</Grid>
						<Grid item xs={2} sm={2} md={3} lg={2}>
							<DashboardCard title={t("active_number_giftcards")} value={activeGiftCards || "N/A"}  window={selectedWindow} icon={faGift}/>
						</Grid>
						<Grid item xs={2} sm={2} md={3} lg={2}>
							<DashboardCard title={t("partner_stores")} value={Object.keys(partnerStoreData?.retailers ?? {}).length.toString() } window={selectedWindow} icon={faMoneyBills} onClick={() => handleButtonClick()}/>
								<BootstrapDialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} >
									<DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title" style={{textAlign: "center"}}>{t("partner_stores")}</DialogTitle>
									<IconButton aria-label="close" onClick={handleClose} sx={{ position: "absolute", right: 8, top: 8, color: (theme) => theme.palette.grey[500],}}>
										<CloseIcon />
									</IconButton>
									<DialogContent dividers style={{ backgroundColor: "#F9FAFB", }}>
										<Typography variant="h5" style={{ fontSize: "14px", textAlign: "center"}}> {t("showing")} {Object.keys(partnerStoreData?.retailers ?? {}).length } {t("partner_stores")}</Typography>
										<div style={{ display: "flex", flexWrap: "wrap", justifyContent: "space-around" }}>
											{partnerStoreData?.retailers.map((retailer:any) => (
												<Card key={retailer.id} id='dashboardCard' style={{ flexBasis: "40%", margin: "1rem",height:"218px" }}>  
													<CCard  style={{ width: "100%", padding:"13px", borderRadius: "7px" }}>
														<div style={{width: "140px", height: "140px", overflow: "hidden", margin: "auto"}}>
															{retailer.cover ? (
																<CCardImage orientation="top" src={retailer.cover} style={{width: "100%", height: "100%", objectFit: "contain", objectPosition: "center"}}/>
															) : (
																<div style={{width: "100%", height: "100%", display: "flex",fontSize: "14px" ,justifyContent: "center", alignItems: "center", border: "1px solid #ccc"}}>
																	{t("no_photo_available")}
																</div>
															)}
														</div>
														<CCardBody>
															<CCardTitle style={{textAlign: "center", fontSize: "14px", color: "black"}}>{retailer.id} - {retailer.name}</CCardTitle>
														</CCardBody>
													</CCard>
												</Card>  
											))}
										</div>
									</DialogContent>
								</BootstrapDialog>
						</Grid>
					</>
				)}
			</Grid>
			{/* <Grid item xs={12} sm={6} md={4} lg={3}> */}

			
			<div className="dashboardGraph">
				<div className="uniqueVisitorGraph">
					<UniqueVisitorChartComponent onSelectedWindow={handleSelectedWindow} appToastRef={appToastRef} />
				</div>
			</div>
		</div>
	);
}

