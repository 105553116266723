import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { FileUpload } from "primereact/fileupload";
import { Card } from "primereact/card";
import { RootState } from "../../../../store/models";
import { Timeline } from "primereact/timeline";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { useFormik } from "formik";
import { classNames } from "primereact/utils";
import { updateProfile, userAddress, changePassword } from "../../../main/models";
import { Dropdown } from "primereact/dropdown";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { ProgressSpinner } from "primereact/progressspinner";
import { updateProfileInStore } from "../../../../store/Reducers/userAuth";
import { useTranslation } from "react-i18next";
import { changeUserPassword, updateStore, uploadProfileImage } from "../../../../store/Reducers/userMain";
import "../../../Assets/Styling/home.css";
import { SplitButton } from "primereact/splitbutton";
import { Password } from "primereact/password";
import { Country, City } from "country-state-city";
import { UserPaymentDetails } from "../../../auth/models";
import { Checkbox } from "primereact/checkbox";
import { GlobalValues } from "../../../shared/services/global.service";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStripe, faCcPaypal } from '@fortawesome/free-brands-svg-icons';



export default function ProfileComponent({ appToastRef }: any) {
	const dispatch: ThunkDispatch<RootState, void, any> = useDispatch();
	const { t } = useTranslation();
	const navigate: NavigateFunction = useNavigate();
	const [isPasswordForm, setIsPasswordForm] = useState(false);
	const [selectCategory, setSelectCategory] = useState("updateProfileOne");
	const [Cities, setCities] = useState([]);
	const [showSubtitle, setShowSubtitle] = useState(true);
	const [layout, setLayout] = useState<"vertical" | "horizontal" | undefined>("vertical");

	const loginData = useSelector((state: RootState) => state.authData.LoginInterface);
	const paymentDetailsKeys = Object.keys(loginData?.paymentDetails || {});	
	const [allCheckBox, setAllCheckbox] = useState(paymentDetailsKeys);
	const [userPayment, setUserPayment] = useState<UserPaymentDetails>({
		paymentMethod: Object.keys(loginData?.paymentDetails || {}),
		stripePaymentId: "",
		paypalPaymentId: "",
		payoneMerchantId: "",
	  payoneSecretId: "",
	  payoneApiId: "",
	  bankAccountName:"",
	  bankIban:"",
	  bankBic:""
	  });
	  useEffect(() => {
		// Update userPayment when loginData changes
		fetchCities(loginData?.info.country);
		setUserPayment({
		  paymentMethod: Object.keys(loginData?.paymentDetails || {}),
		  stripePaymentId: "",
		  paypalPaymentId: "",
		  payoneMerchantId: "",
		  payoneSecretId: "",
		  payoneApiId: "",
		  bankAccountName:"",
		  bankIban:"",
		  bankBic:""
		});
	  }, [loginData]);	  
	  useEffect(() => {
		if (loginData) {
		  const paymentDetailsKeys = Object.keys(loginData?.paymentDetails || {});
		  setAllCheckbox(paymentDetailsKeys);
		}
	  }, [loginData]);
	
	  const onIngredientsChange = (e: any) => {
		const _userPayment = { ...userPayment };
		const selectedValue = e.value;
	
		if (e.checked) {
		  _userPayment.paymentMethod.push(selectedValue);
	
		  // Check if selectedValue is not already present in allCheckBox, then add it
		  if (!allCheckBox.includes(selectedValue)) {
				setAllCheckbox((prevAllCheckBox) => [...prevAllCheckBox, selectedValue]);
		  }
		} else {
		  _userPayment.paymentMethod = _userPayment.paymentMethod.filter(
				(method) => method !== selectedValue
		  );
		  // Use type assertion to inform TypeScript about the dynamic key
		  (_userPayment as any)[selectedValue + "PaymentId"] = "";
		  // Check if selectedValue is present in allCheckBox, then remove it
		  if (allCheckBox.includes(selectedValue)) {
				setAllCheckbox((prevAllCheckBox) =>
			  prevAllCheckBox.filter((method) => method !== selectedValue)
				);
		  }
		}
		setUserPayment(_userPayment);
	  };
	const formattedCountryData = Country.getAllCountries().map((country: any) => {
		return {
			value: country.isoCode,
			label: country.name,
		};
	});
	useEffect(() => {
		if (!loginData) {
			navigate("/auth/login");
		}
	}, [loginData, navigate]);
	const fetchCities = (country: any) => {
		const mappedCities: any = City.getCitiesOfCountry(country)?.map((city) => ({ label: city.name, value: city.name }));
		setCities(mappedCities);
	};

	const imageDataObject = useSelector((state: RootState) => state.mainData.UploadProfileImageData);
	const imageUploaded = `${GlobalValues.baseWebUrl}${imageDataObject?.url}`;
	const [uploadedImageUrl, setUploadedImageUrl] = useState<string | null>();
	useEffect(() => {
		if (imageDataObject?.url != undefined) {
			setUploadedImageUrl(imageUploaded);
		}
	}, [imageDataObject]);
	const [userDetails, setUserDetails] = useState<updateProfile>({
		name: loginData?.name || "",
		website: loginData?.website || "",
		email: loginData?.email || "",
		username: loginData?.username || "",
	});
	const [userAddress, setUserAddress] = useState<userAddress>({
		country: "",
		city: "",
		postcode: "",
		address: "",
	});
	const [showSpinner, setShowSpinner] = useState(false);
	const [showOtherSpinner, setShowOtherSpinner] = useState(false);
	const [uploadedFile, setUploadedFile] = useState<File | null>(null);
	const onFileUpload = async (event: any) => {
		const file = event.files[0];
		// Check if the file type is allowed
		const allowedFileTypes = ["image/jpg", "image/jpeg", "image/png"];
		if (!allowedFileTypes.includes(file.type)) {
			// Display an error message or handle the invalid file type
			appToastRef.current.show({
				severity: "error",
				summary: t("error"),
				detail: t("profile_img_error"),
				life: 3000,
			});
			return;
		}
		setUploadedFile(file);
		const formData = new FormData();
		// Add values to the FormData object
		formData.append("files", file);
		formData.append("ref", "api::store.store");
		formData.append("refId", `${loginData?.id}`);
		formData.append("field", "cover");
		await dispatch(uploadProfileImage({
			updateData: formData,
			successMsg: t("profile_updated_success"),
			errorMsg: t("profile_updated_error"),
			toastRef: appToastRef
		}));
		setUploadedImageUrl(URL.createObjectURL(file));
	};
	//payment details	
	const userPaymentFormik = useFormik({
		initialValues: {
		  paymentMethod:allCheckBox,
		  stripePaymentId: loginData?.paymentDetails?.stripe?.id || "",
			paypalPaymentId: loginData?.paymentDetails?.paypal?.id || "",
			payoneMerchantId: loginData?.paymentDetails?.payone?.merchantId || "",
			payoneApiId: loginData?.paymentDetails?.payone?.apiKeyId || "",
			payoneSecretId: loginData?.paymentDetails?.payone?.secretApiKey || "",
			bankAccountName:loginData?.paymentDetails?.bank?.bankAccountName || "",
			bankIban:loginData?.paymentDetails?.bank?.bankIban || "",
			bankBic:loginData?.paymentDetails?.bank?.bankBic || ""
		},
		validate: (data) => {
		  const errors: Partial<UserPaymentDetails> = {};		
		  // Validate individual payment methods
		  if (data.paymentMethod.includes("paypal") && !data.paypalPaymentId) {
				errors.paypalPaymentId = t("paypal_error");
		  }
		  if (data.paymentMethod.includes("stripe") && !data.stripePaymentId) {
				errors.stripePaymentId =t("stripe_error");
		  }
		  if (data.paymentMethod.includes("payone") && !data.payoneMerchantId) {
				errors.payoneMerchantId = t("payone_merchant_error");
	  }
	  if (data.paymentMethod.includes("payone") && !data.payoneApiId) {
				errors.payoneApiId = t("payone_api_error");
			}
			if (data.paymentMethod.includes("payone") && !data.payoneSecretId) {
				errors.payoneSecretId = t("payone_secret_error");
			}
			if (data.paymentMethod.includes("bank") && !data.bankAccountName) {
				errors.bankAccountName = t("bank_name_error");
		  }
		  if (data.paymentMethod.includes("bank") && !data.bankIban) {
				errors.bankIban = t("bank_iban_error");
	  }
	  if (data.paymentMethod.includes("bank") && !data.bankBic) {
				errors.bankBic = t("bank_bic_error");
			}
		  return errors;
		},
		onSubmit: (data) => {

		  
		  setUserPayment(data);
		  handleUpdateProfile(data);
		},
	  });
	
	const paymentImages: { [key: string]: string } = {
		"payone": "/payone.png",
		"paypal": "/paypal.png",
		"stripe": "/stripe.png",
		"bank": "/bank.png"
	};	  
	const isFormFieldPaymentDetailsInvalid = (name: keyof typeof userPaymentFormik.values) =>
		!!(userPaymentFormik.touched[name] && userPaymentFormik.errors[name]);
	const getFormUserPaymentDetailsErrorMessage = (name: keyof typeof userPaymentFormik.values) =>
		isFormFieldPaymentDetailsInvalid(name) ? (
			<small className="p-error margin-top">{userPaymentFormik.errors[name]}</small>
		) : (
			<small className="p-error">&nbsp;</small>
		);
	useEffect(() => {
		userPaymentFormik.setValues({
			  ...userPaymentFormik.values,
			  paymentMethod: allCheckBox,
		});
		  }, [allCheckBox]);
		  useEffect(() => {
		const handleResize = () => {
			const screenWidth = window.innerWidth;
			if (screenWidth <= 750) {
				setLayout("horizontal");
				setShowSubtitle(false);
			} else {
				setLayout("vertical");
				setShowSubtitle(true);
			}
		};
	
		// Initial check on component mount
		handleResize();
	
		// Add event listener for window resize
		window.addEventListener("resize", handleResize);
	
		// Cleanup the event listener
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);
	//timeline work
	const events = [
		{
			status: showSubtitle ?t("update_pers"):t("details"),
			subTitle: t("update_pers_p"),
			date: "15/10/2020 10:30",
			icon: "pi pi pi-cog",
			color: selectCategory === "updateProfileOne" && "black",
			moveAnotherTab: () => { setIsPasswordForm(false); setSelectCategory("updateProfileOne"); }
		},
		{
			status: showSubtitle ?t("update_address"):t("property"),
			subTitle: t("update_address_p"),
			date: "15/10/2020 10:30",
			icon: "pi pi pi-home",
			color: selectCategory === "updateProfileTwo" && "black",
			moveAnotherTab: () => { setIsPasswordForm(false); setSelectCategory("updateProfileTwo"); }
		},
		{
			status:showSubtitle ? t("payment_details"):t("payment"),
			subTitle: t("payment_para"),
			date: "16/10/2020 10:00",
			icon: "pi pi-paypal",
			color: selectCategory === "paymentDetails" && "black",
			moveAnotherTab: () => { setIsPasswordForm(false); setSelectCategory("paymentDetails"); }

		},
	];
	const customizedMarker = (item: any) => {
		const circleStyle = {
			width: "2rem",
			height: "2rem",
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
			borderRadius: "50%",
			border: `1px solid ${item.color ? item.color : "gray"}`,
		};

		return (
			<span className="flex w-2rem h-2rem align-items-center justify-content-center text-white border-circle z-1 shadow-1">
				<span style={circleStyle}>
					<i className={item.icon} style={{ color: item.color ? item.color : "gray" }}></i>
				</span>
			</span>
		);
	};
	//change password
	const changePasswordFormik = useFormik({
		initialValues: {
			old_password: "",
			new_password: "",
			confirm_password: "",
		},
		validate: (data) => {
			const errors: Partial<{ old_password: string, new_password: string, confirm_password: string }> = {};
			if (!data.old_password) {
				errors.old_password = t("old_password_required");
			}
			if (!data.new_password) {
				errors.new_password = t("new_password_required");
			}
			if (!data.confirm_password) {
				errors.confirm_password = t("confirm_password_required_error");
			}
			if (data.new_password !== data.confirm_password) {
				errors.confirm_password = t("passwords_match_error");
			}
			return errors;
		},
		onSubmit: (data) => {
			// Handle form submission here
			handleChangePassword(data);

		},
	});

	const isFormchangeUserPasswordInvalid = (name: keyof typeof changePasswordFormik.values) =>
		!!(changePasswordFormik.touched[name] && changePasswordFormik.errors[name]);

	const getFormChangePasswordErrorMessage = (name: keyof typeof changePasswordFormik.values) =>
		isFormchangeUserPasswordInvalid(name) ? (
			<small className="p-error margin-top">{changePasswordFormik.errors[name]}</small>
		) : (
			<small className="p-error">&nbsp;</small>
		);
	const handleChangePassword = async (values: changePassword) => {
		try {
			setShowOtherSpinner(true);
			const changePasswordObj = {
				username: userDetails.username, email: userDetails.email, mode: "email", ...values
			};
			await dispatch(changeUserPassword({ changePasswordData: changePasswordObj, successMsg: t("password_updated_success"), errorMsg: t("password_updated_error"), toastRef: appToastRef }));
			setShowOtherSpinner(false);

		} catch (err) {
			setShowOtherSpinner(false);
		}


	};
	//your details
	const userDetailsFormik = useFormik({
		initialValues: {
			name: loginData?.name || "",
			website: loginData?.website || "",
			email: loginData?.email || "",
			username: loginData?.username || "",
		},
		validate: (data) => {
			const errors: Partial<{ name: string, email: string, website: string, username: string }> = {};
			if (!data.name) {
				errors.name = t("name_required_error");
			}
			if (!data.website) {
				errors.website = t("website_required_error");
			}
			if (!data.username) {
				errors.username = t("username_required_error");
			} else if (data.username.length < 5) {
				errors.username = t("username_length_error");
			}
			if (data.username) {
				// Use a regular expression to check if the username contains only allowed characters
				const usernameRegex = /^[a-zA-Z0-9\-_]+$/; // Alphanumeric, "-", and "_" are allowed
				if (!usernameRegex.test(data.username)) {
					errors.username = t("username_format_required_error");
				}
			}
			return errors;
		},
		onSubmit: (data) => {
			// Handle form submission here
			setSelectCategory("updateProfileTwo");
			setUserDetails(data);
			// handleUpdateProfile(data);

		},
	});

	const isFormFieldUserDetailsInvalid = (name: keyof typeof userDetailsFormik.values) =>
		!!(userDetailsFormik.touched[name] && userDetailsFormik.errors[name]);

	const getFormUserDetailsErrorMessage = (name: keyof typeof userDetailsFormik.values) =>
		isFormFieldUserDetailsInvalid(name) ? (
			<small className="p-error margin-top">{userDetailsFormik.errors[name]}</small>
		) : (
			<small className="p-error">&nbsp;</small>
		);



	//userAddress
	const userAddressFormik = useFormik({
		initialValues: {
			city: loginData?.info.city || "",
			country: loginData?.info.country || "",
			postcode: loginData?.info.postcode || "",
			address: loginData?.info.address || "",

		},
		validate: (data) => {
			const errors: Partial<{ city: string, country: string, postcode: string, address: string }> = {};

			if (!data.city) {
				errors.city = t("city_required_error");
			}
			if (!data.country) {
				errors.country = t("country_required_error");
			}
			if (!data.address) {
				errors.address = t("address_required_error");
			}
			if (!data.postcode) {
				errors.postcode = t("postcode_required_error");
			}
			return errors;
		},
		onSubmit: (data) => {
			// Handle form submission here
			setSelectCategory("paymentDetails");

			setUserAddress(data);
		},
	});

	const isFormFieldUserAddressInvalid = (name: keyof typeof userAddressFormik.values) =>
		!!(userAddressFormik.touched[name] && userAddressFormik.errors[name]);

	const getFormUserAddressErrorMessage = (name: keyof typeof userAddressFormik.values) =>
		isFormFieldUserAddressInvalid(name) ? (
			<small className="p-error margin-top">{userAddressFormik.errors[name]}</small>
		) : (
			<small className="p-error">&nbsp;</small>
		);
		
	const handleUpdateProfile = async (data: UserPaymentDetails) => {
		try {
			setShowSpinner(true);
			const paymentDetails: any = {};			
			data.paymentMethod.forEach((method) => {
				const paymentIdKey = method + "PaymentId";
	
				if (method === "payone") {
					paymentDetails[method] = {
						merchantId: data.payoneMerchantId,
						apiKeyId: data.payoneApiId,
						secretApiKey: data.payoneSecretId,
					};
				}else if (method === "bank") {
					paymentDetails[method] = {
						bankAccountName: data.bankAccountName,
						bankIban: data.bankIban,
						bankBic: data.bankBic,
					};
				} else {
					paymentDetails[method] = {
						id: data[paymentIdKey as keyof UserPaymentDetails],
					};
				}
			});
			
			const formData = {
				client_hash: loginData?.client_hash,
				username: userDetails.username,
				updated: {
					name: userDetails?.name,
					info: {
						country: userAddress.country,
						city: userAddress.city,
						postcode: userAddress.postcode,
					},
					paymentDetails
				},
			};	
			 await dispatch(
				updateStore({
					updateData: formData,
					successMsg: t("user_updated_success"),
					errorMsg: t("user_updated_error"),
					toastRef: appToastRef,
				})
			);				
			// Dispatch updateProfileInStore if the updateStore was successful
			dispatch(
				updateProfileInStore({
					name: userDetails.name,
					country: userAddress.country,
					city: userAddress.city,
					postcode: userAddress.postcode,
					paymentMethodData:paymentDetails,
				})
			);
			
	
			setShowSpinner(false);

		} catch (error: any) {
			setShowSpinner(false);
			console.error("Login error:", error);
		}
	};
	return (
		<div className="reset-password-parent ">
			<div
				className="profile-password-partOne"    >

				<div className="profile-timeline-parent">
					<Timeline
						value={events}
						layout={layout}
						marker={customizedMarker}
						content={(item) => {
							return (
								<div className="timeline-content-parent" onClick={() => item.moveAnotherTab()}>
									<span
										className="timeline-status" style={{ color: item.color ? item.color : "gray" }}>
										{item.status}
									</span>
									{showSubtitle && (
										<span className="timeline-subtitle" style={{ color: item.color ? item.color : "gray" }}>
											{item.subTitle}
										</span>)}
								</div>
							);
						}}
						className="w-full cursor-pointer"
					/>
				</div>
			</div>

			<div className="profile-password-partTwo" >
				<div className="profile-password-partTwo-child">
					<div style={{ width: "90%", display: "flex",paddingBottom:"2%" }}>
						<SplitButton label={t("change_password")} onClick={() => setIsPasswordForm(true)} outlined />
					</div>
					<Card style={{ width: "100%",paddingBottom: "2%", marginRight: "4%" }}>
						<div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
							{uploadedImageUrl ? ( // Check if an image URL is available
								<div>
									<img src={uploadedImageUrl} alt="Uploaded" className="profile-img" />
								</div>
							) : (
								<div>
									{loginData?.cover.length === 0 ?<i className="pi pi-user" style={{ fontSize: "2.5rem" }}></i>
										:<img
											src={ loginData?.cover}
											alt="Uploaded"
											className="profile-img"
										/>}
								
								</div>
							)}
							<FileUpload
								mode="basic"
								name="demo[]"
								url="/api/upload"
								accept="image/*"
								maxFileSize={1000000}
								onSelect={onFileUpload}
								chooseLabel={t("select_img")}
								auto
							/>
						</div></Card>

					{!isPasswordForm ? selectCategory === "updateProfileOne" ?
						<Card className="profile-form-align-center">
							<div className="login-partOne-parent">
								<h2 className="text-center forgotpassword-heading" >{t("update_profile_data")}</h2>
								<form className="profile-form-div "
									onSubmit={userDetailsFormik.handleSubmit}
								>
									<div className="update-profile-personal-data-parent">
										<div className=" p-mb-2 margin-top profile-input-parent-div" >
											<label htmlFor="name" style={{paddingBottom: '8px', paddingLeft: '1px'}}>{t("name")}*</label>
											<InputText
												id="name"
												name="name"
												placeholder={t("name_placeholder")}
												value={userDetailsFormik.values.name}
												onChange={userDetailsFormik.handleChange}
												className={`profile-input-width input-border-radius ${classNames({ "p-invalid": isFormFieldUserDetailsInvalid("name") })}`}
											/>
											{getFormUserDetailsErrorMessage("name")}

										</div>
										<div className=" p-mb-2 margin-top profile-input-parent-div">
											<label htmlFor="email" style={{paddingBottom: '8px', paddingLeft: '1px'}}>{t("email")}</label>
											<InputText
												id="email"
												name="email"
												disabled
												placeholder={t("email_placeholder")}
												value={userDetailsFormik.values.email}
												onChange={userDetailsFormik.handleChange}
												className={`profile-input-width input-border-radius ${classNames({ "p-invalid": isFormFieldUserDetailsInvalid("email") })}`}
											/>
											{getFormUserDetailsErrorMessage("email")}

										</div>
									</div>
									<div className="update-profile-personal-data-parent">
										<div className=" p-mb-2 margin-top profile-input-parent-div">
											<label htmlFor="username" style={{paddingBottom: '8px', paddingLeft: '1px'}}>{t("username")}</label>
											<InputText
												id="username"
												name="username"
												disabled
												placeholder={t("username_required_error")}
												value={userDetailsFormik.values.username}
												onChange={userDetailsFormik.handleChange}
												className={`profile-input-width input-border-radius ${classNames({ "p-invalid": isFormFieldUserDetailsInvalid("username") })}`}
											/>
											{getFormUserDetailsErrorMessage("username")}

										</div>
										<div className=" p-mb-2 margin-top profile-input-parent-div">
											<label htmlFor="website" style={{paddingBottom: '8px', paddingLeft: '1px'}}>{t("website")}*</label>
											<InputText
												id="website"
												name="website"
												placeholder={t("website_placeholder")}
												value={userDetailsFormik.values.website}
												onChange={userDetailsFormik.handleChange}
												className={`profile-input-width input-border-radius ${classNames({ "p-invalid": isFormFieldUserDetailsInvalid("website") })}`}
											/>{getFormUserDetailsErrorMessage("website")}  </div>
									</div>
									<div className="profile-submit-parent-div">
										<Button type="submit" label={t("continue")} className="login-submit-button" /></div>
								</form>
							</div>
						</Card> : selectCategory === "updateProfileTwo" ?
							<Card className="profile-form-align-center">
								<div className="login-partOne-parent">
									<h2 className="text-center forgotpassword-heading" >{t("update_address_data")}</h2>
									{showSpinner ? (
										<ProgressSpinner />
									) : (
										<form className="profile-form-div"
											onSubmit={userAddressFormik.handleSubmit}
										>
											<div className="update-profile-personal-data-parent">
												<div className=" p-mb-2 margin-top profile-input-parent-div">
													<label htmlFor="country" style={{paddingBottom: '8px', paddingLeft: '1px'}}>{t("country")}*</label>
													<Dropdown
														id="country"
														name="country"
														filter={true}
														showFilterClear={true}
														value={userAddressFormik.values.country}
														options={formattedCountryData}
														onChange={(e) => { userAddressFormik.setFieldValue("country", e.value);
													 fetchCities(e.value); }}
														placeholder={t("select_country")}
														className={`profile-input-width input-border-radius ${classNames({ "p-invalid": isFormFieldUserAddressInvalid("country") })}`}
													/>
													{getFormUserAddressErrorMessage("country")}

												</div>
												<div className=" p-mb-2 margin-top profile-input-parent-div">
													<label htmlFor="city" style={{paddingBottom: '8px', paddingLeft: '1px'}}>{t("city")}*</label>
													<Dropdown
														id="city"
														name="city"
														filter={true}
														showFilterClear={true}
														value={userAddressFormik.values.city}
														options={Cities}
														onChange={(e) => userAddressFormik.setFieldValue("city", e.value)}
														placeholder={loginData?.info?.city!=undefined?loginData?.info?.city:t("select_city")}
														className={`profile-input-width input-border-radius ${classNames({ "p-invalid": isFormFieldUserAddressInvalid("city") })}`}
												
													/>
													{getFormUserAddressErrorMessage("city")}

												</div>
											</div>
											<div className="update-profile-personal-data-parent">
												<div className=" p-mb-2 margin-top profile-input-parent-div">
													<label htmlFor="postcode" style={{paddingBottom: '8px', paddingLeft: '1px'}}>{t("postcode")}*</label>
													<InputText
														id="postcode"
														name="postcode"
														placeholder={t("postcode_required")}
														value={userAddressFormik.values.postcode}
														onChange={userAddressFormik.handleChange}
														className={`profile-input-width input-border-radius ${classNames({ "p-invalid": isFormFieldUserAddressInvalid("postcode") })}`}
													/>
													{getFormUserAddressErrorMessage("postcode")}

												</div>
												<div className=" p-mb-2 margin-top profile-input-parent-div">
													<label htmlFor="address" style={{paddingBottom: '8px', paddingLeft: '1px'}}>{t("address")}*</label>
													<InputText
														id="address"
														name="address"
														placeholder={t("address_required")}
														value={userAddressFormik.values.address}
														onChange={userAddressFormik.handleChange}
														className={`profile-input-width input-border-radius ${classNames({ "p-invalid": isFormFieldUserAddressInvalid("address") })}`}
													/>
													{getFormUserAddressErrorMessage("address")}

												</div>
											</div>
											<div className="profile-submit-parent-div">


												<Button type="submit" label={t("continue")} className="login-submit-button" /></div>

										</form>
									)}
								</div>
							</Card> :selectCategory === "paymentDetails" ? 
								<Card className="profile-form-align-center margin-top" style={{ height: '425px'}} >
									<div className="login-partOne-parent">
										<h2 className="text-center forgotpassword-heading" >{t("update_address_data")}</h2>
										{showSpinner ? (
											<ProgressSpinner />
										) : (
											<form className="profile-form-div"
												onSubmit={userPaymentFormik.handleSubmit}
											>
												<div className="update-profile-personal-data-parent">
													<div className=" p-mb-2 margin-top profile-input-parent-div" style={{flexBasis:"100%"}}>
														<label style={{paddingBottom: '8px', paddingLeft: '1px'}}>{t("choose_payment_method")}*</label>

														<div className="flex flex-wrap justify-content-center gap-3" style={{marginTop:"2%"}}>
														{[
															{ id: 'paypal', label: 'Paypal', logo: paymentImages["paypal"], width: '71px' },
															{ id: 'stripe', label: 'Stripe', logo: paymentImages["stripe"], width: '71px' },
															{ id: 'payone', label: 'Payone', logo: paymentImages["payone"], width: '71px' },
															{ id: 'bank', label: 'Bank', logo: paymentImages["bank"], width: '71px', height: '38px' },
														].map(({ id, label, logo, width, height }) => (	
															<div className="flex align-items-center" key={id}>
																<Checkbox
																	inputId={id}
																	name={id}
																	value={id}
																	onChange={onIngredientsChange}
																	checked={userPayment.paymentMethod.includes(id)}
																/>
																<label htmlFor={id} className="ml-2" style={{ display: 'flex', alignItems: 'center', width: '126px' }}>
																	<img src={logo} style={{ width, height: height || 'auto', marginRight: '2px' }} alt={`${label} Logo`} />
																	{label}
																</label>
																</div>
															))}
															</div>
														<div  className="margin-top payment-inputs-parent">
															{userPayment.paymentMethod.includes("paypal") && (
																<div>
                  													<h3>PayPal</h3>
																	<div className="margin-top forgotpassword-input-parent-div input-width flexBasis45"  >
																		<label style={{paddingBottom: '8px', paddingLeft: '1px'}}>{t("paypal_payment_id")}*</label>
																		<InputText
																			id="paypalPaymentId"
																			name="paypalPaymentId"
																			placeholder={t("payment_placeholder")}
																			value={userPaymentFormik.values.paypalPaymentId}
																			onChange={userPaymentFormik.handleChange}
																			className={`input-width input-border-radius ${classNames({
																				"p-invalid": isFormFieldPaymentDetailsInvalid("paypalPaymentId"),
																			})}`}
																		/>
																		{getFormUserPaymentDetailsErrorMessage("paypalPaymentId")}
																	</div>
																</div>
															)}
															{userPayment.paymentMethod.includes("stripe") && (
																<div>
                  													<h3>Stripe</h3>
																	<div className="forgotpassword-input-parent-div input-width flexBasis45"  >
																		<label style={{paddingBottom: '8px', paddingLeft: '1px'}}>{t("stripe_payment_id")}*</label>
																		<InputText
																			id="stripePaymentId"
																			name="stripePaymentId"
																			placeholder={t("stripePaymentId_placeholder")}
																			value={userPaymentFormik.values.stripePaymentId}
																			onChange={userPaymentFormik.handleChange}
																			className={`input-width input-border-radius ${classNames({
																				"p-invalid": isFormFieldPaymentDetailsInvalid("stripePaymentId"),
																			})}`}
																		/>
																		{getFormUserPaymentDetailsErrorMessage("stripePaymentId")}
																	</div>
																</div>
															)}
															{userPayment.paymentMethod.includes("payone") && (
																<div>
                  													<h3>Payone</h3>
																	<div className="forgotpassword-input-parent-div input-width flexBasis45"  >
																		<label style={{paddingBottom: '8px', paddingLeft: '1px'}}>{t("payone_merchant_id")}*</label>
																		<InputText
																			id="payoneMerchantId"
																			name="payoneMerchantId"
																			placeholder={t("payonePaymentId_placeholder")}
																			value={userPaymentFormik.values.payoneMerchantId}
																			onChange={userPaymentFormik.handleChange}
																			className={`input-width input-border-radius ${classNames({
																				"p-invalid": isFormFieldPaymentDetailsInvalid("payoneMerchantId"),
																			})}`}
																		/>
																		{getFormUserPaymentDetailsErrorMessage("payoneMerchantId")}
																	</div>
															
															{/* {userPayment.paymentMethod.includes("payone") && ( */}
																<div className="forgotpassword-input-parent-div input-width flexBasis45"  >
																	<label style={{paddingBottom: '8px', paddingLeft: '1px'}}>{t("payone_api_id")}*</label>
																	<InputText
																		id="payoneApiId"
																		name="payoneApiId"
																		placeholder={t("payonePaymentId_placeholder")}
																		value={userPaymentFormik.values.payoneApiId}
																		onChange={userPaymentFormik.handleChange}
																		className={`input-width input-border-radius ${classNames({
																			"p-invalid": isFormFieldPaymentDetailsInvalid("payoneApiId"),
																		})}`}
																	/>
																	{getFormUserPaymentDetailsErrorMessage("payoneApiId")}
																</div>
															
															{/* {userPayment.paymentMethod.includes("payone") && ( */}
																<div className="forgotpassword-input-parent-div input-width flexBasis45"  >
																	<label style={{paddingBottom: '8px', paddingLeft: '1px'}}>{t("payone_secret_id")}*</label>
																	<InputText
																		id="payoneSecretId"
																		name="payoneSecretId"
																		placeholder={t("payonePaymentId_placeholder")}
																		value={userPaymentFormik.values.payoneSecretId}
																		onChange={userPaymentFormik.handleChange}
																		className={`input-width input-border-radius ${classNames({
																			"p-invalid": isFormFieldPaymentDetailsInvalid("payoneSecretId"),
																		})}`}
																	/>
																	{getFormUserPaymentDetailsErrorMessage("payoneSecretId")}
																</div>
															</div>
															)}
															
															{userPayment.paymentMethod.includes("bank") && (
																<div>
                  													<h3>Bank</h3>
																	<div className="forgotpassword-input-parent-div input-width flexBasis45"  >
																		<label style={{paddingBottom: '8px', paddingLeft: '1px'}}>{t("bank_name")}*</label>
																		<InputText
																			id="bankAccountName"
																			name="bankAccountName"
																			placeholder={t("payonePaymentId_placeholder")}
																			value={userPaymentFormik.values.bankAccountName}
																			onChange={userPaymentFormik.handleChange}
																			className={`input-width input-border-radius ${classNames({
																				"p-invalid": isFormFieldPaymentDetailsInvalid("bankAccountName"),
																			})}`}
																		/>
																		{getFormUserPaymentDetailsErrorMessage("bankAccountName")}
																	</div>
															 {/* {userPayment.paymentMethod.includes("bank") && ( */}
																<div className="forgotpassword-input-parent-div input-width flexBasis45"  >
																	<label style={{paddingBottom: '8px', paddingLeft: '1px'}}>{t("bank_iban")}*</label>
																	<InputText
																		id="bankIban"
																		name="bankIban"
																		placeholder={t("payonePaymentId_placeholder")}
																		value={userPaymentFormik.values.bankIban}
																		onChange={userPaymentFormik.handleChange}
																		className={`input-width input-border-radius ${classNames({
																			"p-invalid": isFormFieldPaymentDetailsInvalid("bankIban"),
																		})}`}
																	/>
																	{getFormUserPaymentDetailsErrorMessage("bankIban")}
																</div>
															
															 {/* {userPayment.paymentMethod.includes("bank") && ( */}
																<div className="forgotpassword-input-parent-div input-width flexBasis45"  >
																	<label style={{paddingBottom: '8px', paddingLeft: '1px'}}>{t("bank_bic")}*</label>
																	<InputText
																		id="bankBic"
																		name="bankBic"
																		placeholder={t("payonePaymentId_placeholder")}
																		value={userPaymentFormik.values.bankBic}
																		onChange={userPaymentFormik.handleChange}
																		className={`input-width input-border-radius ${classNames({
																			"p-invalid": isFormFieldPaymentDetailsInvalid("bankBic"),
																		})}`}
																	/>
																	{getFormUserPaymentDetailsErrorMessage("bankBic")}
																</div>
															</div>
															)}
														</div>
													</div>
											
												</div>
										
												<div className="profile-submit-parent-div">


													<Button type="submit" label={t("update_profile_btn")} className="login-submit-button" /></div>

											</form>
										)}

									</div>
								</Card>: <></> : <Card className="profile-form-align-center">
						<div className="login-partOne-parent">
							<h2 className="text-center forgotpassword-heading" >{t("change_password")}</h2>
							{showOtherSpinner ? (
								<ProgressSpinner />
							) : (
								<form className="profile-form-div "
									onSubmit={changePasswordFormik.handleSubmit}
								>
									<div className=" p-mb-2 margin-top profile-input-parent-div" >

										<label htmlFor="old_password">{t("old_password")}*</label>
										<Password value={changePasswordFormik.values.old_password} onChange={changePasswordFormik.handleChange} id="old_password" name="old_password" feedback={false} tabIndex={1} className={`input-width input-border-radius ${classNames({ "p-invalid": isFormchangeUserPasswordInvalid("old_password") })}`} placeholder={t("old_password_placeholder")} />
										{getFormChangePasswordErrorMessage("old_password")}

									</div>

									<div className=" p-mb-2 margin-top profile-input-parent-div">
										<label htmlFor="new_password">{t("new_password")}*</label>
										<Password value={changePasswordFormik.values.new_password} onChange={changePasswordFormik.handleChange} id="new_password" name="new_password" feedback={false} tabIndex={1} className={`input-width input-border-radius ${classNames({ "p-invalid": isFormchangeUserPasswordInvalid("new_password") })}`} placeholder={t("password_placeholder")} />
										{getFormChangePasswordErrorMessage("new_password")}

									</div>
									<div className=" p-mb-2 margin-top profile-input-parent-div">
										<label htmlFor="confirm_password">{t("confirm_password")}*</label>
										<Password value={changePasswordFormik.values.confirm_password} onChange={changePasswordFormik.handleChange} id="confirm_password" name="confirm_password" feedback={false} tabIndex={1} className={`input-width input-border-radius ${classNames({ "p-invalid": isFormchangeUserPasswordInvalid("confirm_password") })}`} placeholder={t("password_placeholder")} />
										{getFormChangePasswordErrorMessage("confirm_password")}
									</div>


									<div className="profile-submit-parent-div">
										<Button type="submit" label={t("change_password")} className="login-submit-button" /></div>
								</form>)}
						</div>
					</Card>}
				</div>
			</div>
		</div>
	);
};
