import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavigateFunction, useNavigate, useParams } from "react-router-dom";
import { RootState } from "../../../../store/models";
import { InputNumber } from "primereact/inputnumber";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { addToCart, getWallpapers, updateCartQty } from "../../../../store/Reducers/userMain";
import { ProgressSpinner } from "primereact/progressspinner";
import { Image } from "primereact/image";
import { useTranslation } from "react-i18next";
import { GlobalValues } from "../../../shared/services/global.service";
import "../../../Assets/Styling/gift.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone, faClock, faMapMarkerAlt,faEnvelope } from "@fortawesome/free-solid-svg-icons";


export default function DetailGiftComponent({ appToastRef }: any) {
	const { username } = useParams();
	const navigate: NavigateFunction = useNavigate();
	const dispatch: ThunkDispatch<RootState, void, any> = useDispatch();
	const giftData: any = useSelector((state: RootState) => state.mainData.AllGiftsData || []);
	const { t } = useTranslation();
	const cartData: any = useSelector((state: RootState) => state.mainData.cartItems || {});
	const wallPaperData: any = useSelector((state: RootState) => state.mainData.CategorizedWallpapers || []);
	const prices = [ 10, 15, 25, 50, 100, 150];
	let selectedData = giftData.filter((item: any) => { return item.username == username; });
	selectedData = selectedData[0];

	//const address:any = selectedData?.wallester_info?.headquarter_address?.address1+", "+selectedData?.wallester_info?.headquarter_address?.city +", "+ selectedData?.wallester_info?.headquarter_address?.postal_code;
	const [showSpinner, setShowSpinner] = useState(false);
	const [qty, setQty] = useState(1);
	const [price, setPrice] = useState(0);
	const [visible, setVisible] = useState(false);	  
	interface Wallpaper {
		id: number; 
		thumbnail_url: string;
	}
	const categoryMapping: any = {
		birthday: t("birthday"),
		christmas: t("christmas"),
		easter: t("easter"),
		fathersDay:t("fathersDay"),
		getWellSoon: t("getWellSoon"),
		graduation: t("graduation"),
		mothersDay:t("mothersDay"),
		newBorn: t("newBorn"),
		ramadan:t("ramadan"),
		retirement: t("retirement"),
		valentines: t("valentines"),
		wedding:t("wedding"),
	};
	
	const [selectedWallpaper, setSelectedWallpaper] = useState<Wallpaper | null>(null);
	const [selectedCategory, setSelectedCategory] = useState("birthday");
	const [errorMessage, setErrorMessage] = useState('');

	const categoryButtons = Object.keys(categoryMapping).map((key) => (
	  <Button
			key={key}
			label={categoryMapping[key]}
			style={{marginBottom:"2%",marginRight:"2%"}}
			className={`p-button-outlined ${
		  selectedCategory === key ? "p-button-danger" : ""
			}`}
			onClick={() => handleCategoryClick(key)}
	  />
	));

	const handleCategoryClick = async(category:any) => {
		try{		
	  setSelectedCategory(category);
	  setShowSpinner(true);
	  await dispatch(getWallpapers({category, toastRef: appToastRef }));
	  setShowSpinner(false);
		}catch(err){
			setShowSpinner(false);	
		}
	};
	useEffect(() => {
		getWallpaper();
	}, []);
	const handleWallpaperClick = (wallpaper: any) => {
		setSelectedWallpaper(wallpaper);
	};
	const getWallpaper = async () => {
		try {
			setShowSpinner(true);

			await dispatch(getWallpapers({ category:"birthday",toastRef: appToastRef }));
			setShowSpinner(false);

		} catch (err) {
			setShowSpinner(false);

		}
	};
	const handleSaveClick = () => {
		if (selectedWallpaper) {
			const { id: wallPaperId, thumbnail_url } = selectedWallpaper;
			const total = price * qty;
	
			for (let i = 0; i < qty; i++) {
				const cartInfo = {
				  ...selectedData,
				  wallPaperId,
				  thumbnail_url,
				  price,
				  qty: 1,
				  total: price,
				};
		
				// Use the addToCart action with the current username
				dispatch(addToCart(cartInfo, username));
			  }
	
			appToastRef.current.show({
				severity: "success",
				summary: t("success"),
				detail: t("gift_added_success"),
				life: 3000,
			});
	
			setVisible(false); // Close the dialog
		} else {
			appToastRef.current.show({
				severity: "error",
				summary: t("error"),
				detail: t("add_wallpaper_before_save"),
				life: 3000,
			});
		}
	};
	
	return (
		<div className="detail-gift-parent">
			<div className="flexbasis30">
				{selectedData?.cover?.length == 0 ?
					<img className=" shadow-2 cursor-pointer block xl:block mx-auto border-round" src="/image 2.png" alt={selectedData?.name} style={{width:"45%",height:"42%",objectFit:"contain"}}  /> : <img className="shadow-2 block xl:block mx-auto border-round"  src={selectedData?.cover} alt={selectedData?.name}  style={{width:"45%",height:"42%",objectFit:"contain"}} />}
				
				<div className="mall-info">
      				<h2 style={{textAlign: "center"}}>Donau-Einkaufszentrum</h2>
      				{/* <p>With <strong>140 stores</strong>, cafes, restaurants, and service providers under one roof, Donau-Einkaufszentrum offers the largest selection in Eastern Bavaria.</p> */}
      				<p style={{textAlign: "center"}}><strong> <FontAwesomeIcon icon={faMapMarkerAlt} /> {t("address")}:</strong>{selectedData.address}</p>
      				{/* <p><strong><FontAwesomeIcon icon={faClock} /> Opening Hours:</strong> Monday - Saturday until 20:00</p>
      				<p><strong><FontAwesomeIcon icon={faPhone} /> Contact:</strong> Tel. 0941 4608-0</p> */}
					<p style={{textAlign: "center"}}><strong><FontAwesomeIcon icon={faEnvelope} /> {t("info")}: </strong> {t("more_info")} <a href="http://www.donaueinkaufszentrum.de" target="_blank" rel="noopener noreferrer">{t("here")}</a>.</p>
    			</div>

			</div>
			<div className="detail-gift-view-cart-btn" style={{marginLeft: "19%"}}>
				{ username && cartData[username] && cartData[username].length > 0 && <div className="detail-gift-view-cart-btn-child">
					<Button style={{ marginRight: "20%"}} label={t("view_cart")} severity="info" outlined  onClick={()=>navigate(`/cartScreen/${username}`)} />
				</div> }	
				<h3>{t("select_price")}</h3>
				<div className="detail-gift-dialog-body" style={{width: "97%"}}>
					{prices.map((price: number, index: number) => {
						return <div key={index + "i"} onClick={() => { setPrice(price); setVisible(true); }} style={{ width: "12%", height: "5%", display: "flex", justifyContent: "center", alignItems: "center", background: "#ebe9eb", marginRight: "2%", marginBottom: "2%", borderRadius: 5 }} className="cursor-pointer"><h2 style={{fontSize: "20px"}}>€{price}</h2></div>;
					})}


				</div>
				<div className="card flex flex-wrap gap-3 p-fluid cursor-pointer">
					<div className="flex-auto">
						<label htmlFor="quantity" className="font-bold block mb-2">{t("quantity")}</label>
						<InputNumber
							inputId="quantity"
							value={qty}
							onValueChange={(e) => setQty(e.value as number)}
							mode="decimal"
							showButtons
							min={1}
							style={{ width: "60%", textAlign: "center" }}
						/>
					</div>
					<div className="flex-auto">
						<label htmlFor="price" className="font-bold block mb-2">{t("price")}</label>
						<InputNumber
							inputId="price"
							value={price}
							onValueChange={(e) => {
								const newValue = e.value as number;
								if (newValue >= 10 && newValue <= 150) {
									setPrice(newValue);
									setErrorMessage(''); // Clear the error message when the value is valid
								  } else if (newValue > 150) {
									setErrorMessage('Value cannot be greater than 150');
								  }
							}}
							onBlur={(e) => {
								const newValue = e.target.value as unknown as number;
								if (newValue > 150) {
								  setErrorMessage('Value cannot be greater than 150');
								}
							  }}
							mode="decimal"
							showButtons
							min={10}
							max={150}
							style={{ width: "58%", textAlign: "center" }}

						/>
						{errorMessage && <div style={{ color: 'red' }}>{errorMessage}</div>}

					</div>
				</div>
				<span style={{ color: "red",marginTop:"5",fontSize: "12px", width: "30%", textAlign: "justify" }}>{t("price_range_set")} </span>


				<Button style={{ margin: "5% 0", width: "79%", backgroundColor: "#0354CC" }} label={t("add_cart")} severity="info" raised onClick={() => setVisible(true)} />
				<Dialog
					header={t("wallPapers")}
					visible={visible}
					onHide={() => setVisible(false)}
					className="detail-gift-dialog"
					breakpoints={{ "960px": "75vw", "641px": "100vw" }}
					footer={
						<div className="detail-gift-dialog-footer">
							<Button label={t("save")} severity="info" raised onClick={handleSaveClick} />
						</div>
					}
				>
					
					  <div className="p-grid p-dir-col p-nogutter">
						{categoryButtons}
					</div>
					{showSpinner ? (
						<ProgressSpinner />
					) : (
						Object.keys(wallPaperData).map((category, index) => (
							<div key={index} className="cursor-pointer image-indicate">
								<h2>{categoryMapping[category] || categoryMapping["1689321208.png"]}</h2>
								<div className="detail-gift-dialog-body">
									{Array.isArray(wallPaperData[category]) ? (
										wallPaperData[category].map((wallpaper: any) => (
											<div
												key={wallpaper.id}
												style={{
													width: "145px",
													height: "82px",
													margin: "5px",
													border:
														selectedWallpaper && selectedWallpaper.id === wallpaper.id
															? "2px solid red"
															: "2px solid transparent",
												}}
												onClick={() => handleWallpaperClick(wallpaper)}
											>
												<Image
													src={`${GlobalValues.baseWebUrl}${wallpaper.thumbnail_url}`}
													zoomSrc={`${GlobalValues.baseWebUrl}${wallpaper.medium_url}`}
													alt={category}
													imageStyle={{ width: "100%", height: "auto", objectFit: "contain" }}
													preview
												/>
											</div>
										))
									) : (
										<p>{t("no_wallpaper")}</p>
									)}
								</div>
							</div>
						))
					)}

						
				</Dialog>

			</div>

		</div>
	);
}
