// RedeemCardComponent.js
import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { classNames } from "primereact/utils";
import { useDispatch, useSelector } from "react-redux";
import "../../../Assets/Styling/auth.css";
import { ThunkDispatch } from "redux-thunk";
import { useNavigate, NavigateFunction } from "react-router-dom";
import { ProgressSpinner } from "primereact/progressspinner";
import { RootState } from "../../../../store/models";
import { FormRedeemValues } from "../../../auth/models";
import { redeemCartData, redeemCartDropdown } from "../../../../store/Reducers/userMain";
import { GlobalService } from "../../../shared/services/global.service";
import { useTranslation } from "react-i18next";

interface RedeemCardProps {
	appToastRef: any;
	queryParams: string;
}

const RedeemCardComponent: React.FC<RedeemCardProps> = ({ appToastRef, queryParams: locationSearch }) => {
	const queryParams = new URLSearchParams(locationSearch);
	const type = queryParams.get("type") || "";
	const amount = parseFloat(queryParams.get("amount") || "0");
	const customer_hash = queryParams.get("customer_hash") || "";
	const customer_username = queryParams.get("customer_username") || "";
	const timestamp = parseInt(queryParams.get("timestamp") || "0");
	const voucherCode = queryParams.get("voucherCode") || "";
	const dispatch: ThunkDispatch<RootState, void, any> = useDispatch();
	const [showSpinner, setShowSpinner] = useState(false);
	const { t } = useTranslation();
	const redeemDropdown = useSelector((state: RootState) => state.mainData.AllRetailerDropdownData);
	const navigate: NavigateFunction = useNavigate();
	const retailerOptions = redeemDropdown.map((item: any) => ({
		label: item.name + " - ( " + item.username + " )",
		value: item.id,
		pinCode: item.pinCode
	}));
	const sortedRetailerOptions = [...retailerOptions].sort((a, b) =>
		a.label.localeCompare(b.label)
	);
	useEffect(() => {
		setTimeout(() => {
			getDropdownData();
		}, 1000);
	}, [dispatch, appToastRef]);
	const getDropdownData = async () => {
		try {
			setShowSpinner(true);
			await dispatch(redeemCartDropdown());
			setShowSpinner(false);

		}catch(err){
			setShowSpinner(false);
			
		}

	};
	// useEffect(() => {
	// 	setTimeout(() => {
	// 		dispatch(redeemCartDropdown());
	// 	}, 500);

	// }, []);

	const dropdownSavedValue = localStorage.getItem("retailerDropdownValue");

	const selectedRetailerValue = dropdownSavedValue !== null ? parseFloat(dropdownSavedValue) : "";
	const outputString: any = redeemDropdown.find(d => d.id == selectedRetailerValue)?.pinCode;
	const handleSubmit = async (values: FormRedeemValues) => {
		try {
			setShowSpinner(true);
			const retailer: any = values.retailer;
			const data = {
				customer_username: values.username,
				customer_hash: customer_hash,
				amount: values.amount,
				voucherCode: voucherCode,
				type: type,
				retailer_username: redeemDropdown.find(d => d.id == retailer)?.username,
				pinCode: values.pin
			};
			await dispatch(redeemCartData({ data, successMsg: t("redeem_card_success"), errorMsg: t("redeem_card__error"), toastRef: appToastRef}));
			setShowSpinner(false);
			navigate("/successfully");
		} catch (error: any) {
			setShowSpinner(false);
			console.error("Sign-up error:", error);
		}
	};

	const formik = useFormik({
		initialValues: {
			username: customer_username || "",
			code: voucherCode || "",
			amount: `${amount}` || "",
			pin: "",
			retailer:selectedRetailerValue|| "",
			confirm_pin: "",
		},
		validate: (data) => {
			const errors: Partial<{ username: string; code: string; amount: string; pin: string; retailer: string }> = {};

			if (!data.code) {
				errors.code = "Code is required.";
			}
			if (!data.username) {
				errors.username = "Username is required.";
			}

			if (!data.amount) {
				errors.amount = "Amount is required.";
			}
			if (!data.pin) {
				errors.pin = "Pin is required.";
			}

			if (!data.retailer) {
				errors.retailer = "Retailer is required.";
			}
			if (data.pin !== outputString) {
				errors.pin = "Pins must match";
			}
			return errors;
		},
		onSubmit: (data) => {
			handleSubmit(data);
		},
	});

	const isFormFieldInvalid = (name: keyof typeof formik.values) =>
		!!(formik.touched[name] && formik.errors[name]);

	const getFormErrorMessage = (name: keyof typeof formik.values) =>
		isFormFieldInvalid(name) ? <small className="p-error margin-top">{formik.errors[name]}</small> : <small className="p-error">&nbsp;</small>;

	return (
		<div className="login-parent-div">
			<div style={{ flexBasis: "100%" }}>
				<div className="login-partOne-parent">
					<div className="logo-login-parent">
						<img src="/image 2.png" alt="" className="width-complete-100" height="106" />
					</div>
					<h2 className="text-center store-heading">{t("redeem_portal")}</h2>
					{showSpinner ? (
						<ProgressSpinner />
					) : (
						<form
							className="login-form-div"
							onSubmit={formik.handleSubmit}
						>
							<div className="p-mb-2 margin-top login-input-parent-div">
								<label htmlFor="retailer">{t("retailer")}</label>
								<Dropdown
									id="retailer"
									name="retailer"
									filter={true}
									showFilterClear={true}
									value={formik.values.retailer}
									options={sortedRetailerOptions}
									onChange={(e) => {
										formik.setFieldValue("retailer", e.value);
										GlobalService.setStorage("retailerDropdownValue", e.value);}}
									placeholder={ t("select_retailer")}
									className={`input-width input-border-radius ${classNames({ "p-invalid": isFormFieldInvalid("retailer") })}`}
								/>
								{getFormErrorMessage("retailer")}
							</div>
							<div className="p-mb-2 margin-top login-input-parent-div">
								<label htmlFor="username">{t("username")}</label>
								<InputText
									id="username"
									disabled
									name="username"
									value={formik.values.username}
									onChange={formik.handleChange}
									placeholder={t("username_placeholder")}
									className={`input-width input-border-radius ${classNames({ "p-invalid": isFormFieldInvalid("username") })}`}
								/>
								{getFormErrorMessage("username")}
							</div>
							<div className="p-mb-2 margin-top login-input-parent-div">
								<label htmlFor="code">{t("code")}</label>
								<InputText
									id="code"
									placeholder={t("code_placeholder")}
									name="code"
									disabled
									value={formik.values.code}
									onChange={formik.handleChange}
									className={`input-width input-border-radius ${classNames({ "p-invalid": isFormFieldInvalid("code") })}`}
								/>
								{getFormErrorMessage("code")}
							</div>
							<div className="p-mb-2 margin-top login-input-parent-div">
								<label htmlFor="amount">{t("amount")}</label>
								<InputText
									id="amount"
									name="amount"
									value={formik.values.amount}
									disabled
									onChange={formik.handleChange}
									placeholder={t("amount_placeholder")}
									className={`input-width input-border-radius ${classNames({ "p-invalid": isFormFieldInvalid("amount") })}`}
								/>
								{getFormErrorMessage("amount")}
							</div>
							<div className="p-mb-2 margin-top login-input-parent-div">
								<label htmlFor="pin">{t("pin")}</label>
								<InputText
									id="pin"
									name="pin"
									value={formik.values.pin}
									onChange={formik.handleChange}
									placeholder={t("pin_placeholder")}
									className={`input-width input-border-radius ${classNames({ "p-invalid": isFormFieldInvalid("pin") })}`}
								/>
								{getFormErrorMessage("pin")}
							</div>
							<div className="width-fifty" style={{ marginBottom: "4%" }}>
								<Button type="submit" label={t("submit")} className="login-submit-button margin-top" />
							</div>
						</form>
					)}
				</div>
			</div>
		</div>
	);
};

export default RedeemCardComponent;
